import React, { useEffect, useMemo, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {
  IQButtonLink, IQDatePicker,
  IQFormInput,
  IQFormSelect,
  IQFormTextArea,
  IQTheme,
  ValidationProvider,
} from '@gannettdigital/shared-react-components';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  DialogTitle,
  FormControlLabel,
  FormHelperText,
  Grid,
  Link,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
  IconButton, Checkbox,
} from '@mui/material';
import {
  Controller, FormProvider, useFieldArray, useForm,
} from 'react-hook-form';
import GridItem from 'components/grid/GridItem';
import { Trans, useTranslation } from 'react-i18next';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import GridContainer from 'components/grid/GridContainer';
import { v4 as uuidv4 } from 'uuid';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import CoButtonContinue from 'components/buttons/CoButtonContinue';
import CoButtonBack from 'components/buttons/CoButtonBack';
import FileUploader from 'pages/generic-product/details/custom-fields/FileUploader';
import {
  ProductTypes,
  deleteFile,
  selectFilesUploaded,
} from 'services/fileUploaderSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveProductId } from 'services/navigationSlice';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import schemaValidate from 'components/schemaValidate';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import { Urls } from 'navigation/Urls';
import { useParams } from 'react-router';
import ErrorIcon from '@mui/icons-material/Error';
import { normalizeNewLines } from 'shared/constants';
import {
  TacticFormDialogProps,
  TacticTypeSelected,
  TacticDialogComponentProps, TargetedPageSelected,
} from '../TargetedDisplay.interface';
import {
  geofenceSegmentLengthList,
  tacticComeFromList,
  geoGraphicalAreaList,
  andOrList,
  yesNoList,
  targetingTypeList,
  isPropIsAllowed, XmoTacticTypeList, targetedDisplayTacticTypeList, pmmTacticTypeList, adTypesRunningList, dspTacticComeFromList, huluAdLengthList,
  imageAdTypesRunningList, imagePreRollAdTypesRunningList,
  zTVAdTypesRunningList,
} from './TacticsService';
import TacticsFormDialogSchema from './TacticsFormDialogSchema';

function checkHuluAdSelected(adRunningTypes) {
  return adRunningTypes.find((adType) => adType.indexOf('Hulu') > -1);
}

function checkTacticType(tacticType: string) {
  if (tacticType?.includes('ZTV')) {
    return 1;
  } if (tacticType?.includes('Dynamic')) {
    return 2;
  } if (tacticType?.includes('Event') || tacticType?.includes('Category') || tacticType?.includes('CRM') || tacticType?.includes('Lookalikes')) {
    return 3;
  } if (tacticType?.includes('Google Display Network')) {
    return 4;
  }
  return 0;
}

export default function TacticsFormDialog(props: TacticDialogComponentProps) {
  const {
    onClose,
    updateTactics,
    selectedTactics,
  } = props;

  const { t } = useTranslation();
  const megabyte = 1_000_000;
  const defaultFileSize = 20 * megabyte;
  const dispatch = useDispatch();
  const websiteId = useSelector(selectActiveProductId);
  const methods = useForm<TacticFormDialogProps>({
    mode: 'all',
  });
  const { page } = useParams();

  const {
    handleSubmit,
    register,
    unregister,
    getFieldState,
    control,
    watch,
    reset,
    formState: {
      isValid, isDirty, isSubmitSuccessful, isSubmitting,
    },
    setValue,
    getValues,
  } = methods;

  /** Add Tactic * */
  const tacticType = watch('tacticType') ?? '';
  const geographicalArea = watch('geographicalArea') ?? '';
  const andOr2 = watch('andOr2');
  const andOr3 = watch('andOr3');
  const andOr4 = watch('andOr4');
  const tacticComeFrom = watch('tacticComeFrom');
  const targetingType = watch('targetingType');
  const targetedCities = watch('targetedCities');
  const uniqueLandingPage = watch('uniqueLandingPage');
  const sourceId = watch('sourceId');
  const fileUploaded = useSelector(selectFilesUploaded);
  const currentDate = new Date().setHours(0, 0, 0, 0);
  const [fileUploadedError, setFileUploadedError] = useState('');
  const [selectedAdRunningTypes, setSelectedAdTypesRunning] = useState(selectedTactics?.adRunningTypes || []);
  const [selectedHuluAdLength, setSelectedHuluAdLength] = useState(selectedTactics?.huluAdLength || []);
  const [adRunningTypes, setAdTypesRunning] = useState(selectedTactics?.adRunningTypes || []);
  const [eventStartDateData, setEventStartDateData] = useState(selectedTactics?.eventStartDate || '');
  const [eventEndDateData, setEventEndDateData] = useState(selectedTactics?.eventEndDate || '');
  const [eventDateError, setEventDateError] = useState(false);
  const [eventStartDateError, setEventStartDateError] = useState(false);
  const [eventEndDateError, setEventEndDateError] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);

  const tacticTypeSelected = useMemo<TacticTypeSelected>(() => {
    const selected: TacticTypeSelected = {
      geoTargetOnly: tacticType === t('pages.targetedDisplay.tacticModel.tacticsTypes.geoTargetOnly'),
      searchRetargeting: tacticType === t('pages.targetedDisplay.tacticModel.tacticsTypes.searchRetargeting'),
      categoryContextual: tacticType === t('pages.targetedDisplay.tacticModel.tacticsTypes.categoryContextual'),
      dynamicSearchProspecting: tacticType === t('pages.targetedDisplay.tacticModel.tacticsTypes.dynamicSearchProspecting'),
      dynamicSiteRetargeting: tacticType === t('pages.targetedDisplay.tacticModel.tacticsTypes.dynamicSiteRetargeting'),
      event: tacticType === t('pages.targetedDisplay.tacticModel.tacticsTypes.eventAudienceRetargeting'),
      keywordContextual: tacticType === t('pages.targetedDisplay.tacticModel.tacticsTypes.keywordContextual'),
      lookalike: tacticType === t('pages.targetedDisplay.tacticModel.tacticsTypes.lookalikes'),
      siteRetargeting: tacticType === t('pages.targetedDisplay.tacticModel.tacticsTypes.siteRetargeting'),
      standardGeofence: tacticType === t('pages.targetedDisplay.tacticModel.tacticsTypes.standardGeoFence'),
      ztv: tacticType === t('pages.targetedDisplay.tacticModel.tacticsTypes.ztv'),
      addressableClientProvided: tacticType === t('pages.targetedDisplay.tacticModel.tacticsTypes.addressableGeoFenceClientProvided'),
      addressableCurated: tacticType === t('pages.targetedDisplay.tacticModel.tacticsTypes.addressableGeoFenceCuratedAudience'),
      crm: tacticType === t('pages.targetedDisplay.tacticModel.tacticsTypes.crmRetargeting'),
      gdn: tacticType === t('pages.xmo.targetedDisplay.tactics.tacticsTypes.googleDisplayNetwork'),
    };
    return selected;
  }, [tacticType]);

  const tacticComeFromSelected = useMemo(() => {
    const selected = {
      clientProvided: tacticComeFrom === t('pages.xmo.targetedDisplay.tactics.tacticComeFrom.clientProvided'),
      designIq: tacticComeFrom === t('pages.xmo.targetedDisplay.tactics.tacticComeFrom.designIq'),
      promotionsByVentures: tacticComeFrom === t('pages.xmo.targetedDisplay.tactics.tacticComeFrom.promotionsByVentures'),
    };
    return selected;
  }, [tacticComeFrom]);

  const geoGraphicalAreaSelected = useMemo(() => {
    const selected = {
      states: geographicalArea === t('pages.xmo.targetedDisplay.tactics.geoGraphicalAreas.states'),
      metros: geographicalArea === t('pages.xmo.targetedDisplay.tactics.geoGraphicalAreas.metros'),
      congressionalDistricts: geographicalArea === t('pages.xmo.targetedDisplay.tactics.geoGraphicalAreas.congressionalDistricts'),
      zipCodes: geographicalArea === t('pages.xmo.targetedDisplay.tactics.geoGraphicalAreas.zipCodes'),
    };
    return selected;
  }, [geographicalArea]);

  const tacticsPageForProductSelected = useMemo<TargetedPageSelected>(() => {
    const selected: TargetedPageSelected = {
      xmoFreeParty: page === Urls.XmoTargetedDisplay,
      xmoBrandAmplifer: page === Urls.XmoTargetedDisplay,
      targetedDisplay: page === Urls.TargetedDisplay,
      pmm: page === Urls.PmmTargetedDisplay,
    };
    return selected;
  }, [page]);

  useEffect(() => {
    const geographicalAreaData = watch('geographicalArea');
    if (geographicalAreaData === 'State(s)') {
      setValue('cities', '');
      setValue('congressionalDistricts', '');
      setValue('zipCodes', '');
    } else if (geographicalAreaData === 'Metro(s)') {
      setValue('states', '');
      setValue('congressionalDistricts', '');
      setValue('zipCodes', '');
    } else if (geographicalAreaData === 'Congressional District(s)') {
      setValue('states', '');
      setValue('cities', '');
      setValue('zipCodes', '');
    } else if (geographicalAreaData === 'Zip Code(s)') {
      setValue('states', '');
      setValue('cities', '');
      setValue('congressionalDistricts', '');
    }
  }, [watch('geographicalArea')]);

  const targetingTypeSelected = useMemo(() => {
    const selected = {
      city: targetingType === t('pages.xmo.targetedDisplay.tactics.gdn.targetingTypeOptions.city'),
      national: targetingType === t('pages.xmo.targetedDisplay.tactics.gdn.targetingTypeOptions.national'),
      dma: targetingType === t('pages.xmo.targetedDisplay.tactics.gdn.targetingTypeOptions.dma'),
    };
    return selected;
  }, [targetingType]);
  const hasMoreThanOneTargetedCities = useMemo(() => targetedCities && targetedCities.length > 1, [targetedCities]);

  /** Auto populate Tactics * */
  useEffect(() => {
    // Populate form fields with selectedTacticsFields data
    setValue('tacticType', selectedTactics?.tacticType || '');
    setValue('specificProductOrService', normalizeNewLines(selectedTactics?.specificProductOrService) || '');
    setValue('tacticNameInClientReporting', selectedTactics?.tacticNameInClientReporting || '');
    setValue('uniqueLandingPage', selectedTactics?.uniqueLandingPage || '');
    setValue('landingPageUrl', selectedTactics?.landingPageUrl || '');
    setValue('tacticComeFrom', selectedTactics?.tacticComeFrom || '');
    setValue('uploadedInSalesForce', selectedTactics?.uploadedInSalesForce || '');
    setValue('creativeServiceCid', selectedTactics?.creativeServiceCid || '');
    setValue('designiqLink', selectedTactics?.designiqLink || '');
    setValue('congressionalDistricts', normalizeNewLines(selectedTactics?.congressionalDistricts) || '');
    setValue('andOr1', selectedTactics?.andOr1 || '');
    setValue('sequentialRetargetingTactics', selectedTactics?.sequentialRetargetingTactics || '');
    setValue('geoFenceSegmentLength', selectedTactics?.geoFenceSegmentLength || '');
    setValue('geoFenceAddressListUpload', selectedTactics?.geoFenceAddressListUpload || '');
    setValue('geographicalArea', selectedTactics?.geographicalArea || '');
    setValue('states', normalizeNewLines(selectedTactics?.states) || '');
    setValue('cities', normalizeNewLines(selectedTactics?.cities) || '');
    setValue('congressionalDistricts', normalizeNewLines(selectedTactics?.congressionalDistricts) || '');
    setValue('zipCodes', normalizeNewLines(selectedTactics?.zipCodes) || '');
    setValue('segment1', selectedTactics?.segment1 || '');
    setValue('andOr1', selectedTactics?.andOr1 || '');
    setValue('segment2', selectedTactics?.segment2 || '');
    setValue('andOr2', selectedTactics?.andOr2 || '');
    setValue('segment3', selectedTactics?.segment3 || '');
    setValue('andOr3', selectedTactics?.andOr3 || '');
    setValue('segment4', selectedTactics?.segment4 || '');
    setValue('andOr4', selectedTactics?.andOr4 || '');
    setValue('segment5', selectedTactics?.segment5 || '');
    setValue('cities', normalizeNewLines(selectedTactics?.cities) || '');
    setValue('targetingType', selectedTactics?.targetingType || '');
    setValue('targetedCities', selectedTactics?.targetedCities || []);
    setValue('dmaTargetedDetails', normalizeNewLines(selectedTactics?.dmaTargetedDetails) || '');
    setValue('uploader_addressableGeoFenceAddressList', selectedTactics?.uploader_addressableGeoFenceAddressList);
    setValue('adRunningTypes', selectedTactics?.adRunningTypes || []);
    setValue('huluAdLength', selectedTactics?.huluAdLength || []);
    setValue('eventAudienceRetargetingSegmentLength', selectedTactics?.eventAudienceRetargetingSegmentLength || '');
    setValue('detailsOfEvent', normalizeNewLines(selectedTactics?.detailsOfEvent) || '');
    setValue('eventStartDate', selectedTactics?.eventStartDate || '');
    setValue('eventEndDate', selectedTactics?.eventEndDate || '');
    setValue('timeZone', selectedTactics?.timeZone || '');
    setValue('uploader_eventAudienceRetargetingList', selectedTactics?.uploader_eventAudienceRetargetingList);
    setValue('uploader_ztvList', selectedTactics?.uploader_ztvList);
    setValue('inventoryTarget', selectedTactics?.inventoryTarget || '');
    setValue('siteRetargetingUrls', normalizeNewLines(selectedTactics?.siteRetargetingUrls) || '');
    setValue('referenceKeywords', selectedTactics?.referenceKeywords || '');
    setValue('keywordsListUpload', selectedTactics?.keywordsListUpload || '');
    setValue('dsrTacticFrom', selectedTactics?.dsrTacticFrom || '');
    setValue('dynamicAdHeadline', selectedTactics?.dynamicAdHeadline || '');
    setValue('dynamicAdTagline', selectedTactics?.dynamicAdTagline || '');
    setValue('dynamicAdCallToAction', selectedTactics?.dynamicAdCallToAction || '');
    setValue('categoryContextualInfo', normalizeNewLines(selectedTactics?.categoryContextualInfo) || '');
    setValue('relevantReferenceKeywords', normalizeNewLines(selectedTactics?.relevantReferenceKeywords) || '');
    setValue('uploader_standardGeoFenceAddressList', selectedTactics?.uploader_standardGeoFenceAddressList);
    setValue('uploader_addressableGeoFenceAddressList', selectedTactics?.uploader_addressableGeoFenceAddressList);
    setValue('uploader_searchRetargetingKeywordsList', selectedTactics?.uploader_searchRetargetingKeywordsList);
    setValue('uploader_keywordContextualKeywordsList', selectedTactics?.uploader_keywordContextualKeywordsList);
    setValue('sourceId', selectedTactics?.sourceId);
    setSelectedAdTypesRunning(selectedTactics?.adRunningTypes || []);
    setSelectedHuluAdLength(selectedTactics?.huluAdLength || []);
    // handleTacticType(selectedTactics?.tacticType || "");
    if (selectedTactics && selectedTactics.sourceId) setValue('sourceId', selectedTactics.sourceId);
    else setValue('sourceId', uuidv4());
  }, [selectedTactics]);

  const { fields: targetedCitiesFields, append: targetedCitiesAppend, remove: targetedCitiesRemove } = useFieldArray({
    control,
    name: 'targetedCities',
  });

  useEffect(() => {
    if (andOr2 === 'and') {
      unregister('andOr3');
      unregister('andOr4');
      unregister('segment4');
      unregister('segment5');
    } else if (andOr2 === 'or') {
      selectedTactics?.andOr3 !== '' && setValue('andOr3', selectedTactics.andOr3);
      selectedTactics?.andOr4 !== '' && setValue('andOr4', selectedTactics.andOr4);
      selectedTactics?.segment4 !== '' && setValue('segment4', selectedTactics.segment4);
      selectedTactics?.segment5 !== '' && setValue('segment5', selectedTactics.segment5);
    }
  }, [andOr2]);

  useEffect(() => {
    if (andOr3 === 'and') {
      unregister('andOr4');
      unregister('segment4');
      unregister('segment5');
    } else if (andOr3 === 'or') {
      selectedTactics?.andOr4 !== '' && setValue('andOr4', selectedTactics.andOr4);
      selectedTactics?.segment4 !== '' && setValue('segment4', selectedTactics.segment4);
      selectedTactics?.segment5 !== '' && setValue('segment5', selectedTactics.segment5);
    }
  }, [andOr3]);

  useEffect(() => {
    unregister('targetedCities', { keepDefaultValue: true, keepValue: true });
    unregister('dmaTargetedDetails', { keepDefaultValue: true, keepValue: true });

    if (targetingTypeSelected.city) {
      if (selectedTactics.targetedCities && selectedTactics.targetedCities.length > 0) {
        selectedTactics.targetedCities.forEach(city => targetedCitiesAppend({ value: city }));
      } else if (targetedCitiesFields.length < 1) targetedCitiesAppend({ value: '' });

      register('targetedCities', { required: true, minLength: 1 });
    } else if (targetingTypeSelected.dma) {
      register('dmaTargetedDetails', { required: true });
    }
  }, [targetingTypeSelected]);

  useEffect(() => {
    if (watch('adRunningTypes').length > 0) {
      setHasErrors(false);
    } else {
      setHasErrors(true);
    }
  }, [watch('adRunningTypes')]);

  useEffect(() => {
    if (eventStartDateData !== '' && new Date(eventStartDateData).getTime() < currentDate) {
      setEventStartDateError(true);
    } else {
      setEventStartDateError(false);
    }

    if (eventEndDateData !== '' && new Date(eventEndDateData).getTime() < currentDate) {
      setEventEndDateError(true);
    } else {
      setEventEndDateError(false);
    }
    if (eventStartDateData !== '' && eventEndDateData !== '') {
      if (new Date(eventStartDateData).getTime() > new Date(eventEndDateData).getTime()) {
        setEventDateError(true);
      } else {
        setEventDateError(false);
      }
    }
  }, [eventStartDateData, eventEndDateData]);

  const handleClose = () => {
    deleteUnusedUploadedFiles('close');
    onClose();
  };

  const handleFormSubmit = async (formInputData) => {
    // Remove unwanted fields from formInputData
    const { ...formData } = formInputData;

    // update Tactics Options with sourceId
    const updatedTacticsOption = {
      ...formData,
    };

    if (formData.adRunningTypes.find((adType) => adType.indexOf('Hulu') > -1) === undefined) {
      setSelectedHuluAdLength([]);
      formData.huluAdLength = [];
    }
    // removed all
    delete updatedTacticsOption.uploader_standardGeoFenceAddressList;
    delete updatedTacticsOption.uploader_addressableGeoFenceAddressList;
    delete updatedTacticsOption.uploader_eventAudienceRetargetingList;
    delete updatedTacticsOption.uploader_ztvList;
    delete updatedTacticsOption.uploader_searchRetargetingKeywordsList;
    delete updatedTacticsOption.uploader_keywordContextualKeywordsList;

    // set file to props
    if (fileUploaded !== undefined && fileUploaded.content !== undefined && fileUploaded.content.length > 0) {
      fileUploaded.content.forEach((data) => {
        if (data.uploaderType === fileUploaderType.geoFenceUploaderType && selectedTacticType.standardGeoFence) {
          updatedTacticsOption.uploader_standardGeoFenceAddressList = fileUploaded?.content
            .filter((file: any) => file.uploaderType.includes(fileUploaderType.geoFenceUploaderType));
        } else if (data.uploaderType === fileUploaderType.clientProvidedUploaderType && selectedTacticType.addressableGeoFenceClientProvided) {
          updatedTacticsOption.uploader_addressableGeoFenceAddressList = fileUploaded?.content
            .filter((file: any) => file.uploaderType.includes(fileUploaderType.clientProvidedUploaderType));
        } else if (data.uploaderType === fileUploaderType.eventUploaderType && selectedTacticType.eventAudienceRetargeting) {
          updatedTacticsOption.uploader_eventAudienceRetargetingList = fileUploaded?.content
            .filter((file: any) => file.uploaderType.includes(fileUploaderType.eventUploaderType));
        } else if (data.uploaderType === fileUploaderType.zTvUploaderType && selectedTacticType.ztv) {
          updatedTacticsOption.uploader_ztvList = fileUploaded?.content
            .filter((file: any) => file.uploaderType.includes(fileUploaderType.zTvUploaderType));
        } else if (data.uploaderType === fileUploaderType.searchUploaderType && selectedTacticType.searchRetargeting) {
          updatedTacticsOption.uploader_searchRetargetingKeywordsList = fileUploaded?.content
            .filter((file: any) => file.uploaderType.includes(fileUploaderType.searchUploaderType));
        } else if (data.uploaderType === fileUploaderType.keywordUploaderType && selectedTacticType.keywordContextual) {
          updatedTacticsOption.uploader_keywordContextualKeywordsList = fileUploaded?.content
            .filter((file: any) => file.uploaderType.includes(fileUploaderType.keywordUploaderType));
        }
      });
    }

    if (updatedTacticsOption?.geographicalArea === 'State(s)') {
      updatedTacticsOption.cities = '';
      updatedTacticsOption.congressionalDistricts = '';
      updatedTacticsOption.zipCodes = '';
    } else if (updatedTacticsOption?.geographicalArea === 'Metro(s)') {
      updatedTacticsOption.states = '';
      updatedTacticsOption.congressionalDistricts = '';
      updatedTacticsOption.zipCodes = '';
    } else if (updatedTacticsOption?.geographicalArea === 'Congressional District(s)') {
      updatedTacticsOption.states = '';
      updatedTacticsOption.cities = '';
      updatedTacticsOption.zipCodes = '';
    } else if (updatedTacticsOption?.geographicalArea === 'Zip Code(s)') {
      updatedTacticsOption.states = '';
      updatedTacticsOption.cities = '';
      updatedTacticsOption.congressionalDistricts = '';
    }

    if (tacticTypeSelected.gdn) updatedTacticsOption.targetedCities = updatedTacticsOption.targetedCities.map(city => city.value);

    deleteUnusedUploadedFiles('submit');

    if (updatedTacticsOption?.geographicalArea === 'State(s)') {
      updatedTacticsOption.cities = '';
      updatedTacticsOption.congressionalDistricts = '';
      updatedTacticsOption.zipCodes = '';
    } else if (updatedTacticsOption?.geographicalArea === 'Metro(s)') {
      updatedTacticsOption.states = '';
      updatedTacticsOption.congressionalDistricts = '';
      updatedTacticsOption.zipCodes = '';
    } else if (updatedTacticsOption?.geographicalArea === 'Congressional District(s)') {
      updatedTacticsOption.states = '';
      updatedTacticsOption.cities = '';
      updatedTacticsOption.zipCodes = '';
    } else if (updatedTacticsOption?.geographicalArea === 'Zip Code(s)') {
      updatedTacticsOption.states = '';
      updatedTacticsOption.cities = '';
      updatedTacticsOption.congressionalDistricts = '';
    }

    for (const prop in updatedTacticsOption) {
      if (prop === 'sourceId' || prop === 'tacticType') continue;
      if (
        !isPropIsAllowed(tacticTypeSelected, prop)
          || updatedTacticsOption[prop] === undefined
          || updatedTacticsOption[prop] === ''
          || (Array.isArray(updatedTacticsOption[prop])
              && updatedTacticsOption[prop].length < 1)
      ) delete updatedTacticsOption[prop];
    }
    updateTactics(updatedTacticsOption);
    onClose();
  };

  const handleUploaderError = () => {
    setFileUploadedError(
      fileUploaded?.content.length === 0
        ? t('pages.xmo.targetedDisplay.tactics.tacticFileUploadError')
        : '',
    );
  };

  const deleteUnusedUploadedFiles = (actionType: 'submit' | 'close') => {
    // delete uploaded files when not required
    const isNew = !(selectedTactics && selectedTactics.tacticType);

    const doDeletion = (actionType === 'submit') || (actionType === 'close' && isNew);

    if (doDeletion) {
      if (!tacticTypeSelected.addressableClientProvided) {
        const addressableGeoFenceAddressList = getValues('uploader_addressableGeoFenceAddressList');
        addressableGeoFenceAddressList?.forEach(file => dispatch(deleteFile({ websiteId, file })));
      }
      if (!tacticTypeSelected.standardGeofence) {
        const standardGeoFenceAddressList = getValues('uploader_standardGeoFenceAddressList');
        standardGeoFenceAddressList?.forEach(file => dispatch(deleteFile({ websiteId, file })));
      }
      if (!tacticTypeSelected.event) {
        const eventAudienceRetargetingList = getValues('uploader_eventAudienceRetargetingList');
        eventAudienceRetargetingList?.forEach(file => dispatch(deleteFile({ websiteId, file })));
      }
      if (!tacticTypeSelected.ztv) {
        const ztvList = getValues('uploader_ztvList');
        ztvList?.forEach(file => dispatch(deleteFile({ websiteId, file })));
      }
      if (!tacticTypeSelected.searchRetargeting) {
        const searchRetargetingKeywordsList = getValues('uploader_searchRetargetingKeywordsList');
        searchRetargetingKeywordsList?.forEach(file => dispatch(deleteFile({ websiteId, file })));
      }
      if (!tacticTypeSelected.keywordContextual) {
        const keywordContextualKeywordsList = getValues('uploader_keywordContextualKeywordsList');
        keywordContextualKeywordsList?.forEach(file => dispatch(deleteFile({ websiteId, file })));
      }
    }
  };

  const getItemsList = () => {
    if (tacticsPageForProductSelected.xmoBrandAmplifer || tacticsPageForProductSelected.xmoFreeParty) {
      return XmoTacticTypeList;
    } if (tacticsPageForProductSelected.targetedDisplay) {
      return targetedDisplayTacticTypeList;
    } if (tacticsPageForProductSelected.pmm) {
      return pmmTacticTypeList;
    }
  };
  // computed
  const fileUploaderType = useMemo(() => ({
    geoFenceUploaderType: `${ProductTypes.TARGETED_DISPLAY_GEO_FENCE_ADDRESS_LIST}-${sourceId}`,
    clientProvidedUploaderType: `${ProductTypes.TARGETED_DISPLAY_GEO_FENCE_ADDRESS_LIST_1}-${sourceId}`,
    eventUploaderType: `${ProductTypes.TARGETED_DISPLAY_EVENT_AUDIENCE_RETARGETING_LIST}-${sourceId}`,
    zTvUploaderType: `${ProductTypes.TARGETED_DISPLAY_ZTV_LIST}-${sourceId}`,
    searchUploaderType: `${ProductTypes.TARGETED_DISPLAY_SEARCH_RETARGETING_URLS}-${sourceId}`,
    keywordUploaderType: `${ProductTypes.TARGETED_DISPLAY_KEY_LIST}-${sourceId}`,
  }), [sourceId]);
  const selectedTacticType = useMemo(() => ({
    geoTargetOnly: tacticType === t('pages.targetedDisplay.tacticModel.tacticsTypes.geoTargetOnly'),
    standardGeoFence: tacticType === t('pages.targetedDisplay.tacticModel.tacticsTypes.standardGeoFence'),
    addressableGeoFenceClientProvided: tacticType === t('pages.targetedDisplay.tacticModel.tacticsTypes.addressableGeoFenceClientProvided'),
    addressableGeoFenceCuratedAudience: tacticType === t('pages.targetedDisplay.tacticModel.tacticsTypes.addressableGeoFenceCuratedAudience'),
    eventAudienceRetargeting: tacticType === t('pages.targetedDisplay.tacticModel.tacticsTypes.eventAudienceRetargeting'),
    ztv: tacticType === t('pages.targetedDisplay.tacticModel.tacticsTypes.ztv'),
    siteRetargeting: tacticType === t('pages.targetedDisplay.tacticModel.tacticsTypes.siteRetargeting'),
    dynamicSiteRetargeting: tacticType === t('pages.targetedDisplay.tacticModel.tacticsTypes.dynamicSiteRetargeting'),
    searchRetargeting: tacticType === t('pages.targetedDisplay.tacticModel.tacticsTypes.searchRetargeting'),
    dynamicSearchProspecting: tacticType === t('pages.targetedDisplay.tacticModel.tacticsTypes.dynamicSearchProspecting'),
    keywordContextual: tacticType === t('pages.targetedDisplay.tacticModel.tacticsTypes.keywordContextual'),
    categoryContextual: tacticType === t('pages.targetedDisplay.tacticModel.tacticsTypes.categoryContextual'),
    crmRetargeting: tacticType === t('pages.targetedDisplay.tacticModel.tacticsTypes.crmRetargeting'),
    lookalikes: tacticType === t('pages.targetedDisplay.tacticModel.tacticsTypes.lookalikes'),
  }), [tacticType]);

  const addressableExcelSpreadsheetLinkText = (
    <Link
      mr={1}
      href="https://thesource.reachlocal.com/resources/addressable-geofence-spreadsheet"
      target="_blank"
      rel="noopener noreferrer"
      sx={{ marginLeft: '5px' }}
    />
  );
  useEffect(() => {
    setAdTypesRunning([...selectedAdRunningTypes]);
  }, [selectedAdRunningTypes]);

  const handleHuluAdLength = (event) => {
    const { name, checked } = event.target;
    if (selectedTactics?.huluAdLength) {
      setSelectedHuluAdLength(huluAdLengthList
        .filter(option => selectedTactics?.huluAdLength.includes(option.description))
        .map(option => option.value));
    }
    if (checked) {
      setSelectedHuluAdLength((prev) => [...prev, name]); // Add to huluAdLength
    } else {
      setSelectedHuluAdLength((prev) => prev.filter((length) => length !== name)); // Remove from huluAdLength
    }
  };

  return (
    <Dialog open onClose={handleClose} maxWidth="lg"
      fullWidth
    >
      <DialogTitle>
        {t('pages.xmo.targetedDisplay.tactics.title')}
      </DialogTitle>
      <DialogContent>
        <ValidationProvider schema={TacticsFormDialogSchema}>
          <FormProvider {...methods}>
            <form>
              <GridContainer fullWidth>
                <GridItem sizes={[12]}>
                  <IQFormSelect
                    id="tacticType"
                    name="tacticType"
                    labelText={t(
                      'pages.xmo.targetedDisplay.tactics.tacticTypeLabel',
                    )}
                    items={getItemsList()}
                    defaultValue=""
                    theme={IQTheme}
                    fontLabelWeight="600"
                    fullWidth
                    schema={TacticsFormDialogSchema}
                    required
                  />
                </GridItem>
                {tacticType && tacticType !== '' && (
                  <GridItem>
                    <Typography variant="h6">{tacticType}</Typography>
                  </GridItem>
                )}

                {(tacticTypeSelected.addressableClientProvided
                      || tacticTypeSelected.addressableCurated || tacticTypeSelected.standardGeofence) && (
                      <GridItem sizes={[12]}>
                        <IQFormSelect
                          id="geoFenceSegmentLength"
                          name="geoFenceSegmentLength"
                          labelText={t(
                            'pages.xmo.targetedDisplay.tactics.addressableGeoFenceClient.geoFenceSegmentLabel',
                          )}
                          items={geofenceSegmentLengthList}
                          defaultValue=""
                          theme={IQTheme}
                          fontLabelWeight="600"
                          fullWidth
                          schema={TacticsFormDialogSchema}
                          required
                        />
                        <Grid mt={-3.5} fontSize="0.9rem" fontWeight={600}>
                          <FormHelperText variant="filled">
                            {t(
                              'pages.xmo.targetedDisplay.tactics.addressableGeoFenceClient.geoFenceSegmentDescription',
                            )}
                          </FormHelperText>
                        </Grid>
                      </GridItem>
                )}
                { tacticTypeSelected.standardGeofence && (
                  <>
                    <Grid item xs={12} sx={{ marginBottom: '8px' }}>
                      <Typography>{t('pages.targetedDisplay.tacticModel.standardGeofence.geoFenceSegmentHelpText_1')}</Typography>
                      <Typography>
                        {t('pages.targetedDisplay.tacticModel.standardGeofence.geoFenceSegmentHelpText_2')}
                        <Link mr={1}
                          href="https://thesource.reachlocal.com/resources/geofence-targeting-submission-template"
                          target="_blank" rel="noopener noreferrer" sx={{ marginLeft: '5px' }}
                        >
                          {t('pages.targetedDisplay.tacticModel.standardGeofence.geoFenceSegmentExcelSheetLink')}
                        </Link>
                        {t('pages.targetedDisplay.tacticModel.standardGeofence.geoFenceSegmentHelpText_3')}
                      </Typography>
                      <Typography>
                        {t('pages.targetedDisplay.tacticModel.standardGeofence.geoFenceSegmentHelpText_4').split('\n').map((para) => <li>{para}</li>)}
                      </Typography>

                    </Grid>
                    <Grid item xs={12} sx={{ marginBottom: '8px' }}>
                      <FileUploader
                        name="uploader_standardGeoFenceAddressList"
                        label={t('pages.targetedDisplay.tacticModel.standardGeofence.geoFenceAddressListLabel')}
                        uploaderType={fileUploaderType.geoFenceUploaderType}
                        totalAllowedFiles={1}
                        imageTypes={[]}
                        videoTypes={[]}
                        schema
                        documentTypes={['.csv', '.xls', '.xlsx']}
                        supportingText={t('pages.targetedDisplay.tacticModel.standardGeofence.geoFenceAddressListDescription')}
                        maxFileSize={defaultFileSize}
                        currentUploadedFiles={fileUploaded?.content.length}
                        onBlur={handleUploaderError}
                        requiredError={fileUploadedError}
                      />
                    </Grid>
                  </>
                )}
                {tacticTypeSelected.addressableClientProvided && (
                  <>
                    <Grid item xs={12} sx={{ marginBottom: '8px' }}>
                      <Typography>
                        {t(
                          'pages.xmo.targetedDisplay.tactics.addressableGeoFenceClient.descriptionText.text1',
                        )}
                      </Typography>
                      <Typography>
                        <Trans
                          i18nKey="pages.xmo.targetedDisplay.tactics.addressableGeoFenceClient.descriptionText.text2"
                          components={{
                            linkTag: addressableExcelSpreadsheetLinkText,
                          }}
                          t={t}
                        />
                      </Typography>
                      <Typography>
                        <ul>
                          <li>
                            {t(
                              'pages.xmo.targetedDisplay.tactics.addressableGeoFenceClient.descriptionText.line1',
                            )}
                          </li>
                          <li>
                            {t(
                              'pages.xmo.targetedDisplay.tactics.addressableGeoFenceClient.descriptionText.line2',
                            )}
                          </li>
                          <li>
                            {t(
                              'pages.xmo.targetedDisplay.tactics.addressableGeoFenceClient.descriptionText.line3',
                            )}
                          </li>
                          <li>
                            {t(
                              'pages.xmo.targetedDisplay.tactics.addressableGeoFenceClient.descriptionText.line4',
                            )}
                          </li>
                        </ul>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ marginBottom: '8px' }}>
                      {sourceId && (
                      <FileUploader
                        name="uploader_addressableGeoFenceAddressListUpload"
                        label={t(
                          'pages.xmo.targetedDisplay.tactics.addressableGeoFenceClient.geoFenceAddressListLabel',
                        )}
                        uploaderType={fileUploaderType.clientProvidedUploaderType}
                        totalAllowedFiles={1}
                        imageTypes={[]}
                        videoTypes={[]}
                        documentTypes={['.csv', '.xls', '.xlsx']}
                        supportingText={t(
                          'pages.xmo.targetedDisplay.tactics.addressableGeoFenceClient.geoFenceAddressListDescription',
                        )}
                        maxFileSize={defaultFileSize}
                        currentUploadedFiles={fileUploaded?.content.length}
                        onBlur={handleUploaderError}
                        requiredError={fileUploadedError}
                      />
                      )}
                    </Grid>
                  </>
                )}

                {tacticTypeSelected.addressableCurated && (
                  <>
                    <Grid item xs={12} sx={{ marginBottom: '8px' }}>
                      <IQFormSelect
                        id="geographicalArea"
                        name="geographicalArea"
                        labelText={t(
                          'pages.xmo.targetedDisplay.tactics.addressableGeoFenceCurated.geographicalAreaLabel',
                        )}
                        items={geoGraphicalAreaList}
                        defaultValue=""
                        theme={IQTheme}
                        fontLabelWeight="600"
                        fullWidth
                        schema={TacticsFormDialogSchema}
                        required
                      />
                      <Grid mt={-3.5} fontSize="0.9rem" fontWeight={600}>
                        <FormHelperText variant="filled">
                          {t(
                            'pages.xmo.targetedDisplay.tactics.standardGeofence.geoFenceSegmentDescription',
                          )}
                        </FormHelperText>
                      </Grid>
                    </Grid>
                    {geoGraphicalAreaSelected.states && (
                    <GridItem sizes={[12]}>
                      <IQFormTextArea
                        id="states"
                        labelText={t(
                          'pages.xmo.targetedDisplay.tactics.addressableGeoFenceCurated.statesLabel',
                        )}
                        name="states"
                        rowCount={2}
                        fullWidth
                        fontLabelWeight="600"
                        required
                      />
                    </GridItem>
                    )}

                    {geoGraphicalAreaSelected.metros && (
                    <GridItem sizes={[12]}>
                      <IQFormTextArea
                        id="cities"
                        labelText={t(
                          'pages.xmo.targetedDisplay.tactics.addressableGeoFenceCurated.citiesLabel',
                        )}
                        name="cities"
                        rowCount={2}
                        fullWidth
                        fontLabelWeight="600"
                        required
                      />
                    </GridItem>
                    )}

                    {geoGraphicalAreaSelected.congressionalDistricts && (
                    <GridItem sizes={[12]}>
                      <IQFormTextArea
                        id="congressionalDistricts"
                        labelText={t(
                          'pages.xmo.targetedDisplay.tactics.addressableGeoFenceCurated.congressionalDistrictLabel',
                        )}
                        name="congressionalDistricts"
                        rowCount={2}
                        fullWidth
                        fontLabelWeight="600"
                        required
                      />
                    </GridItem>
                    )}

                    {geoGraphicalAreaSelected.zipCodes && (
                    <GridItem sizes={[12]}>
                      <IQFormTextArea
                        id="zipCodes"
                        labelText={t(
                          'pages.xmo.targetedDisplay.tactics.addressableGeoFenceCurated.zipCodesLabel',
                        )}
                        name="zipCodes"
                        rowCount={2}
                        fullWidth
                        fontLabelWeight="600"
                        required
                      />
                    </GridItem>
                    )}

                    <Grid item xs={12} sx={{ marginBottom: '8px' }}>
                      <Typography>
                        {t(
                          'pages.xmo.targetedDisplay.tactics.addressableGeoFenceCurated.geoFenceSegmentHelpText_1',
                        )}
                      </Typography>
                      <Typography sx={{ fontWeight: 'bold' }}>
                        {t(
                          'pages.xmo.targetedDisplay.tactics.addressableGeoFenceCurated.geoFenceSegmentHelpText_2',
                        )}
                      </Typography>
                      <Typography>
                        {t(
                          'pages.xmo.targetedDisplay.tactics.addressableGeoFenceCurated.geoFenceSegmentHelpText_3',
                        )}
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      mt="0.5rem"
                      sx={{ marginBottom: '8px' }}
                    >
                      <IQFormInput
                        id="segment1"
                        name="segment1"
                        labelText={t(
                          'pages.xmo.targetedDisplay.tactics.addressableGeoFenceCurated.segment1Label',
                        )}
                        theme={IQTheme}
                        fontLabelWeight="600"
                        fullWidth
                        schema={TacticsFormDialogSchema}
                      />
                    </Grid>
                    <Grid item xs={12} sx={{ marginBottom: '8px' }}>
                      <RadioGroup
                        id="andOr1"
                        name="andOr1"
                        aria-labelledby="topic-location"
                        row
                        defaultValue={selectedTactics.andOr1 || ''}
                      >
                        {andOrList.map((option) => (
                          <FormControlLabel
                            key={option.value}
                            control={<Radio value={option.value} />}
                            {...register('andOr1', {
                              validate: (value) =>
                                schemaValidate(
                                  value,
                                  'andOr1',
                                  TacticsFormDialogSchema,
                                ),
                            })}
                            label={(
                              <Grid
                                item
                                xs={12}
                                sx={{ display: 'flex', flexDirection: 'row' }}
                              >
                                <Typography>{option.description}</Typography>
                                {option.value === 'And' && (
                                <Tooltip
                                  title={t(
                                    'pages.xmo.targetedDisplay.tactics.addressableGeoFenceCurated.segmentAndToolTip',
                                  )}
                                  placement="right-end"
                                >
                                  <InfoOutlinedIcon
                                    color="primary"
                                    sx={{
                                      marginLeft: 1,
                                      fontSize: '15px',
                                      marginTop: 0.6,
                                    }}
                                  />
                                </Tooltip>
                                )}
                                {option.value === 'Or' && (
                                <Tooltip
                                  title={t(
                                    'pages.xmo.targetedDisplay.tactics.addressableGeoFenceCurated.segmentOrToolTip',
                                  )}
                                  placement="right-end"
                                >
                                  <InfoOutlinedIcon
                                    color="primary"
                                    sx={{
                                      marginLeft: 1,
                                      fontSize: '15px',
                                      marginTop: 0.6,
                                    }}
                                  />
                                </Tooltip>
                                )}
                              </Grid>
                                    )}
                          />
                        ))}
                      </RadioGroup>
                    </Grid>

                    <Grid item xs={12} sx={{ marginBottom: '8px' }}>
                      <IQFormInput
                        id="segment2"
                        name="segment2"
                        labelText={t(
                          'pages.xmo.targetedDisplay.tactics.addressableGeoFenceCurated.segment2Label',
                        )}
                        theme={IQTheme}
                        fontLabelWeight="600"
                        fullWidth
                        schema={TacticsFormDialogSchema}
                      />
                    </Grid>
                    <Grid item xs={12} sx={{ marginBottom: '8px' }}>
                      <RadioGroup
                        id="andOr2"
                        name="andOr2"
                        aria-labelledby="topic-location"
                        row
                        defaultValue={selectedTactics.andOr2 || ''}
                      >
                        {andOrList.map((option) => (
                          <FormControlLabel
                            key={option.value}
                            control={<Radio value={option.value} />}
                            {...register('andOr2', {
                              validate: (value) =>
                                schemaValidate(
                                  value,
                                  'andOr2',
                                  TacticsFormDialogSchema,
                                ),
                            })}
                            label={(
                              <Grid
                                item
                                xs={12}
                                sx={{ display: 'flex', flexDirection: 'row' }}
                              >
                                <Typography>{option.description}</Typography>
                                {option.value === 'And' && (
                                <Tooltip
                                  title={t(
                                    'pages.xmo.targetedDisplay.tactics.addressableGeoFenceCurated.segmentAndToolTip',
                                  )}
                                  placement="right-end"
                                >
                                  <InfoOutlinedIcon
                                    color="primary"
                                    sx={{
                                      marginLeft: 1,
                                      fontSize: '15px',
                                      marginTop: 0.6,
                                    }}
                                  />
                                </Tooltip>
                                )}
                                {option.value === 'Or' && (
                                <Tooltip
                                  title={t(
                                    'pages.xmo.targetedDisplay.tactics.addressableGeoFenceCurated.segmentOrToolTip',
                                  )}
                                  placement="right-end"
                                >
                                  <InfoOutlinedIcon
                                    color="primary"
                                    sx={{
                                      marginLeft: 1,
                                      fontSize: '15px',
                                      marginTop: 0.6,
                                    }}
                                  />
                                </Tooltip>
                                )}
                              </Grid>
                                    )}
                          />
                        ))}
                      </RadioGroup>
                    </Grid>

                    <Grid item xs={12} sx={{ marginBottom: '8px' }}>
                      <IQFormInput
                        id="segment3"
                        name="segment3"
                        labelText={t(
                          'pages.xmo.targetedDisplay.tactics.addressableGeoFenceCurated.segment3Label',
                        )}
                        theme={IQTheme}
                        fontLabelWeight="600"
                        fullWidth
                        schema={TacticsFormDialogSchema}
                      />
                    </Grid>
                    {andOr2 === 'or' && (
                    <Grid item xs={12} sx={{ marginBottom: '8px' }}>
                      <RadioGroup
                        id="andOr3"
                        name="andOr3"
                        aria-labelledby="topic-location"
                        row
                        defaultValue={selectedTactics.andOr3 || ''}
                      >
                        {andOrList.map((option) => (
                          <FormControlLabel
                            key={option.value}
                            control={<Radio value={option.value} />}
                            {...register('andOr3', {
                              validate: (value) =>
                                schemaValidate(
                                  value,
                                  'andOr3',
                                  TacticsFormDialogSchema,
                                ),
                            })}
                            label={(
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                }}
                              >
                                <Typography>
                                  {option.description}
                                </Typography>
                                {option.value === 'And' && (
                                <Tooltip
                                  title={t(
                                    'pages.xmo.targetedDisplay.tactics.addressableGeoFenceCurated.segmentAndToolTip',
                                  )}
                                  placement="right-end"
                                >
                                  <InfoOutlinedIcon
                                    color="primary"
                                    sx={{
                                      marginLeft: 1,
                                      fontSize: '15px',
                                      marginTop: 0.6,
                                    }}
                                  />
                                </Tooltip>
                                )}
                                {option.value === 'Or' && (
                                <Tooltip
                                  title={t(
                                    'pages.xmo.targetedDisplay.tactics.addressableGeoFenceCurated.segmentOrToolTip',
                                  )}
                                  placement="right-end"
                                >
                                  <InfoOutlinedIcon
                                    color="primary"
                                    sx={{
                                      marginLeft: 1,
                                      fontSize: '15px',
                                      marginTop: 0.6,
                                    }}
                                  />
                                </Tooltip>
                                )}
                              </Grid>
                                        )}
                          />
                        ))}
                      </RadioGroup>
                    </Grid>
                    )}

                    {andOr3 === 'or' && (
                    <>
                      <Grid item xs={12} sx={{ marginBottom: '8px' }}>
                        <IQFormInput
                          id="segment4"
                          name="segment4"
                          labelText={t(
                            'pages.xmo.targetedDisplay.tactics.addressableGeoFenceCurated.segment4Label',
                          )}
                          theme={IQTheme}
                          fontLabelWeight="600"
                          fullWidth
                          schema={TacticsFormDialogSchema}
                        />
                      </Grid>
                      <Grid item xs={12} sx={{ marginBottom: '8px' }}>
                        <RadioGroup
                          id="andOr4"
                          name="andOr4"
                          aria-labelledby="topic-location"
                          row
                          defaultValue={selectedTactics.andOr4 || ''}
                        >
                          {andOrList.map((option) => (
                            <FormControlLabel
                              key={option.value}
                              control={<Radio value={option.value} />}
                              {...register('andOr4', {
                                validate: (value) =>
                                  schemaValidate(
                                    value,
                                    'andOr4',
                                    TacticsFormDialogSchema,
                                  ),
                              })}
                              label={(
                                <Grid
                                  item
                                  xs={12}
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                  }}
                                >
                                  <Typography>
                                    {option.description}
                                  </Typography>
                                  {option.value === 'And' && (
                                  <Tooltip
                                    title={t(
                                      'pages.xmo.targetedDisplay.tactics.addressableGeoFenceCurated.segmentAndToolTip',
                                    )}
                                    placement="right-end"
                                  >
                                    <InfoOutlinedIcon
                                      color="primary"
                                      sx={{
                                        marginLeft: 1,
                                        fontSize: '15px',
                                        marginTop: 0.6,
                                      }}
                                    />
                                  </Tooltip>
                                  )}
                                  {option.value === 'Or' && (
                                  <Tooltip
                                    title={t(
                                      'pages.xmo.targetedDisplay.tactics.addressableGeoFenceCurated.segmentOrToolTip',
                                    )}
                                    placement="right-end"
                                  >
                                    <InfoOutlinedIcon
                                      color="primary"
                                      sx={{
                                        marginLeft: 1,
                                        fontSize: '15px',
                                        marginTop: 0.6,
                                      }}
                                    />
                                  </Tooltip>
                                  )}
                                </Grid>
                                          )}
                            />
                          ))}
                        </RadioGroup>
                      </Grid>
                    </>
                    )}

                    {andOr4 === 'or' && (
                    <Grid item xs={12} sx={{ marginBottom: '8px' }}>
                      <IQFormInput
                        id="segment5"
                        name="segment5"
                        labelText={t(
                          'pages.xmo.targetedDisplay.tactics.addressableGeoFenceCurated.segment5Label',
                        )}
                        theme={IQTheme}
                        fontLabelWeight="600"
                        fullWidth
                        schema={TacticsFormDialogSchema}
                      />
                    </Grid>
                    )}
                  </>
                )}
                {tacticTypeSelected.event && (
                  <>
                    <Grid item xs={12} sx={{ marginBottom: '8px' }}>
                      <IQFormSelect
                        id="eventAudienceRetargetingSegmentLength"
                        name="eventAudienceRetargetingSegmentLength"
                        labelText={t('pages.targetedDisplay.tacticModel.eventAudienceRetargeting.eventAudienceRetargetingSegmentLengthLabel')}
                        items={geofenceSegmentLengthList}
                        defaultValue=""
                        theme={IQTheme}
                        fontLabelWeight="600"
                        fullWidth
                        schema={TacticsFormDialogSchema}
                        required
                      />
                      <Grid mt={-3.5} fontSize="0.9rem" fontWeight={600}>
                        <FormHelperText
                          variant="filled"
                        >
                          {t('pages.targetedDisplay.tacticModel.standardGeofence.geoFenceSegmentDescription')}
                        </FormHelperText>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ marginBottom: '8px' }}>
                      <IQFormInput
                        id="eventName"
                        name="eventName"
                        labelText={t('pages.targetedDisplay.tacticModel.eventAudienceRetargeting.eventNameLabel')}
                        theme={IQTheme}
                        fontLabelWeight="600"
                        fullWidth
                        schema={TacticsFormDialogSchema}
                      />
                    </Grid>
                    <Grid item xs={12} sx={{ marginBottom: '8px' }}>
                      <IQFormTextArea
                        id="detailsOfEvent"
                        labelText={t('pages.targetedDisplay.tacticModel.eventAudienceRetargeting.eventDetailsLabel')}
                        name="detailsOfEvent"
                        rowCount={2}
                        fullWidth
                        fontLabelWeight="600"
                      />
                    </Grid>
                    <GridItem sizes={[6, 6]} columnSpacing={2}>
                      <Controller
                        name="eventStartDate"
                        control={methods.control}
                        defaultValue={eventStartDateData}
                        render={({ field }) => (
                          <IQDatePicker
                            id="eventStartDate"
                            {...field}
                            schema={TacticsFormDialogSchema}
                            theme={IQTheme}
                            fontLabelWeight="600"
                            labelText="Start Date"
                            fullWidth
                            onChange={(e: any) => setEventStartDateData(e)}
                          />
                        )}
                      />

                      <Controller
                        name="eventEndDate"
                        control={methods.control}
                        defaultValue={eventEndDateData}
                        render={({ field }) => (
                          <IQDatePicker
                            id="eventEndDate"
                            {...field}
                            schema={TacticsFormDialogSchema}
                            theme={IQTheme}
                            fontLabelWeight="600"
                            labelText="End Date"
                            fullWidth
                            onChange={(e: any) => setEventEndDateData(e)}
                          />
                        )}
                      />

                    </GridItem>
                    <GridItem sizes={[6, 6]} columnSpacing={2}>
                      <Typography sx={{ color: '#C20F1E', fontSize: '14px' }} variant="subtitle1">
                        {eventStartDateError && (
                        <ErrorIcon sx={{
                          fontSize: '16px',
                          marginTop: '6px',
                        }}
                        />
                        )}
                        {' '}
                        {eventStartDateError ? t('pages.targetedDisplay.tacticModel.eventAudienceRetargeting.eventDateError') : ''}
                      </Typography>
                      <Typography sx={{ color: '#C20F1E', fontSize: '14px' }} variant="subtitle1">
                        {eventEndDateError && (
                        <ErrorIcon sx={{
                          fontSize: '16px',
                          marginTop: '6px',
                        }}
                        />
                        )}
                        {' '}
                        {eventEndDateError ? t('pages.targetedDisplay.tacticModel.eventAudienceRetargeting.eventDateError') : ''}
                      </Typography>
                    </GridItem>
                    <Grid item xs={12} marginTop={-2}>
                      <Typography sx={{ color: '#C20F1E', fontSize: '14px' }} variant="subtitle1">
                        {eventDateError && (
                        <ErrorIcon sx={{
                          fontSize: '16px',
                          marginTop: '6px',
                        }}
                        />
                        )}
                        {' '}
                        {eventDateError ? t('pages.targetedDisplay.solutionStrategy.endDateAfterError') : ''}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ marginBottom: '8px' }}>
                      <IQFormInput
                        id="timeZone"
                        name="timeZone"
                        labelText={t('pages.targetedDisplay.tacticModel.eventAudienceRetargeting.timeZone')}
                        theme={IQTheme}
                        fontLabelWeight="600"
                        fullWidth
                        schema={TacticsFormDialogSchema}
                      />
                    </Grid>
                    <Grid item xs={12} sx={{ marginBottom: '5px', marginTop: '3%' }}>
                      <FileUploader
                        name="uploader_eventAudienceRetargetingList"
                        supportingText=""
                        label={t('pages.targetedDisplay.tacticModel.eventAudienceRetargeting.eventAudienceRetargetingListUploadLabel')}
                        uploaderType={fileUploaderType.eventUploaderType}
                        totalAllowedFiles={1}
                        imageTypes={[]}
                        videoTypes={[]}
                        documentTypes={['.csv', '.xls', '.xlsx']}
                        maxFileSize={defaultFileSize}
                        currentUploadedFiles={fileUploaded?.content.length}
                        onBlur={handleUploaderError}
                        requiredError={fileUploadedError}
                      />
                    </Grid>
                  </>
                )}
                {tacticTypeSelected.ztv && (
                  <GridItem sizes={[12]}>
                    <FileUploader
                      name="uploader_ztvList"
                      label={t('pages.targetedDisplay.tacticModel.ztv.ZTVListLabel')}
                      uploaderType={fileUploaderType.zTvUploaderType}
                      totalAllowedFiles={1}
                      imageTypes={[]}
                      videoTypes={[]}
                      documentTypes={['.csv', '.xls', '.xlsx']}
                      maxFileSize={defaultFileSize}
                      currentUploadedFiles={fileUploaded?.content.length}
                      onBlur={handleUploaderError}
                      requiredError={fileUploadedError}
                    />
                  </GridItem>
                )}
                {tacticTypeSelected.siteRetargeting && (
                  <GridItem sizes={[12]}>
                    <IQFormTextArea
                      id="siteRetargetingUrls"
                      labelText={t('pages.targetedDisplay.tacticModel.siteRetargeting.siteRetargetingUrlsLabel')}
                      name="siteRetargetingUrls"
                      rowCount={2}
                      fullWidth
                      fontLabelWeight="600"
                      required
                      showError
                    />
                    <Grid mt={-3.5} fontSize="0.9rem" fontWeight={600}>
                      <FormHelperText
                        variant="filled"
                      >
                        {t('pages.targetedDisplay.tacticModel.siteRetargeting.siteRetargetingUrlsDescription')}
                      </FormHelperText>
                    </Grid>
                  </GridItem>
                )}
                {(tacticTypeSelected.dynamicSearchProspecting || tacticTypeSelected.dynamicSiteRetargeting) && (
                  <>
                    <Grid item>
                      <Typography variant="subtitle2" component="h3" fontSize={16}>
                        {t('pages.targetedDisplay.tacticModel.dynamicSiteRetargeting.inventoryTargetLabel')}
                      </Typography>
                      <RadioGroup
                        id="inventoryTarget"
                        name="inventoryTarget"
                        aria-labelledby="topic-location"
                        row
                        defaultValue={selectedTactics?.inventoryTarget || undefined}
                      >
                        {dspTacticComeFromList.map(option => (
                          <FormControlLabel
                            key={option.value}
                            control={<Radio value={option.value} />}
                            label={option.description}
                            {...register('inventoryTarget', {
                              validate: (value) => schemaValidate(value, 'inventoryTarget', TacticsFormDialogSchema),
                            })}
                          />
                        ))}
                      </RadioGroup>
                    </Grid>
                    <Grid item xs={12} sx={{ marginBottom: '8px' }}>
                      <IQFormInput
                        id="dynamicAdHeadline"
                        name="dynamicAdHeadline"
                        labelText={t('pages.targetedDisplay.tacticModel.dynamicSiteRetargeting.headLineLabel')}
                        theme={IQTheme}
                        fontLabelWeight="600"
                        fullWidth
                        schema={TacticsFormDialogSchema}
                      />
                    </Grid>
                    <Grid item xs={12} sx={{ marginBottom: '8px' }}>
                      <IQFormInput
                        id="dynamicAdTagline"
                        name="dynamicAdTagline"
                        labelText={t('pages.targetedDisplay.tacticModel.dynamicSiteRetargeting.tagLineLabel')}
                        theme={IQTheme}
                        fontLabelWeight="600"
                        fullWidth
                        schema={TacticsFormDialogSchema}
                      />
                    </Grid>
                    <Grid item xs={12} sx={{ marginBottom: '8px' }}>
                      <IQFormInput
                        id="dynamicAdCallToAction"
                        name="dynamicAdCallToAction"
                        labelText={t('pages.targetedDisplay.tacticModel.dynamicSiteRetargeting.callToActionLabel')}
                        theme={IQTheme}
                        fontLabelWeight="600"
                        fullWidth
                        schema={TacticsFormDialogSchema}
                      />
                    </Grid>
                  </>
                )}
                {(tacticTypeSelected.searchRetargeting || tacticTypeSelected.keywordContextual) && (
                  <GridItem sizes={[12]}>
                    <IQFormTextArea
                      id="relevantReferenceKeywords"
                      labelText={t('pages.targetedDisplay.tacticModel.searchRetargeting.relevantReferenceKeywordsLabel')}
                      name="relevantReferenceKeywords"
                      rowCount={2}
                      fullWidth
                      fontLabelWeight="600"
                    />
                    <Grid mt={-3.5} fontSize="0.9rem" fontWeight={600}>
                      <FormHelperText
                        variant="filled"
                      >
                        {t('pages.targetedDisplay.tacticModel.searchRetargeting.searchRetargetingDescription')}
                      </FormHelperText>
                    </Grid>
                  </GridItem>
                )}
                {tacticTypeSelected.searchRetargeting && (
                  <GridItem sizes={[12]}>
                    <FileUploader
                      name="uploader_searchRetargetingKeywordsList"
                      label={t('pages.targetedDisplay.tacticModel.siteRetargeting.siteRetargetingUrlsLabel')}
                      uploaderType={fileUploaderType.searchUploaderType}
                      totalAllowedFiles={1}
                      imageTypes={[]}
                      videoTypes={[]}
                      documentTypes={['.csv', '.xls', '.xlsx']}
                      maxFileSize={defaultFileSize}
                      currentUploadedFiles={fileUploaded?.content.length}
                      onBlur={handleUploaderError}
                      requiredError={fileUploadedError}
                    />
                  </GridItem>
                )}
                {tacticTypeSelected.keywordContextual && (
                  <GridItem sizes={[12]}>
                    <FileUploader
                      name="uploader_keywordContextualKeywordsList"
                      label={t('pages.targetedDisplay.tacticModel.keywordContextual.uploadKeyListLabel')}
                      uploaderType={fileUploaderType.keywordUploaderType}
                      totalAllowedFiles={1}
                      imageTypes={[]}
                      videoTypes={[]}
                      documentTypes={['.csv', '.xls', '.xlsx']}
                      maxFileSize={defaultFileSize}
                      currentUploadedFiles={fileUploaded?.content.length}
                      onBlur={handleUploaderError}
                      requiredError={fileUploadedError}
                    />
                  </GridItem>
                )}
                {tacticTypeSelected.categoryContextual && (
                  <GridItem sizes={[12]}>
                    <IQFormTextArea
                      id="categoryContextualInfo"
                      name="categoryContextualInfo"
                      labelText={t('pages.targetedDisplay.tacticModel.categoryContextual.categoryContextualLabel')}
                      rowCount={2}
                      fullWidth
                      fontLabelWeight="600"
                      required
                      showError
                      tooltipText={t('pages.targetedDisplay.tacticModel.categoryContextual.categoryContextualTooltip')}
                    />
                    <Grid mt={-3.5} fontSize="0.9rem" fontWeight={600}>
                      <FormHelperText
                        variant="filled"
                      >
                        {t('pages.targetedDisplay.tacticModel.categoryContextual.categoryContextualDescription')}
                      </FormHelperText>
                    </Grid>
                  </GridItem>
                )}

                {tacticType !== '' && (
                  <GridItem sizes={[12]}>
                    <Typography variant="h6">
                      {t('pages.xmo.targetedDisplay.tactics.tacticInfo')}
                    </Typography>
                  </GridItem>
                )}

                {tacticType !== '' && !tacticTypeSelected.gdn && (
                  <>
                    <GridItem sizes={[12]}>
                      <IQFormTextArea
                        id="specificProductOrService"
                        labelText={t(
                          'pages.xmo.targetedDisplay.tactics.specificProductOrServiceLabel',
                        )}
                        name="specificProductOrService"
                        rowCount={2}
                        fullWidth
                        fontLabelWeight="600"
                        required
                        showError
                      />
                    </GridItem>
                    <Grid item xs={12} sx={{ marginBottom: '8px' }}>
                      <IQFormInput
                        id="tacticNameInClientReporting"
                        name="tacticNameInClientReporting"
                        labelText={t(
                          'pages.xmo.targetedDisplay.tactics.clientReportingTacticLabel',
                        )}
                        theme={IQTheme}
                        fontLabelWeight="600"
                        fullWidth
                        schema={TacticsFormDialogSchema}
                        showError
                      />
                    </Grid>
                    <Grid mt={-1} fontSize="0.9rem" fontWeight={600}>
                      <FormHelperText variant="filled">
                        {t(
                          'pages.xmo.targetedDisplay.tactics.clientReportingTacticDescription',
                        )}
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={12} mt={2}
                      sx={{ marginBottom: '8px' }}
                    >
                      <Typography
                        variant="subtitle2"
                        component="h3"
                        fontSize={16}
                      >
                        {t(
                          'pages.xmo.targetedDisplay.tactics.uniqueLandingPageLabel',
                        )}
                      </Typography>
                      <RadioGroup
                        id="uniqueLandingPage"
                        name="uniqueLandingPage"
                        aria-labelledby="topic-location"
                        row
                        defaultValue={selectedTactics.uniqueLandingPage || ''}
                      >
                        {yesNoList.map((option) => (
                          <FormControlLabel
                            key={option.value}
                            control={<Radio value={option.value} />}
                            label={option.description}
                            {...register('uniqueLandingPage', {
                              validate: (value) =>
                                schemaValidate(
                                  value,
                                  'uniqueLandingPage',
                                  TacticsFormDialogSchema,
                                ),
                            })}
                          />
                        ))}
                      </RadioGroup>
                    </Grid>
                    {uniqueLandingPage === 'yes' && (
                    <GridItem sizes={[12]}>
                      <IQFormInput
                        id="landingPageUrl"
                        name="landingPageUrl"
                        labelText={t(
                          'pages.xmo.targetedDisplay.tactics.landingPageURLLabel',
                        )}
                        theme={IQTheme}
                        fontLabelWeight="600"
                        fullWidth
                        schema={TacticsFormDialogSchema}
                        adornmentIcon={<InsertLinkIcon />}
                      />
                    </GridItem>
                    )}
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle2"
                        component="h3"
                        fontSize={16}
                      >
                        {t(
                          'pages.xmo.targetedDisplay.tactics.tacticFromLabel',
                        )}
                      </Typography>
                      <RadioGroup
                        id="tacticComeFrom"
                        name="tacticComeFrom"
                        aria-labelledby="topic-location"
                        defaultValue={selectedTactics.tacticComeFrom || ''}
                      >
                        {tacticComeFromList.map((option) => (
                          <FormControlLabel
                            key={option.value}
                            value={option.value}
                            control={<Radio value={option.value} />}
                            label={option.description}
                            {...register('tacticComeFrom', {
                              validate: (value) =>
                                schemaValidate(
                                  value,
                                  'tacticComeFrom',
                                  TacticsFormDialogSchema,
                                ),
                            })}
                          />
                        ))}
                      </RadioGroup>
                    </Grid>
                    {tacticComeFromSelected.clientProvided && (
                    <GridItem sizes={[12]}>
                      <Grid item>
                        <Typography
                          variant="subtitle2"
                          component="h3"
                          fontSize={16}
                        >
                          {t(
                            'pages.xmo.targetedDisplay.tactics.salesForceUploadedLabel',
                          )}
                        </Typography>
                        <RadioGroup
                          id="uploadedInSalesForce"
                          name="uploadedInSalesForce"
                          aria-labelledby="topic-location"
                          row
                          defaultValue={
                                      selectedTactics.uploadedInSalesForce || ''
                                  }
                        >
                          {yesNoList.map((option) => (
                            <FormControlLabel
                              key={option.value}
                              control={<Radio value={option.value} />}
                              label={option.description}
                              {...register('uploadedInSalesForce', {
                                validate: (value) =>
                                  schemaValidate(
                                    value,
                                    'uploadedInSalesForce',
                                    TacticsFormDialogSchema,
                                  ),
                              })}
                            />
                          ))}
                        </RadioGroup>
                      </Grid>
                    </GridItem>
                    )}
                    {tacticComeFromSelected.designIq && (
                    <>
                      <Grid item xs={12} sx={{ marginBottom: '8px' }}>
                        <IQFormInput
                          id="designiqLink"
                          name="designiqLink"
                          labelText={t(
                            'pages.xmo.targetedDisplay.tactics.designIQLinkLabel',
                          )}
                          theme={IQTheme}
                          fontLabelWeight="600"
                          fullWidth
                          schema={TacticsFormDialogSchema}
                          adornmentIcon={<InsertLinkIcon />}
                        />
                      </Grid>
                      <Grid item xs={12} sx={{ marginBottom: '8px' }}>
                        <IQFormInput
                          id="creativeServiceCid"
                          name="creativeServiceCid"
                          labelText={t(
                            'pages.xmo.targetedDisplay.tactics.creativeServiceCIDLabel',
                          )}
                          theme={IQTheme}
                          fontLabelWeight="600"
                          fullWidth
                          schema={TacticsFormDialogSchema}
                        />
                      </Grid>
                    </>
                    )}
                    {(tacticsPageForProductSelected.targetedDisplay || tacticsPageForProductSelected.pmm) && (
                    <Grid item xs={12} mt={2}
                      mb={2}
                    >
                      <Grid>
                        <Typography
                          variant="subtitle2" component="h3"
                          fontSize={16}
                        >
                          {t('pages.targetedDisplay.tacticModel.runningTypesLabel')}
                        </Typography>
                      </Grid>
                      {checkTacticType(tacticType) === 0 && (
                      <Grid container>
                        {adTypesRunningList.map((option) => (
                          <Grid item xs={12} sm={6}
                            key={option.value}
                          >
                            <FormControlLabel
                              control={(
                                <Controller
                                  name="adRunningTypes"
                                  control={control}
                                  {...register('adRunningTypes', {
                                    validate: (value) => schemaValidate(value, 'adRunningTypes', TacticsFormDialogSchema),
                                  })}
                                  render={({ field }) => (
                                    <Checkbox
                                      {...field}
                                      value={option.value}
                                      checked={field.value.includes(option.value)}
                                      onChange={() => {
                                        const newValue = field.value.includes(option.value)
                                          ? field.value.filter(e => e !== option.value)
                                          : [...field.value, option.value];
                                        field.onChange(newValue);
                                        selectedAdRunningTypes.includes(option.value);
                                      }}
                                    />
                                  )}
                                />
                                            )}
                              label={option.description}
                            />
                          </Grid>
                        ))}
                      </Grid>
                      )}
                      {checkTacticType(tacticType) === 1 && (
                      <Grid container>
                        {zTVAdTypesRunningList.map((option) => (
                          <Grid item xs={12} sm={6}
                            key={option.value}
                          >
                            <FormControlLabel
                              control={(
                                <Controller
                                  name="adRunningTypes"
                                  control={control}
                                  {...register('adRunningTypes', {
                                    validate: (value) => schemaValidate(value, 'adRunningTypes', TacticsFormDialogSchema),
                                  })}
                                  render={({ field }) => (
                                    <Checkbox
                                      {...field}
                                      value={option.value}
                                      checked={field.value.includes(option.value)}
                                      onChange={() => {
                                        const newValue = field.value.includes(option.value)
                                          ? field.value.filter(e => e !== option.value)
                                          : [...field.value, option.value];
                                        field.onChange(newValue);
                                      }}
                                    />
                                  )}
                                />
                                            )}
                              label={option.description}
                            />
                          </Grid>
                        ))}
                      </Grid>
                      )}
                      {checkTacticType(tacticType) === 2 && (
                      <Grid container>
                        {imageAdTypesRunningList.map((option) => (
                          <Grid item xs={12} sm={6}
                            key={option.value}
                          >
                            <FormControlLabel
                              control={(
                                <Controller
                                  name="adRunningTypes"
                                  control={control}
                                  {...register('adRunningTypes', {
                                    validate: (value) => schemaValidate(value, 'adRunningTypes', TacticsFormDialogSchema),
                                  })}
                                  render={({ field }) => (
                                    <Checkbox
                                      {...field}
                                      value={option.value}
                                      checked={field.value.includes(option.value)}
                                      onChange={() => {
                                        const newValue = field.value.includes(option.value)
                                          ? field.value.filter(e => e !== option.value)
                                          : [...field.value, option.value];
                                        field.onChange(newValue);
                                      }}
                                    />
                                  )}
                                />
                                            )}
                              label={option.description}
                            />
                          </Grid>
                        ))}
                      </Grid>
                      )}
                      {checkTacticType(tacticType) === 3 && (
                      <Grid container>
                        {imagePreRollAdTypesRunningList.map((option) => (
                          <Grid item xs={12} sm={6}
                            key={option.value}
                          >
                            <FormControlLabel
                              control={(
                                <Controller
                                  name="adRunningTypes"
                                  control={control}
                                  {...register('adRunningTypes', {
                                    validate: (value) => schemaValidate(value, 'adRunningTypes', TacticsFormDialogSchema),
                                  })}
                                  render={({ field }) => (
                                    <Checkbox
                                      {...field}
                                      value={option.value}
                                      checked={field.value.includes(option.value)}
                                      onChange={() => {
                                        const newValue = field.value.includes(option.value)
                                          ? field.value.filter(e => e !== option.value)
                                          : [...field.value, option.value];
                                        field.onChange(newValue);
                                      }}
                                    />
                                  )}
                                />
                                            )}
                              label={option.description}
                            />
                          </Grid>
                        ))}
                      </Grid>
                      )}
                      { tacticsPageForProductSelected.targetedDisplay && checkHuluAdSelected(watch('adRunningTypes')) && (
                      <Grid>
                        <Grid>
                          <Typography
                            variant="subtitle2" component="h3"
                            fontSize={16}
                          >
                            {t('pages.targetedDisplay.tacticModel.huluAdCreativeLengthLabel')}
                          </Typography>
                        </Grid>
                        <Grid container>
                          {huluAdLengthList.map((option) => (
                            <Grid item xs={12} sm={6}
                              key={option.value}
                            >
                              <FormControlLabel
                                control={(
                                  <Controller
                                    name="huluAdLength"
                                    control={control}
                                    {...register('huluAdLength', {
                                      validate: (value) => schemaValidate(value, 'huluAdLength', TacticsFormDialogSchema),
                                    })}
                                    render={({ field }) => (
                                      <Checkbox
                                        {...field}
                                        value={option.value}
                                        checked={field.value.includes(option.value)}
                                        onChange={() => {
                                          const newValue = field.value.includes(option.value)
                                            ? field.value.filter(e => e !== option.value)
                                            : [...field.value, option.value];
                                          field.onChange(newValue);
                                          handleHuluAdLength;
                                        }}
                                      />
                                    )}
                                  />
                                              )}
                                label={option.description}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                      )}
                    </Grid>
                    )}
                    <Grid item xs={12} sx={{ marginBottom: '8px' }}>
                      <Typography
                        variant="subtitle2"
                        component="h3"
                        fontSize={16}
                      >
                        {t(
                          'pages.xmo.targetedDisplay.tactics.sequentialRetargetingTacticsLabel',
                        )}
                      </Typography>
                      <RadioGroup
                        id="sequentialRetargetingTactics"
                        name="sequentialRetargetingTactics"
                        aria-labelledby="topic-location"
                        row
                        defaultValue={
                            selectedTactics.sequentialRetargetingTactics || ''
                        }
                      >
                        {yesNoList.map((option) => (
                          <FormControlLabel
                            key={option.value}
                            control={<Radio value={option.value} />}
                            label={option.description}
                            {...register('sequentialRetargetingTactics', {
                              validate: (value) =>
                                schemaValidate(
                                  value,
                                  'sequentialRetargetingTactics',
                                  TacticsFormDialogSchema,
                                ),
                            })}
                          />
                        ))}
                      </RadioGroup>
                    </Grid>
                  </>
                )}
                {tacticTypeSelected.gdn && (
                  <Grid item xs={12} sx={{ marginBottom: '8px' }}>
                    <Typography
                      variant="subtitle2"
                      component="h3"
                      fontSize={16}
                    >
                      {t(
                        'pages.xmo.targetedDisplay.tactics.gdn.targetingType',
                      )}
                    </Typography>
                    <RadioGroup
                      id="targetingType"
                      aria-labelledby="targeting-type"
                      row
                      defaultValue={selectedTactics.targetingType || ''}
                    >
                      {targetingTypeList.map((option) => (
                        <FormControlLabel
                          value={option.value}
                          key={option.value}
                          control={<Radio value={option.value} />}
                          label={option.description}
                          {...register('targetingType', {
                            required: true,
                            shouldUnregister: true,
                          })}
                        />
                      ))}
                    </RadioGroup>
                  </Grid>
                )}
                {tacticTypeSelected.gdn && targetingTypeSelected.city && (
                  <>
                    {(targetedCitiesFields || []).map((field, idx) => (
                      <>
                        <Grid
                          item
                          xs={idx === 0 ? 12 : 11}
                          sx={{ marginBottom: '8px' }}
                        >
                          <IQFormInput
                            key={field.id}
                            id={`targetedCities.${field.id}`}
                            name={`targetedCities.${field.id}`}
                            labelText={t(
                              'pages.xmo.targetedDisplay.tactics.gdn.targetCity',
                              {
                                position: hasMoreThanOneTargetedCities
                                  ? idx + 1
                                  : '',
                              },
                            )}
                            theme={IQTheme}
                            fontLabelWeight="600"
                            fullWidth
                            {...register(`targetedCities.${idx}.value`, {
                              required: true,
                            })}
                          />
                        </Grid>
                        {idx > 0 && (
                        <Grid item xs={1}>
                          <IconButton
                            onClick={() => targetedCitiesRemove(idx)}
                            style={{ marginTop: '40%', marginLeft: '15%' }}
                            color="primary"
                            aria-label="delete"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                        )}
                      </>
                    ))}
                    <Grid item xs={12} sx={{ marginBottom: '8px' }}>
                      <IQButtonLink
                        disabled={
                              getFieldState('targetedCities').invalid
                            }
                        startIcon={<AddIcon />}
                        onClick={() => targetedCitiesAppend('')}
                      >
                        {t(
                          'pages.xmo.targetedDisplay.tactics.gdn.addTargetCity',
                        )}
                      </IQButtonLink>
                    </Grid>
                  </>
                )}
                {tacticTypeSelected.gdn && targetingTypeSelected.dma && (
                  <Grid item xs={12} sx={{ marginBottom: '8px' }}>
                    <IQFormTextArea
                      id="dmaTargetedDetails"
                      labelText={t(
                        'pages.xmo.targetedDisplay.tactics.gdn.dmaTargetingDetails',
                      )}
                      name="dmaTargetedDetails"
                      rowCount={2}
                      fullWidth
                      fontLabelWeight="600"
                    />
                  </Grid>
                )}
                {tacticTypeSelected.gdn && (
                  <Grid item xs={12} mt={2}
                    sx={{ marginBottom: '8px' }}
                  >
                    <Typography
                      variant="subtitle2"
                      component="h3"
                      fontSize={16}
                    >
                      {t(
                        'pages.xmo.targetedDisplay.tactics.gdn.clientLikeToAddLookalikeAudienceExpansionToTheirCampaign',
                      )}
                      <Tooltip
                        title={t(
                          'pages.xmo.targetedDisplay.tactics.gdn.clientLikeToAddLookalikeAudienceExpansionToTheirCampaignInfo',
                        )}
                        placement="right-end"
                      >
                        <InfoOutlinedIcon
                          color="primary"
                          sx={{
                            marginLeft: 1,
                            fontSize: '15px',
                            marginTop: 0.6,
                          }}
                        />
                      </Tooltip>
                    </Typography>
                    <RadioGroup
                      id="clientLikeToAddLookalikeAudienceExpansionToTheirCampaign"
                      name="clientLikeToAddLookalikeAudienceExpansionToTheirCampaign"
                      aria-labelledby="topic-location"
                      row
                      defaultValue={
                              selectedTactics.clientLikeToAddLookalikeAudienceExpansionToTheirCampaign
                              || ''
                          }
                    >
                      {yesNoList.map((option) => (
                        <FormControlLabel
                          key={option.value}
                          control={<Radio value={option.value} />}
                          label={option.description}
                          {...register(
                            'clientLikeToAddLookalikeAudienceExpansionToTheirCampaign',
                            { required: false, shouldUnregister: true },
                          )}
                        />
                      ))}
                    </RadioGroup>
                  </Grid>
                )}
              </GridContainer>
              <DialogActions>
                <GridItem
                  sx={{
                    width: '20%',
                    display: 'flex',
                    justifyContent: 'right',
                  }}
                >
                  <Box sx={{ display: 'flex' }}>
                    <Grid item>
                      <CoButtonBack
                        label={t(
                          'pages.youtube.advertising.adGroupOptions.CloseBtn',
                        )}
                        onClick={handleClose}
                      />
                      {' '}
                    </Grid>
                    <Grid item ml={1}>
                      <CoButtonContinue
                        disabled={!isValid || ((tacticsPageForProductSelected.pmm || tacticsPageForProductSelected.targetedDisplay) && hasErrors) || (tacticsPageForProductSelected.targetedDisplay && (eventStartDateError || eventEndDateError || eventDateError))}
                        label={t(
                          'pages.xmo.targetedDisplay.tactics.saveTacticLabel',
                        )}
                        onClick={handleSubmit(handleFormSubmit)}
                      />
                    </Grid>
                  </Box>
                </GridItem>
              </DialogActions>
            </form>
            <CoNavigationConfirm
              showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
            />
          </FormProvider>
        </ValidationProvider>
      </DialogContent>
    </Dialog>
  );
}
