import {
  Alert,
  Button, Card, CardActions, CardMedia, Grid, Slide, Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CoLeftNav from 'components/leftNav/CoLeftNav';
import { dynamicTile, underlinedOnHover } from 'pages/themes/Themes';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { getBusinessFunctionalities, getMarketCategories } from 'services/adminSlice';
import {
  addItemToOrder, getOrderItems, requestNewOrder, selectOrdersContent,
  setOrderItemTypeOfSale, updateOrderItemsWithSalesforceId,
} from 'services/ordersSlice';
import {
  Theme,
  ThemeFilterType,
  getFilteredProducts, getMenuProducts, setFilter, setImageUrl,
} from 'services/themesSlice';
import { selectCurrentCountry } from 'services/topNavMenuSlice';
import { useTheme } from '@emotion/react';
import { Urls } from 'navigation/Urls';
import CoModalConfirm from 'components/modals/CoModalConfirm';
import {
  getCartItemList, selectCartItems, selecteCartAddons, updateCartAddons, updateCartAddonsSelection,
} from 'services/cartSlice';
import { useAppDispatch } from 'services/redux/store';
import { siteMap } from 'pages/themes/details/BundleConstants';
import { AddonsObjList, TypeOfSaleConstants } from 'pages/shopping-cart/Constants';
import { seoBlogProducts, seoProducts, smmProducts } from 'pages/themes/ThemeConstants';
import { putProduct } from 'services/productsSlice';
import ProductUnavailable from './ProductUnavailable';
import {
  goldBundles, mapAddons, mapAddonsToCart, websiteProducts,
} from '../OpportunityData';

interface Props {
  offer: any
  title: string
  products: string[]
  themes: Theme[]
  animationDir: 'left' | 'right'
  moveToNextTab: () => void
  isBlue?: boolean
}

export default function SolutionContent({
  offer, title, products, themes, animationDir, moveToNextTab, isBlue,
}: Props) {
  const theme = useTheme();
  const { t } = useTranslation();
  const { opportunityId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const selectedCountry = useSelector(selectCurrentCountry);
  const cartItems = useSelector(selectCartItems);
  const cartAddons = useSelector(selecteCartAddons);
  const currentOrder = useSelector(selectOrdersContent);
  const [showBanner, setShowBanner] = useState(offer?.incompleteOrderItems?.length > 0);
  const [isLeaving, setIsLeaving] = useState(false);
  const isOrange = opportunityId && !isBlue;

  const isWebsite = websiteProducts.includes(products?.[0]);

  useEffect(() => {
    dispatch(requestNewOrder({}));
    if (isWebsite) {
      dispatch(getBusinessFunctionalities({}));
      dispatch(getMarketCategories({}));
    }
    dispatch(setFilter({ type: ThemeFilterType.PRODUCT_TYPE, value: products }));
  }, []);

  useEffect(() => {
    dispatch(dispatch(getFilteredProducts({
      product: '',
      country: selectedCountry.name,
      businessFunctionalityId: '',
      marketCategoryId: '',
      exclude: 'add-ons',
      active: true,
    })));
    dispatch(getMenuProducts(selectedCountry.name));
  }, [selectedCountry]);

  const isHealthCare = offer?.neonProducts?.[0]?.neonProductName === 'PremiumListing'
                        && offer?.neonProducts?.[0]?.name?.toLowerCase()?.includes('healthcare');

  const checkBundle = (bundle) => (goldBundles[bundle] ? `&bundle=${goldBundles[bundle]}` : '');

  const checkAddon = (bundle, addonName) => (bundle === 'MasterAddon' ? `&addon=${addonName}` : '');

  const checkSMM = (offer, returnIdOnly = false) => {
    const { neonProductName, name: rlOfferName } = offer;
    if (neonProductName === 'SMMStandard') {
      if (rlOfferName.includes('10 Posts (No Rep. Mgt.)')) return returnIdOnly ? 1 : '&smmStandard=1';
      if (rlOfferName.includes('10 Posts With Rep. Mgt.')) return returnIdOnly ? 2 : '&smmStandard=2';
      if (rlOfferName.includes('20 Posts With Rep')) return returnIdOnly ? 3 : '&smmStandard=3';
    }
    return '';
  };

  const checkForPl = isHealthCare ? '&healthCare=true' : '';

  const checkForBlue = `isBlue=${isBlue}`;

  const goToTheme = (themeId, themeItem) => {
    const { bundle, neonProductName } = themeItem.offer;
    // eslint-disable-next-line max-len
    const filterParams = `id=${themeId}${checkBundle(bundle)}${checkAddon(bundle, neonProductName)}${checkForPl}${checkSMM(themeItem.offer)}`;
    // eslint-disable-next-line max-len
    navigate(`/${Urls.Cef}/${opportunityId}/offer/${offer?.forceOffer?.salesforceOfferId}/detail?${filterParams}&${checkForBlue}`);
  };

  const goToThemeDynamic = (product) => {
    // eslint-disable-next-line max-len
    navigate(`/${Urls.Cef}/${opportunityId}/offer/${offer?.forceOffer?.salesforceOfferId}/detail?product=${product}&${checkForBlue}`);
  };

  const isAddonSelected = (name, offer) => (mapAddons[offer?.neonProductName] === name
    ? true : (cartAddons[mapAddonsToCart[name]] || false));

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const addToCart = async (item) => {
    const content = {
      themeId: item.id || null,
      sourceId: null,
      productType: item.product,
      slug: null,
      tier: null,
      healthcare: isHealthCare,
      salesforceId: offer?.forceOffer?.salesforceOfferId,
      isBlue,
    };

    const mapCartItems = cartItems.content.map(item => item.productName);
    const isPremiumListing = item.product === 'premium listing';
    const isMasterAddon = item.product === 'master addons';
    const isSmmStandard = item.product === 'smm-standard';
    const redirectToCart = `${Urls.ShoppingCart}/${Urls.Cef}/${opportunityId}?${checkForBlue}`;

    if (['website - gold', 'website - standard'].includes(item.product)
      && (mapCartItems.includes('Website') || mapCartItems.includes('GoldSite'))) {
      navigate(redirectToCart, {
        state: { message: 'Oops! User can have a maximum of 1 Website order in the cart' },
      });
      return;
    }

    if (isPremiumListing && cartItems.content.filter(e => e.themeId === item.id).length > 0) {
      navigate(redirectToCart, {
        state: { message: 'Oops! User can have a maximum of 1 Premium Listing order in the cart' },
      });
      return;
    }

    if (isPremiumListing && mapCartItems.includes(siteMap[item.product])) {
      navigate(redirectToCart, {
        state: { message: 'Oops! User can have a maximum of 1 Premium Listing order in the cart' },
      });
      return;
    }

    if (item.product === 'chat' && mapCartItems.includes(siteMap[item.product])) {
      navigate(redirectToCart, {
        state: { message: 'Oops! User can have a maximum of 1 Smart Chatbot Silver order in the cart' },
      });
      return;
    }

    if (item.product === 'chat - platinum' && mapCartItems.includes(siteMap[item.product])) {
      navigate(redirectToCart, {
        state: { message: 'Oops! User can have a maximum of 1 Chat Platinum order in the cart' },
      });
      return;
    }

    if (seoProducts.includes(item.product)
      && (mapCartItems.includes('SEOStandard') || mapCartItems.includes('SEOCustom'))) {
      navigate(redirectToCart, {
        state: { message: 'Oops! User can have a maximum of 1 SEO order in the cart' },
      });
      return;
    }

    if (seoBlogProducts.includes(item.product)
      && (mapCartItems.includes('SEOBlogStandard') || mapCartItems.includes('SEOBlogEnhanced'))) {
      navigate(redirectToCart, {
        state: { message: 'Oops! User can have a maximum of 1 Blog Content order in the cart' },
      });
      return;
    }

    if (smmProducts.includes(item.product)
      && (mapCartItems.includes('SMMStandard') || mapCartItems.includes('SMMCustom'))) {
      navigate(redirectToCart, {
        state: { message: 'Oops! User can have a maximum of 1 SMM order in the cart' },
      });
      return;
    }

    if (!isMasterAddon) {
      await appDispatch(dispatch(addItemToOrder({
        orderId: currentOrder.id,
        content,
      }))).then(async (response: any) => {
        await dispatch(setOrderItemTypeOfSale({
          orderItemId: response.payload.id,
          content: TypeOfSaleConstants.NEW_SALE,
        }));
        if (isSmmStandard) {
          await dispatch(putProduct({
            orderItemId: response.payload.id,
            content: { variant: checkSMM(item.offer, true) },
          }));
        }
        dispatch(getCartItemList(currentOrder.id));
      });
    }

    if (isMasterAddon) {
      const content = {
        themeId: item.id,
        sourceId: null,
        productType: item.product,
        slug: null,
        tier: null,
        healthcare: isHealthCare,
        salesforceId: null,
      };
      if (!mapCartItems.includes(siteMap[item.product])) {
        await appDispatch(dispatch(addItemToOrder({
          orderId: currentOrder.id,
          content,
        }))).then(async () => dispatch(getCartItemList(currentOrder.id)));
      }

      await appDispatch(dispatch(getOrderItems(currentOrder.id))).then(async (response) => {
        const orderItemId = response.payload.filter(item => item.offeringType === 'MasterAddon')[0].id;
        const resource = {
          emailHosting: isAddonSelected('Email Hosting', item.offer),
          expandableMenus: isAddonSelected('Expandable Menus', item.offer),
          hipaaForms: isAddonSelected('HIPAA Forms', item.offer),
          onlineBooking: isAddonSelected('Online Booking', item.offer),
          paymentForms: isAddonSelected('Payment Forms', item.offer),
          popups: isAddonSelected('Pop-up', item.offer),
          videoBackground: isAddonSelected('Video Background', item.offer),
        };

        await dispatch(updateCartAddonsSelection({ orderItemId, resource }));
        if (isMasterAddon && mapCartItems.includes(siteMap[item.product]) && opportunityId) {
          await dispatch(updateCartAddons(resource as any));
        }
      });
    }

    if (isMasterAddon) {
      appDispatch(dispatch(getOrderItems(currentOrder.id))).then(async (response) => {
        const addonItem = response.payload.filter((item: any) => offer?.neonProducts.some((prod: any) => prod?.neonProductName === item.offeringType));
        const addonOrderItemId = addonItem.at(0)?.id || null;
        const content = {
          itemSalesforceOffers: [
            {
              itemId: addonOrderItemId,
              salesforceOfferId: offer?.forceOffer?.salesforceOfferId,
            },
          ],
        };
        dispatch(updateOrderItemsWithSalesforceId(content));
      });
    } else {
      dispatch(getOrderItems(currentOrder.id));
    }

    moveToNextTab();
  };

  const redirectToMyOrders = () => {
    setIsLeaving(false);
    setShowBanner(false);
    navigate(`/${Urls.Orders}`);
  };

  const renderAlert = () => (
    <Box mb={3}>
      <Alert variant="outlined" severity="success" icon={<InfoOutlinedIcon />}
        sx={{
          backgroundColor: 'common.white',
          border: '1px solid common.black',
          borderColor: 'common.black',
          color: 'common.black',
          borderRadius: '5px',
          borderLeft: `12px solid ${theme.palette.secondary.main}`,
          '& .MuiAlert-icon': {
            color: theme.palette.secondary.main,
            display: 'flex',
            alignItems: 'center',
          },
          '& .MuiAlert-message': {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          },
        }}
      >
        <Typography fontWeight="bold" sx={{ fontSize: '16px' }} marginRight={2}>
          {t('pages.opportunity.orderCreatedBanner.title')}
        </Typography>
        <Typography sx={{ fontSize: '14px' }} marginRight={1}>
          {t('pages.opportunity.orderCreatedBanner.subtitle')}
        </Typography>
        <Button onClick={() => setIsLeaving(true)}
          disableElevation
          disableRipple
          sx={{ textDecoration: 'underline', backgroundColor: 'transparent', color: theme.palette.text.primary }}
        >
          My Orders
        </Button>
      </Alert>
    </Box>
  );

  const themeIsSelected = (theme) => (offer?.neonProduct?.bundle === 'MasterAddon'
    ? cartAddons[mapAddonsToCart[mapAddons[offer?.neonProduct?.neonProductName]]]
    : cartItems.content.find(el => Number(el.themeId) === Number(theme.id)));

  const getImageUrl = (item) => {
    if (item?.product === 'master addons') {
      const addon = AddonsObjList.filter(e => e.productName === offer?.neonProduct?.neonProductName);
      if (addon.length > 0) return `/assets/addons/${addon[0].thumbnailUrl}`;
    }
    return setImageUrl(item.id, item.screenshotImage, 'screenshot_image');
  };

  /* Skip add to cart button for SMM Standard */
  const isAddToCartRequired = (themeItem) => {
    if (themeItem.product === 'smm-standard') {
      return false;
    }
    return true;
  };

  const renderThemes = () => {
    const filteredThemes = [];
    products.forEach((product, index) => {
      const themesByProduct = themes?.filter(theme => theme?.product === product);
      if (themesByProduct.length > 0) {
        themesByProduct.forEach(theme => filteredThemes.push({ ...theme, offer: offer.neonProducts[index] }));
      }
    });
    const hasThemes = filteredThemes?.length > 0;

    if (hasThemes) {
      return filteredThemes.map(themeItem => (
        <Grid key={themeItem.id} item
          marginBottom={3}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            '&:hover': {
              '.MuiCardMedia-root': {
                opacity: 0.5,
              },
              '.MuiButton-root': {
                display: 'flex',
              },
            },
          }}
        >
          <Card
            sx={{
              position: 'relative',
              width: '250px',
              marginBottom: 3,
              borderRadius: '8px',
              border: `${!themeIsSelected(themeItem) ? 'none' : `6px solid ${theme.palette.primary.main}`}`,
            }}
          >
            <CardMedia
              image={getImageUrl(themeItem)}
              sx={{ height: '250px', display: 'flex' }}
            />
            {themeIsSelected(themeItem) && (
            <Box sx={{
              background: theme.palette.primary.main,
              width: '40px',
              height: '40px',
              borderRadius: '0 0 0 8px',
              position: 'absolute',
              top: '0',
              right: '0',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: theme.palette.common.white,
            }}
            >
              <ShoppingCartIcon sx={{ marginLeft: '5px' }} />
            </Box>
            )}
            <CardActions
              sx={{
                position: 'absolute',
                top: '80px',
                left: 0,
                width: '100%',
                paddingLeft: 0,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              { isAddToCartRequired(themeItem)
              && (
              <Button
                variant="contained"
                sx={{
                  margin: 'auto',
                  marginBottom: '15px',
                  display: 'none',
                  width: '153px',
                }}
                onClick={() => addToCart(themeItem)}
              >
                Add to Cart
              </Button>
              )}
              <Button
                variant="outlined"
                sx={{
                  margin: 'auto',
                  marginLeft: 'auto !important',
                  display: 'none',
                  backgroundColor: '#ffffff !important',
                  width: '153px',
                }}
                onClick={() => goToTheme(themeItem.id, themeItem)}
              >
                See Details Page
              </Button>
            </CardActions>
          </Card>
          <Typography onClick={() => goToTheme(themeItem.id, themeItem)}
            sx={{
              color: '#000000',
              fontWeight: 'bold',
              fontSize: '20px',
              display: 'block',
              width: '250px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              ...underlinedOnHover,
            }}
          >
            {(opportunityId && themeItem.product === 'master addons')
              ? mapAddons[offer?.neonProduct?.neonProductName] : themeItem.title}
          </Typography>
        </Grid>
      ));
    }

    const isXmo = (product) => ['xmo', 'xmo-upgrade'].includes(product);

    return (
      <Grid item
        marginBottom={3}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          '&.MuiGrid-root.MuiGrid-item': {
            padding: '0 !important',
          },
          '&:hover': {
            '.MuiCardMedia-root': {
              opacity: 0.5,
            },
            '.MuiButton-root': {
              display: 'flex',
            },
          },
        }}
      >
        {products.map(product => dynamicTile(product, goToThemeDynamic, !isXmo, () => addToCart({ product })))}
      </Grid>
    );
  };

  return (
    <Slide
      direction={animationDir}
      in
      mountOnEnter
      unmountOnExit
      timeout={500}
    >
      <Box
        display="flex"
        flexDirection="column"
        paddingTop={5}
        paddingBottom={5}
      >
        {showBanner && renderAlert()}
        <Box>
          <Typography variant="subtitle2" mb={1} style={{ textTransform: 'uppercase', fontSize: '12px' }}>
            {/* eslint-disable-next-line max-len */}
            {isBlue && `${t('pages.opportunity.campaignLabel')} - ${offer?.forceOffer?.rlOrderHubId} - ${offer?.forceOffer?.adPointCustomStatus}`}
            {isOrange && `${offer?.forceOffer?.salesforceOfferName}`}
          </Typography>
          <Typography variant="h3" mb={3}>
            {title}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          {isWebsite && <CoLeftNav isSalesforce />}
          <Grid container spacing={2} sx={{
            marginTop: '0',
            marginLeft: `${isWebsite ? '50px' : '0'}`,
            alignContent: 'baseline',
            columnGap: '30px',
          }}
          >
            {products.length > 0
              ? renderThemes()
              : <ProductUnavailable />}
          </Grid>
        </Box>
        <CoModalConfirm open={isLeaving}
          title={t('pages.opportunity.leaveModal.title')}
          description={(
            <Box>
              <Typography mb={2}>
                {t('pages.opportunity.leaveModal.description')}
              </Typography>
              <Typography>
                <b>Note: </b>
                {t('pages.opportunity.leaveModal.note')}
              </Typography>
            </Box>
          )}
          confirmText={t('pages.opportunity.leaveModal.confirmText')}
          cancelText={t('pages.opportunity.leaveModal.cancelText')}
          handleClose={() => setIsLeaving(false)}
          handleConfirm={redirectToMyOrders}
        />
      </Box>
    </Slide>
  );
}
