/* eslint-disable import/no-duplicates */
/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { StatusType } from 'models/StatusType';
import UserType from 'models/UserType';
import { createAction } from '@reduxjs/toolkit';
import AxiosAPI from './common/axios';
import { createAsyncThunkCatchErrors } from './errorSlice';
import { RootState } from './redux/store';

export interface UserAccess {
  isAdmin: boolean,
  isManager: boolean,
  canDoFulfillment: boolean,
  canDoCarbon: boolean,
  isSales: boolean,
  isGSCIndexer: boolean,
  isPartner: boolean,
  isSendgridChecker: boolean,
}

export interface User {
  id: number,
  email: string,
  userRoles: string,
}

export interface UserRole {
  userRoles: string,
}

export interface UserState {
  userData: StatusType<UserType>,
  access: UserAccess,
}

const initialState: UserState = {
  userData: {
    status: 'loading',
    message: '',
    content: {
      cobaltRole: 'sales',
      email: '',
      id: '',
      firstName: '',
      lastName: '',
      userRoles: '',
    },
  },
  access: {
    isAdmin: false,
    isManager: false,
    canDoFulfillment: false,
    canDoCarbon: false,
    isSales: true,
    isGSCIndexer: false,
    isPartner: false,
    isSendgridChecker: false,
  },
};

const roleToAccessProperty = {
          manager: 'isManager',
          fulfillment: 'canDoFulfillment',
          carbon: 'canDoCarbon',
          sales: 'isSales',
          partner: 'isPartner',
          gscIndexing: 'isGSCIndexer',
          sendgridChecker: 'isSendgridChecker',
        };

export const getUsers = createAsyncThunkCatchErrors(
  'userData/get',
  async (num: string) => {
    const response = await AxiosAPI.get(`/users?size=${num}`);
    return response.data;
  },
);
export const getChickletUsers = createAsyncThunkCatchErrors(
  'userData/get',
  async (num: string) => {
    const response = await AxiosAPI.get(`/users/chicklet-users?size=${num}`);
    return response.data;
  },
);

export const getUserInfo = createAsyncThunkCatchErrors(
  'userInfo/get',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async (user: string) => {
    const response = await AxiosAPI.get(`/users/${user}`);
    return response.data;
  },
);

export const updateUserRoles = createAsyncThunkCatchErrors(
  'userInfo/put',
  async (data) => {
    const response = await AxiosAPI.put(`/users/${data.id}/update-roles?userRoles=${data.roleQuery}`);
    return response;
  },
);

export const setUserRole = createAction<string>('user/setUserRole');

export const selectUserRoles = (state: RootState) => state.user.userData.content.userRoles;

export const getUserRoles = () => {
  const roles = useSelector(selectUserRoles);
  return roles;
};

const userSlice = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(getUserInfo.pending, (state) => {
        state.userData.status = 'loading';
      })
      .addCase(getUserInfo.fulfilled, (state, action) => {
        state.userData.status = 'idle';
        state.userData.content = action.payload;
        const { cobaltRole, userRolesRecord } = state.userData.content;
        const role = cobaltRole;
        let allRoles = userRolesRecord ? userRolesRecord.split(',') : [];
        allRoles = allRoles.map(item => item.trim());
        allRoles.push(role);
        if (allRoles.includes('ROLE_SALES')
          || allRoles.includes('partner')
          || allRoles.includes('sales')
          || allRoles.includes('ROLE_PARTNER_CEF')) {
          state.access.isSales = true;
        }
        if (allRoles.includes('ROLE_MANAGER')) {
          state.access.isManager = true;
        }
        if (allRoles.includes('ROLE_FULFILLMENT')) {
          state.access.canDoFulfillment = true;
        }
        if (allRoles.includes('ROLE_GSC_INDEXING')) {
          state.access.isGSCIndexer = true;
        }
        if (allRoles.includes('ROLE_CARBON')) {
          state.access.canDoCarbon = true;
        }
        if (allRoles.includes('ROLE_SENDGRID_CHECKER')) {
          state.access.isSendgridChecker = true;
        }
        if (allRoles.includes('sendgrid_checker')) {
          state.access.isSendgridChecker = true;
        }
        if (allRoles.includes('ROLE_ADMIN') || allRoles.includes('admin')) {
          state.access.isAdmin = true;
        }
        if (state.access.isAdmin) {
          state.access.canDoCarbon = true;
          state.access.canDoFulfillment = true;
          state.access.isManager = true;
          state.access.isSendgridChecker = true;
          state.access.isGSCIndexer = true;
        }
      })
      .addCase(setUserRole, (state, action) => {
        const selectedRole: string = action.payload;
        state.userData.content.cobaltRole = selectedRole;
        state.access.isAdmin = false;
        state.access.isManager = false;
        state.access.canDoFulfillment = false;
        state.access.canDoCarbon = false;
        state.access.isSales = false;
        state.access.isGSCIndexer = false;

        const key = roleToAccessProperty[selectedRole];
        if (key) {
          state.access[key] = true;
        }

        if (selectedRole === 'admin') {
          state.access.isAdmin = true;
          state.access.canDoCarbon = true;
          state.access.canDoFulfillment = true;
          state.access.isManager = true;
          state.access.isSendgridChecker = true;
          state.access.isGSCIndexer = true;
        }
      });
  },
);

export const selectUserRole = (state: RootState) => state.user.userData.content?.cobaltRole;
export const selectUserEmail = (state: RootState) => state.user.userData.content?.email;
export const selectUserDataStatus = (state: RootState) => state.user.userData.status;
export const selectUserId = (state: RootState) => state.user.userData.content?.userId;
export const selectUserFullName = (state: RootState) => state.user.userData.content?.fullName;
export const selectUserAccess = (state: RootState) => state.user.access;

export const selectAllUsers = (state: RootState) => state.user.userData.content;

export default userSlice;
