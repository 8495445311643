/* eslint-disable max-len */
import {
  Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, Grid, IconButton, Typography,
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import React, {
  SetStateAction, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import {
  selectOrderFlow, selectOrderItems, selectOrdersContent, submitOrder,
} from 'services/ordersSlice';
import { getOrderBusinessByOrderId } from 'services/businessSlice';
import { setSpecialHours } from 'services/businessLocationSlice';
import { S1 } from 'components/labels/S1';
import { useTheme } from '@emotion/react';
import { IQLoadingSpinner } from '@gannettdigital/shared-react-components';
import { getOrderItemsWithCampaignId } from 'services/campaignSlice';
import CustomerCenterSection from 'pages/overview/sections/customerCenter/CustomerCenterSection';
import CustomerCenterByDashSection from 'pages/overview/sections/customerCenter/CustomerCenterByDashSection';
import { Urls } from 'navigation/Urls';
import { OrderFlow } from 'shared/constants';
import XmoSection from 'pages/overview/sections/xmo/XmoSection';
import { TypeOfSaleConstants } from 'pages/shopping-cart/Constants';
import SemSection from 'pages/overview/sections/Sem/SemSection';
import BusinessSection from './sections/Business/BusinessSection';
import SalesAgentSection from './sections/salesAgent/SalesAgentSection';
import SitesSection from './sections/sites/SitesSection';
import ChatSection from './sections/chat/ChatSection';
import CampaignLandingSection from './sections/landing/CampaignLandingSection';
import CustomLandingSection from './sections/landing/CustomLandingSection';
import CustomWebsiteSection from './sections/sites/CustomWebsiteSection';
import MasterAddonSection from './sections/masterAddon/MasterAddonSection';
import PremiumListingsReview from './sections/premiumListings/PremiumListingsReview';
import PremiumListingSection from './sections/premiumListings/PremiumListingsSection';
import SeoSection from './sections/seo/SeoSection';
import { addons, mapTitle } from './OverviewConstants';
import SeoBlogSection from './sections/seo/SeoBlogSection';
import GenericProductSection from './sections/genericProduct/GenericProductSection';
import SmmSection from './sections/smm/SmmSection';
import PiqLandingPagesSection from './sections/piqLandingPages/PiqLandingPagesSection';

export const accordionStyles = {
  '&.MuiAccordion-root': {
    background: 'transparent',
    boxShadow: 'none',
    borderBottom: '1px solid #716F6F',
  },
  '& .MuiAccordionSummary-root': {
    padding: '10px 0',
  },
  '& .MuiAccordionDetails-root': {
    padding: '8px 0',
  },
  '& .MuiAccordionDetails-root > .MuiGrid-container > .MuiGrid-container > .MuiGrid-container': {
    width: '100%',
    marginLeft: '0',
  },
  '& .MuiBox-root > p.MuiTypography-body1': {
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
  '& .MuiIconButton-root': {
    paddingLeft: '0',
  },
};

// Function to transform camelCase to Capitalized with spaces
const formatKeyToCapitalizedWithSpaces = (key: string) => key
  .replace(/([A-Z])/g, ' $1')
  .replace(/^./, (str) => str.toUpperCase());

export const displayNotRequiredField = (title: string, data: any) => {
  if (!data || data === ' ' || data === 'NaN-NaN-NaN') {
    return <Typography fontWeight="bold">{`No ${title}`}</Typography>;
  }

  if (Array.isArray(data)) {
    if (typeof data[0] === 'object' && Object.keys(data[0]).length > 0) {
      return (
        <div>
          {data.map((eachData, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Grid key={index} sx={{
              borderBottom: '1px solid',
            }}
            >
              {Object.entries(eachData).map(([key, value]) => (
                <Typography key={key}>
                  <span
                    style={{ fontWeight: 'bold' }}
                  >
                    {formatKeyToCapitalizedWithSpaces(key)}
                  </span>
                  :
                  {value}
                </Typography>
              ))}
            </Grid>
          ))}
        </div>
      );
    }
  }
  return <Typography>{data}</Typography>;
};

export const editButton = (isEditable, handleClick) => isEditable && (
<IconButton onClick={handleClick}>
  <EditOutlinedIcon />
</IconButton>
);

export const getSectionTitle = (section) => {
  if (!section?.healthcare) {
    return mapTitle[section.title] || section.title;
  }
  return 'US Premium Listings (1-9) w/Yelp + Healthcare';
};

// @ts-ignore
export const renderSectionContent = (
  section,
  product,
  isPlProduct,
  isHCProduct,
  isEditable,
  showTracker,
  review,
  openBusinessAndSalesAgent = () => {
  },
  setShowCIDModal?: React.Dispatch<SetStateAction<boolean>>,
) => {
  const props = {
    product, isEditable, showTracker, openBusinessAndSalesAgent, setShowCIDModal,
  };
  switch (section) {
    case 'Business Section':
      return (
        <BusinessSection
          isPlProduct={isPlProduct}
          isHCProduct={isHCProduct}
          isEditable={isEditable}
          review={review}
        />
      );
    case 'Website':
    case 'GoldSite':
    case 'E-Commerce Microsite':
      return <SitesSection {...props} />;
    case 'CustomWebsite':
      return <CustomWebsiteSection {...props} />;
    case 'Campaign Landing Pages':
      return <CampaignLandingSection {...props} />;
    case 'CustomLandingPage':
      return <CustomLandingSection {...props} />;
    case 'Chat':
    case 'Chat Platinum':
      return <ChatSection {...props} />;
    case 'SEO Standard':
    case 'SEO Custom':
      return <SeoSection {...props} />;
    case 'SMM Standard':
    case 'SMM Custom':
      return <SmmSection {...props} />;
    case 'SEO Blog Standard':
    case 'SEO Blog Enhanced':
      return <SeoBlogSection {...props} />;
    case 'Customer Center':
      return <CustomerCenterSection {...props} />;
    case 'Customer Center By Dash':
      return <CustomerCenterByDashSection {...props} />;
    case 'MasterAddon':
      return <MasterAddonSection {...props} />;
    case 'PremiumListing':
      return review ? <PremiumListingsReview /> : <PremiumListingSection {...props} />;
    case 'PiQ Landing Pages':
      return <PiqLandingPagesSection {...props} />;
    case 'Sales Agent':
      return <SalesAgentSection isEditable={isEditable} />;
    case 'Search Engine Marketing':
      return <SemSection {...props} />;
    case 'XMO':
      return <XmoSection {...props} />;
    case 'XMO Upgrade':
      return <XmoSection {...props} />;
    case 'Promotions Media Mix':
      return <XmoSection {...props} />;
    case 'YouTube':
    default:
      return <GenericProductSection {...props} />;
  }
};

export default function Overview() {
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigationHandler();
  const navigateTo = useNavigate();
  const orderItems = useSelector(selectOrderItems);
  const [sections, setSections] = useState([]);
  const [expandAll, setExpandAll] = useState(false);
  const [showBanner, setShowBanner] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const history: any = useLocation();
  const theme = useTheme();
  const orderFlow = useSelector(selectOrderFlow);
  const orderData = useSelector(selectOrdersContent);

  const isPunchlist = (orderFlow === OrderFlow.PUNCHLIST || orderData.orderFlow === OrderFlow.PUNCHLIST);

  useMemo(() => {
    if (!history.state?.previousPath.includes('salesReps') && (history.state?.banner === true)) {
      setShowBanner(true);
    }

    if (history.state?.previousPath.includes('salesReps')
        && history.state?.previousPath.includes('edit')
        && (history.state?.banner === true)) {
      setShowBanner(true);
    }
  }, [history]);

  useEffect(() => {
    dispatch(getOrderBusinessByOrderId(orderId));
    dispatch(getOrderItemsWithCampaignId(orderId));
  }, [orderId]);

  const isPlProduct = orderItems?.content?.filter(item => (
    item.offeringType === 'PremiumListing' && !item.healthcare))?.length > 0;

  const isHCProduct = orderItems?.content?.filter(item => (
    item.offeringType === 'PremiumListing' && item.healthcare))?.length > 0;

  useEffect(() => {
    if (orderItems.content.length > 0) {
      const products = orderItems.content.filter(item => !addons.includes(item.offeringType))
        .map(item => ({
          title: item.offeringType === 'ProductsData' ? item.pdName : item.offeringType,
          isOpen: false,
          isRebuild: item.saleType === TypeOfSaleConstants.REBUILD,
          ...item,
        }));
      setSections([
        {
          title: t('pages.overview.salesAgent.title'),
          isOpen: false,
        },
        {
          title: t('pages.overview.business.title'),
          isOpen: false,
        },
        ...products,
      ]);
    }
  }, [orderItems]);

  useEffect(() => {
    setExpandAll(sections?.every(section => section.isOpen));
  }, [sections]);

  const handleChange = (index) => {
    const copy = [...sections];
    copy[index].isOpen = !sections[index].isOpen;
    setSections(copy);
  };

  const toggleAllSections = () => {
    setExpandAll(!expandAll);
    const copy = [...sections].map(section => ({ ...section, isOpen: !expandAll }));
    setSections(copy);
  };

  // eslint-disable-next-line no-promise-executor-return
  const sleep = time => new Promise(r => setTimeout(r, time));

  const onBack = async () => {
    await dispatch(setSpecialHours([]));
    navigate.to(Urls.ProductMoveBackward);
  };

  const onContinue = async () => {
    setIsLoading(true);
    if (orderData?.status === 'DRAFT') {
      await dispatch(submitOrder(orderId));
    }
    if (isPunchlist) {
      return navigateTo(`/punchlist/${orderId}/confirmation`);
    }
    return navigate.to('orderSummary');
  };

  const successIcon = (
    <CheckCircleOutlineIcon
      sx={{ fill: theme.palette.success.dark, fontSize: 32 }}
    />
  );
  const dismissBanner = () => setShowBanner(false);

  return (
    <>
      {showBanner
            && (
            <Box sx={{ marginTop: '100px' }}>
              <Alert variant="outlined" severity="success" icon={successIcon}
                sx={{
                  backgroundColor: 'common.white',
                  border: '1px solid common.black',
                  borderColor: 'common.black',
                  color: 'common.black',
                  borderRadius: '5px',
                  borderLeft: `12px solid ${theme.palette.success.dark}`,
                }}
              >
                <S1 sx={{
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '24px',
                  color: 'theme.palette.main.dark',
                }}
                >
                  <strong>Your changes have been successfully made.</strong>
                </S1>
                <S1 sx={{ fontSize: '14px', fontWeight: 400, lineHeight: '21px' }}>
                  Please review the order overview as changes may impact previously entered data
                  before submitting the order.
                </S1>
                <Button onClick={dismissBanner}
                  disableElevation
                  disableRipple
                  sx={{ textDecoration: 'underline', backgroundColor: 'transparent' }}
                >
                  Dismiss
                </Button>
              </Alert>
            </Box>
            )}

      <DefaultPageLayout
        onContinue={onContinue}
        header={t('pages.overview.title')}
        disableContinue={isLoading}
        continueButtonLabel="Submit Order"
        onBack={onBack}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Button onClick={toggleAllSections}
            sx={{ alignSelf: 'flex-end', textDecoration: 'underline' }}
          >
            {expandAll ? ' Collapse All' : 'Expand All'}
          </Button>
          {sections?.map((section, index) => (
            <Accordion
              key={section.title}
              expanded={sections[index]?.isOpen}
              onChange={() => handleChange(index)}
              disableGutters
              sx={accordionStyles}
            >
              <AccordionSummary expandIcon={<ExpandCircleDownOutlinedIcon color="primary" />}>
                <Typography variant="h5">
                  {getSectionTitle(section)}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {sections[index]?.isOpen
                        && renderSectionContent(section.title, section, isPlProduct, isHCProduct, true, false, false, null)}
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
        {isLoading && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={6}
          >
            <IQLoadingSpinner size={60} />
          </Box>
        )}
      </DefaultPageLayout>
    </>
  );
}
