import i18n from 'i18n/i18n';
import { TacticTypeSelected } from '../TargetedDisplay.interface';

const { t } = i18n;

export const targetedDisplayTacticTypeList = [
  {
    value: t('pages.targetedDisplay.tacticModel.tacticsTypes.geoTargetOnly'),
    description: t('pages.targetedDisplay.tacticModel.tacticsTypes.geoTargetOnly'),
  },
  {
    value: t('pages.targetedDisplay.tacticModel.tacticsTypes.standardGeoFence'),
    description: t('pages.targetedDisplay.tacticModel.tacticsTypes.standardGeoFence'),
  },
  {
    value: t('pages.targetedDisplay.tacticModel.tacticsTypes.addressableGeoFenceClientProvided'),
    description: t('pages.targetedDisplay.tacticModel.tacticsTypes.addressableGeoFenceClientProvided'),
  },
  {
    value: t('pages.targetedDisplay.tacticModel.tacticsTypes.addressableGeoFenceCuratedAudience'),
    description: t('pages.targetedDisplay.tacticModel.tacticsTypes.addressableGeoFenceCuratedAudience'),
  },
  {
    value: t('pages.targetedDisplay.tacticModel.tacticsTypes.eventAudienceRetargeting'),
    description: t('pages.targetedDisplay.tacticModel.tacticsTypes.eventAudienceRetargeting'),
  },
  {
    value: t('pages.targetedDisplay.tacticModel.tacticsTypes.ztv'),
    description: t('pages.targetedDisplay.tacticModel.tacticsTypes.ztv'),
  },
  {
    value: t('pages.targetedDisplay.tacticModel.tacticsTypes.siteRetargeting'),
    description: t('pages.targetedDisplay.tacticModel.tacticsTypes.siteRetargeting'),
  },
  {
    value: t('pages.targetedDisplay.tacticModel.tacticsTypes.dynamicSiteRetargeting'),
    description: t('pages.targetedDisplay.tacticModel.tacticsTypes.dynamicSiteRetargeting'),
  },
  {
    value: t('pages.targetedDisplay.tacticModel.tacticsTypes.searchRetargeting'),
    description: t('pages.targetedDisplay.tacticModel.tacticsTypes.searchRetargeting'),
  },
  {
    value: t('pages.targetedDisplay.tacticModel.tacticsTypes.dynamicSearchProspecting'),
    description: t('pages.targetedDisplay.tacticModel.tacticsTypes.dynamicSearchProspecting'),
  },
  {
    value: t('pages.targetedDisplay.tacticModel.tacticsTypes.keywordContextual'),
    description: t('pages.targetedDisplay.tacticModel.tacticsTypes.keywordContextual'),
  },
  {
    value: t('pages.targetedDisplay.tacticModel.tacticsTypes.categoryContextual'),
    description: t('pages.targetedDisplay.tacticModel.tacticsTypes.categoryContextual'),
  },
  {
    value: t('pages.targetedDisplay.tacticModel.tacticsTypes.crmRetargeting'),
    description: t('pages.targetedDisplay.tacticModel.tacticsTypes.crmRetargeting'),
  },
  {
    value: t('pages.targetedDisplay.tacticModel.tacticsTypes.lookalikes'),
    description: t('pages.targetedDisplay.tacticModel.tacticsTypes.lookalikes'),
  },
];

export const XmoTacticTypeList = [
  {
    value: t('pages.xmo.targetedDisplay.tactics.tacticsTypes.addressableGeoFenceClientProvided'),
    description: t('pages.xmo.targetedDisplay.tactics.tacticsTypes.addressableGeoFenceClientProvided'),
  },
  {
    value: t('pages.xmo.targetedDisplay.tactics.tacticsTypes.addressableGeoFenceCuratedAudience'),
    description: t('pages.xmo.targetedDisplay.tactics.tacticsTypes.addressableGeoFenceCuratedAudience'),
  },
  {
    value: t('pages.xmo.targetedDisplay.tactics.tacticsTypes.crmRetargeting'),
    description: t('pages.xmo.targetedDisplay.tactics.tacticsTypes.crmRetargeting'),
  },
  {
    value: t('pages.xmo.targetedDisplay.tactics.tacticsTypes.googleDisplayNetwork'),
    description: t('pages.xmo.targetedDisplay.tactics.tacticsTypes.googleDisplayNetwork'),
  },
];

export const pmmTacticTypeList = [
  {
    value: t('pages.targetedDisplay.tacticModel.tacticsTypes.siteRetargeting'),
    description: t('pages.targetedDisplay.tacticModel.tacticsTypes.siteRetargeting'),
  },
  {
    value: t('pages.targetedDisplay.tacticModel.tacticsTypes.searchRetargeting'),
    description: t('pages.targetedDisplay.tacticModel.tacticsTypes.searchRetargeting'),
  },
  {
    value: t('pages.targetedDisplay.tacticModel.tacticsTypes.keywordContextual'),
    description: t('pages.targetedDisplay.tacticModel.tacticsTypes.keywordContextual'),
  },
  {
    value: t('pages.targetedDisplay.tacticModel.tacticsTypes.addressableGeoFenceCuratedAudience'),
    description: t('pages.targetedDisplay.tacticModel.tacticsTypes.addressableGeoFenceCuratedAudience'),
  },
];

export const geofenceSegmentLengthList = [
  {
    value: t('pages.xmo.targetedDisplay.tactics.geoFenceSegmentLengths.oneMonth'),
    description: t('pages.xmo.targetedDisplay.tactics.geoFenceSegmentLengths.oneMonth'),
  }, {
    value: t('pages.xmo.targetedDisplay.tactics.geoFenceSegmentLengths.twoWeeks'),
    description: t('pages.xmo.targetedDisplay.tactics.geoFenceSegmentLengths.twoWeeks'),
  }, {
    value: t('pages.xmo.targetedDisplay.tactics.geoFenceSegmentLengths.oneWeek'),
    description: t('pages.xmo.targetedDisplay.tactics.geoFenceSegmentLengths.oneWeek'),
  }, {
    value: t('pages.xmo.targetedDisplay.tactics.geoFenceSegmentLengths.oneDay'),
    description: t('pages.xmo.targetedDisplay.tactics.geoFenceSegmentLengths.oneDay'),
  }, {
    value: t('pages.xmo.targetedDisplay.tactics.geoFenceSegmentLengths.instant'),
    description: t('pages.xmo.targetedDisplay.tactics.geoFenceSegmentLengths.instant'),
  },
];

export const tacticComeFromList = [
  {
    value: t('pages.xmo.targetedDisplay.tactics.tacticComeFrom.clientProvided'),
    description: t('pages.xmo.targetedDisplay.tactics.tacticComeFrom.clientProvided'),
  },
  {
    value: t('pages.xmo.targetedDisplay.tactics.tacticComeFrom.designIq'),
    description: t('pages.xmo.targetedDisplay.tactics.tacticComeFrom.designIq'),
  },
  {
    value: t('pages.xmo.targetedDisplay.tactics.tacticComeFrom.promotionsByVentures'),
    description: t('pages.xmo.targetedDisplay.tactics.tacticComeFrom.promotionsByVentures'),
  },
];

export const geoGraphicalAreaList = [
  {
    value: t('pages.xmo.targetedDisplay.tactics.geoGraphicalAreas.states'),
    description: t('pages.xmo.targetedDisplay.tactics.geoGraphicalAreas.states'),
  },
  {
    value: t('pages.xmo.targetedDisplay.tactics.geoGraphicalAreas.metros'),
    description: t('pages.xmo.targetedDisplay.tactics.geoGraphicalAreas.metros'),
  },
  {
    value: t('pages.xmo.targetedDisplay.tactics.geoGraphicalAreas.congressionalDistricts'),
    description: t('pages.xmo.targetedDisplay.tactics.geoGraphicalAreas.congressionalDistricts'),
  },
  {
    value: t('pages.xmo.targetedDisplay.tactics.geoGraphicalAreas.zipCodes'),
    description: t('pages.xmo.targetedDisplay.tactics.geoGraphicalAreas.zipCodes'),
  },
];

export const andOrList = [
  {
    value: 'and',
    description: t('pages.xmo.targetedDisplay.tactics.andOr.and'),
  },
  {
    value: 'or',
    description: t('pages.xmo.targetedDisplay.tactics.andOr.or'),
  },
];

export const yesNoList = [
  { value: 'yes', description: t('form.big-box-labels.yes') },
  { value: 'no', description: t('form.big-box-labels.no') },
];

export const targetingTypeList = [
  {
    value: t('pages.xmo.targetedDisplay.tactics.gdn.targetingTypeOptions.city'),
    description: t('pages.xmo.targetedDisplay.tactics.gdn.targetingTypeOptions.city'),
  },
  {
    value: t('pages.xmo.targetedDisplay.tactics.gdn.targetingTypeOptions.national'),
    description: t('pages.xmo.targetedDisplay.tactics.gdn.targetingTypeOptions.national'),
  },
  {
    value: t('pages.xmo.targetedDisplay.tactics.gdn.targetingTypeOptions.dma'),
    description: t('pages.xmo.targetedDisplay.tactics.gdn.targetingTypeOptions.dma'),
  },
];

export const tacticTypeList = [
  {
    value: t('pages.targetedDisplay.tacticModel.tacticsTypes.geoTargetOnly'),
    description: t('pages.targetedDisplay.tacticModel.tacticsTypes.geoTargetOnly'),
  },
  {
    value: t('pages.targetedDisplay.tacticModel.tacticsTypes.standardGeoFence'),
    description: t('pages.targetedDisplay.tacticModel.tacticsTypes.standardGeoFence'),
  },
  {
    value: t('pages.targetedDisplay.tacticModel.tacticsTypes.addressableGeoFenceClientProvided'),
    description: t('pages.targetedDisplay.tacticModel.tacticsTypes.addressableGeoFenceClientProvided'),
  },
  {
    value: t('pages.targetedDisplay.tacticModel.tacticsTypes.addressableGeoFenceCuratedAudience'),
    description: t('pages.targetedDisplay.tacticModel.tacticsTypes.addressableGeoFenceCuratedAudience'),
  },
  {
    value: t('pages.targetedDisplay.tacticModel.tacticsTypes.eventAudienceRetargeting'),
    description: t('pages.targetedDisplay.tacticModel.tacticsTypes.eventAudienceRetargeting'),
  },
  {
    value: t('pages.targetedDisplay.tacticModel.tacticsTypes.ztv'),
    description: t('pages.targetedDisplay.tacticModel.tacticsTypes.ztv'),
  },
  {
    value: t('pages.targetedDisplay.tacticModel.tacticsTypes.siteRetargeting'),
    description: t('pages.targetedDisplay.tacticModel.tacticsTypes.siteRetargeting'),
  },
  {
    value: t('pages.targetedDisplay.tacticModel.tacticsTypes.dynamicSiteRetargeting'),
    description: t('pages.targetedDisplay.tacticModel.tacticsTypes.dynamicSiteRetargeting'),
  },
  {
    value: t('pages.targetedDisplay.tacticModel.tacticsTypes.searchRetargeting'),
    description: t('pages.targetedDisplay.tacticModel.tacticsTypes.searchRetargeting'),
  },
  {
    value: t('pages.targetedDisplay.tacticModel.tacticsTypes.dynamicSearchProspecting'),
    description: t('pages.targetedDisplay.tacticModel.tacticsTypes.dynamicSearchProspecting'),
  },
  {
    value: t('pages.targetedDisplay.tacticModel.tacticsTypes.keywordContextual'),
    description: t('pages.targetedDisplay.tacticModel.tacticsTypes.keywordContextual'),
  },
  {
    value: t('pages.targetedDisplay.tacticModel.tacticsTypes.categoryContextual'),
    description: t('pages.targetedDisplay.tacticModel.tacticsTypes.categoryContextual'),
  },
  {
    value: t('pages.targetedDisplay.tacticModel.tacticsTypes.crmRetargeting'),
    description: t('pages.targetedDisplay.tacticModel.tacticsTypes.crmRetargeting'),
  },
  {
    value: t('pages.targetedDisplay.tacticModel.tacticsTypes.lookalikes'),
    description: t('pages.targetedDisplay.tacticModel.tacticsTypes.lookalikes'),
  },
];

export const adTypesRunningList = [
  {
    value: t('pages.targetedDisplay.tacticModel.adTypesRunning.image'),
    description: t('pages.targetedDisplay.tacticModel.adTypesRunning.image'),
  },
  {
    value: t('pages.targetedDisplay.tacticModel.adTypesRunning.preRoll'),
    description: t('pages.targetedDisplay.tacticModel.adTypesRunning.preRoll'),
  },
  {
    value: t('pages.targetedDisplay.tacticModel.adTypesRunning.ott'),
    description: t('pages.targetedDisplay.tacticModel.adTypesRunning.ott'),
  },
  {
    value: t('pages.targetedDisplay.tacticModel.adTypesRunning.ctv'),
    description: t('pages.targetedDisplay.tacticModel.adTypesRunning.ctv'),
  },
  {
    value: t('pages.targetedDisplay.tacticModel.adTypesRunning.huluOtt'),
    description: t('pages.targetedDisplay.tacticModel.adTypesRunning.huluOtt'),
  },
  {
    value: t('pages.targetedDisplay.tacticModel.adTypesRunning.huluCtv'),
    description: t('pages.targetedDisplay.tacticModel.adTypesRunning.huluCtv'),
  },
];

export const imagePreRollAdTypesRunningList = [
  {
    value: t('pages.targetedDisplay.tacticModel.adTypesRunning.image'),
    description: t('pages.targetedDisplay.tacticModel.adTypesRunning.image'),
  },
  {
    value: t('pages.targetedDisplay.tacticModel.adTypesRunning.preRoll'),
    description: t('pages.targetedDisplay.tacticModel.adTypesRunning.preRoll'),
  },
];

export const zTVAdTypesRunningList = [
  {
    value: t('pages.targetedDisplay.tacticModel.adTypesRunning.ctv'),
    description: t('pages.targetedDisplay.tacticModel.adTypesRunning.ctv'),
  },
];

export const imageAdTypesRunningList = [
  {
    value: t('pages.targetedDisplay.tacticModel.adTypesRunning.image'),
    description: t('pages.targetedDisplay.tacticModel.adTypesRunning.image'),
  },
];

export const huluAdLengthList = [
  {
    value: t('pages.targetedDisplay.tacticModel.huluAdLengths.fifteenSeconds'),
    description: t('pages.targetedDisplay.tacticModel.huluAdLengths.fifteenSeconds'),
  },
  {
    value: t('pages.targetedDisplay.tacticModel.huluAdLengths.thirtySeconds'),
    description: t('pages.targetedDisplay.tacticModel.huluAdLengths.thirtySeconds'),
  },
  {
    value: t('pages.targetedDisplay.tacticModel.huluAdLengths.sixtySeconds'),
    description: t('pages.targetedDisplay.tacticModel.huluAdLengths.sixtySeconds'),
  },
];

export const dspTacticComeFromList = [
  {
    value: t('pages.targetedDisplay.tacticModel.dspTacticComeFrom.allInventory'),
    description: t('pages.targetedDisplay.tacticModel.dspTacticComeFrom.allInventory'),
  },
  {
    value: t('pages.targetedDisplay.tacticModel.dspTacticComeFrom.usedInventoryOnly'),
    description: t('pages.targetedDisplay.tacticModel.dspTacticComeFrom.usedInventoryOnly'),
  },
  {
    value: t('pages.targetedDisplay.tacticModel.dspTacticComeFrom.newInventoryOnly'),
    description: t('pages.targetedDisplay.tacticModel.dspTacticComeFrom.newInventoryOnly'),
  },
  {
    value: t('pages.targetedDisplay.tacticModel.dspTacticComeFrom.notApplicable'),
    description: t('pages.targetedDisplay.tacticModel.dspTacticComeFrom.notApplicable'),
  },
];

export const tacticTypeAllowedFields = {
  geoTargetOnly: [
    'specificProductOrService',
    'tacticNameInClientReporting',
    'uniqueLandingPage',
    'landingPageUrl',
    'tacticComeFrom',
    'designiqLink',
    'creativeServiceCid',
    'uploadedInSalesForce',
    'sequentialRetargetingTactics',
    'adRunningTypes',
    'huluAdLength',
  ],
  standardGeofence: [
    'geoFenceSegmentLength',
    'uploader_standardGeoFenceAddressList',
    'specificProductOrService',
    'tacticNameInClientReporting',
    'uniqueLandingPage',
    'landingPageUrl',
    'tacticComeFrom',
    'designiqLink',
    'creativeServiceCid',
    'uploadedInSalesForce',
    'sequentialRetargetingTactics',
    'adRunningTypes',
    'huluAdLength',
  ],
  addressableClientProvided: [
    'geoFenceSegmentLength',
    'uploader_addressableGeoFenceAddressList',
    'specificProductOrService',
    'tacticNameInClientReporting',
    'uniqueLandingPage',
    'landingPageUrl',
    'tacticComeFrom',
    'designiqLink',
    'creativeServiceCid',
    'uploadedInSalesForce',
    'sequentialRetargetingTactics',
    'adRunningTypes',
    'huluAdLength',
  ],
  addressableCurated: [
    'geoFenceSegmentLength',
    'geographicalArea',
    'zipCodes',
    'congressionalDistricts',
    'cities',
    'states',
    'segment1',
    'segment2',
    'segment3',
    'segment4',
    'segment5',
    'andOr1',
    'andOr2',
    'andOr3',
    'andOr4',
    'specificProductOrService',
    'tacticNameInClientReporting',
    'uniqueLandingPage',
    'landingPageUrl',
    'tacticComeFrom',
    'designiqLink',
    'creativeServiceCid',
    'uploadedInSalesForce',
    'sequentialRetargetingTactics',
    'adRunningTypes',
    'huluAdLength',
  ],
  event: [
    'eventAudienceRetargetingSegmentLength',
    'eventName',
    'detailsOfEvent',
    'eventStartDate',
    'eventEndDate',
    'timeZone',
    'uploader_eventAudienceRetargetingList',
    'specificProductOrService',
    'tacticNameInClientReporting',
    'uniqueLandingPage',
    'landingPageUrl',
    'tacticComeFrom',
    'designiqLink',
    'creativeServiceCid',
    'uploadedInSalesForce',
    'sequentialRetargetingTactics',
    'adRunningTypes',
    'huluAdLength',
  ],
  ztv: [
    'uploader_ztvList',
    'specificProductOrService',
    'tacticNameInClientReporting',
    'uniqueLandingPage',
    'landingPageUrl',
    'tacticComeFrom',
    'designiqLink',
    'creativeServiceCid',
    'uploadedInSalesForce',
    'sequentialRetargetingTactics',
    'adRunningTypes',
    'huluAdLength',
  ],
  siteRetargeting: [
    'siteRetargetingUrls',
    'specificProductOrService',
    'tacticNameInClientReporting',
    'uniqueLandingPage',
    'landingPageUrl',
    'tacticComeFrom',
    'designiqLink',
    'creativeServiceCid',
    'uploadedInSalesForce',
    'sequentialRetargetingTactics',
    'adRunningTypes',
    'huluAdLength',
  ],
  dynamicSiteRetargeting: [
    'inventoryTarget',
    'dynamicAdHeadline',
    'dynamicAdTagline',
    'dynamicAdCallToAction',
    'specificProductOrService',
    'tacticNameInClientReporting',
    'uniqueLandingPage',
    'landingPageUrl',
    'tacticComeFrom',
    'designiqLink',
    'creativeServiceCid',
    'uploadedInSalesForce',
    'sequentialRetargetingTactics',
    'adRunningTypes',
    'huluAdLength',
  ],
  searchRetargeting: [
    'relevantReferenceKeywords',
    'uploader_searchRetargetingKeywordsList',
    'specificProductOrService',
    'tacticNameInClientReporting',
    'uniqueLandingPage',
    'landingPageUrl',
    'tacticComeFrom',
    'designiqLink',
    'creativeServiceCid',
    'uploadedInSalesForce',
    'sequentialRetargetingTactics',
    'adRunningTypes',
    'huluAdLength',
  ],
  dynamicSearchProspecting: [
    'inventoryTarget',
    'dynamicAdHeadline',
    'dynamicAdTagline',
    'dynamicAdCallToAction',
    'specificProductOrService',
    'tacticNameInClientReporting',
    'uniqueLandingPage',
    'landingPageUrl',
    'tacticComeFrom',
    'designiqLink',
    'creativeServiceCid',
    'uploadedInSalesForce',
    'sequentialRetargetingTactics',
    'adRunningTypes',
    'huluAdLength',
  ],
  keywordContextual: [
    'relevantReferenceKeywords',
    'uploader_keywordContextualKeywordsList',
    'specificProductOrService',
    'tacticNameInClientReporting',
    'uniqueLandingPage',
    'landingPageUrl',
    'tacticComeFrom',
    'designiqLink',
    'creativeServiceCid',
    'uploadedInSalesForce',
    'sequentialRetargetingTactics',
    'adRunningTypes',
    'huluAdLength',
  ],
  categoryContextual: [
    'categoryContextualInfo',
    'specificProductOrService',
    'tacticNameInClientReporting',
    'uniqueLandingPage',
    'landingPageUrl',
    'tacticComeFrom',
    'designiqLink',
    'creativeServiceCid',
    'uploadedInSalesForce',
    'sequentialRetargetingTactics',
    'adRunningTypes',
    'huluAdLength',
  ],
  crm: [
    'specificProductOrService',
    'tacticNameInClientReporting',
    'uniqueLandingPage',
    'landingPageUrl',
    'tacticComeFrom',
    'designiqLink',
    'creativeServiceCid',
    'uploadedInSalesForce',
    'sequentialRetargetingTactics',
    'adRunningTypes',
    'huluAdLength',
  ],
  lookalike: [
    'specificProductOrService',
    'tacticNameInClientReporting',
    'uniqueLandingPage',
    'landingPageUrl',
    'tacticComeFrom',
    'designiqLink',
    'creativeServiceCid',
    'uploadedInSalesForce',
    'sequentialRetargetingTactics',
    'adRunningTypes',
    'huluAdLength',
  ],
  gdn: [
    'targetingType',
    'targetedCities',
    'dmaTargetedDetails',
    'clientLikeToAddLookalikeAudienceExpansionToTheirCampaign',
  ],
};

export const isPropIsAllowed = (selected: TacticTypeSelected, prop: string) => (selected.geoTargetOnly && tacticTypeAllowedFields.geoTargetOnly.includes(prop))
      || (selected.standardGeofence && tacticTypeAllowedFields.standardGeofence.includes(prop))
      || (selected.addressableClientProvided && tacticTypeAllowedFields.addressableClientProvided.includes(prop))
      || (selected.addressableCurated && tacticTypeAllowedFields.addressableCurated.includes(prop)
          || selected.event && tacticTypeAllowedFields.event.includes(prop))
      || (selected.ztv && tacticTypeAllowedFields.ztv.includes(prop))
      || (selected.siteRetargeting && tacticTypeAllowedFields.siteRetargeting.includes(prop))
      || (selected.dynamicSiteRetargeting && tacticTypeAllowedFields.dynamicSiteRetargeting.includes(prop)
          || selected.searchRetargeting && tacticTypeAllowedFields.searchRetargeting.includes(prop))
      || (selected.dynamicSearchProspecting && tacticTypeAllowedFields.dynamicSearchProspecting.includes(prop))
      || (selected.keywordContextual && tacticTypeAllowedFields.keywordContextual.includes(prop))
      || (selected.categoryContextual && tacticTypeAllowedFields.categoryContextual.includes(prop)
          || selected.lookalike && tacticTypeAllowedFields.lookalike.includes(prop))
      || (selected.crm && tacticTypeAllowedFields.crm.includes(prop))
      || (selected.gdn && tacticTypeAllowedFields.gdn.includes(prop));
