import { Urls } from 'navigation/Urls';

export const pmmVariantsConstants = {
  label: 'Solution Strategy',
  items: [
    Urls.GeneralQuestions,
    Urls.PmmDetails,
    '',
    Urls.PmmSocialSmartOptimization,
    Urls.PmmWebsiteClicks,
    Urls.PmmLeadAds,
    Urls.PmmRetargeting,
    Urls.PmmTargetedDisplay,
  ],
};
