import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { ValidationProvider } from '@gannettdigital/shared-react-components';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import GridContainer from 'components/grid/GridContainer';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import GridItem from 'components/grid/GridItem';
import ChatSilverType from 'models/ChatSilverType';
import { getBusinessLocationsForChat, selectBusinessLocations } from 'services/chatLocationsSlice';
import ErrorText from 'components/errorText/ErrorText';
import ChatLocationCard from 'components/locationCard/ChatLocationCard';
import schema from './LocationCardForm.schema';
import LocationCardForm from './LocationCardForm';

interface Props {
  product: ChatSilverType;
  onSubmit: (data: ChatLocationsFormProps) => void;
  onBack: () => void;
}

type ChatLocationsFormProps = {
  locations: ChatSilverType['locations']
};

export default function ChatLocationsForm({ product, onSubmit, onBack }: Props) {
  const { locations } = product;

  const { orderItemId } = useParams();
  const dispatch = useDispatch();
  const businessLocations = useSelector(selectBusinessLocations);

  const [chatLocations, setChatLocations] = useState(locations);
  const [hasError, setHasError] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);

  const methods = useForm<ChatLocationsFormProps>({
    mode: 'onChange',
  });

  const handleFormSubmit = () => onSubmit({ locations: chatLocations });

  const {
    handleSubmit,
    watch,
    setError,
    clearErrors,
    reset,
    formState: {
      isValid, isDirty, isSubmitting, isSubmitSuccessful, dirtyFields,
    },
  } = methods;

  useEffect(() => {
    if (orderItemId && businessLocations === undefined) {
      dispatch(getBusinessLocationsForChat({ chatType: 'silver', orderItemId }));
    }
  }, [orderItemId]);

  useEffect(() => {
    if (businessLocations !== undefined
      && (chatLocations === undefined || chatLocations.length === 0 || !chatLocations[0].addressOne)) {
      const mapLocations = businessLocations.map(loc => ({
        ...loc,
        serviceAreas: loc.serviceAreas?.map(area => ({ label: area, value: area })),
      }));
      setChatLocations(mapLocations);
    }

    const defaultValues = {};
    if (businessLocations && businessLocations.length > 0 && (locations === undefined || locations.length === 0)) {
      businessLocations.forEach(location => { defaultValues[location.locationId] = location.selected; });
      reset({ ...defaultValues });
    }
  }, [businessLocations]);

  const watchAllFields = watch();

  const allCheckboxFields = Object.keys(watchAllFields);

  const isSelectedLocationsCompleted = Object.entries(watchAllFields).map(obj => {
    if (obj[1]) {
      const selectedLocation = chatLocations.filter(location => location.locationId === Number(obj[0]));
      return selectedLocation[0]?.status === 'Complete';
    }
    return true;
  }).every(el => el);

  const isLocationSelected = Object.values(watchAllFields).some(value => value);

  useEffect(() => {
    if (isDirty && !isLocationSelected && Object.values(dirtyFields).length > 0) {
      // @ts-ignore:next-line
      allCheckboxFields.map(fieldName => setError(fieldName, {
        type: 'manual',
        message: 'Please select and complete at least one location to continue.',
      }, { shouldFocus: false }));

      setHasError(true);
    }

    if (isLocationSelected) {
      clearErrors();
      setHasError(false);
    }

    if (isDirty) setIsFormDirty(true);
  }, [isDirty, isLocationSelected]);

  const handleChange = (e) => {
    const copyLocations = [...chatLocations];
    const updatedLocations = copyLocations.map(location =>
      ((location.locationId === Number(e.target.name))
        ? { ...location, selected: !location.selected, status: location.emails.length === 0 ? 'Review' : 'Complete' }
        : location));
    setChatLocations(updatedLocations);
  };

  return (
    <ValidationProvider schema={schema}>
      <FormProvider {...methods}>
        <DefaultPageLayout
          disableContinue={!isValid || !isLocationSelected || !isSelectedLocationsCompleted || hasError}
          onBack={onBack}
          header="Locations"
          description="Select all locations you would like included in the chat script.
          At least one location must be selected and completed to continue."
          onContinue={handleSubmit(handleFormSubmit)}
        >
          <GridContainer fullWidth>
            {chatLocations?.map(location => (
              <GridItem marginBottom={2}>
                <ChatLocationCard
                  field={`${location.locationId}`}
                  locationName={location.chatListedLocationName}
                  addressLine1={location.addressOne}
                  addressLine2={location.addressTwo}
                  city={location.city}
                  state={location.stateProvinceRegion}
                  zipcode={location.zipcode}
                  error={hasError && !isLocationSelected}
                  locationStatus={location.status as ('Complete' | 'Review' | null)}
                  childern={(
                    <LocationCardForm
                      location={location}
                      locations={chatLocations}
                      setLocations={setChatLocations}
                      setHasError={setHasError}
                      setIsFormDirty={setIsFormDirty}
                    />
                  )}
                  defaultValue={location.selected}
                  onChange={(e) => handleChange(e)}
                />
              </GridItem>
            ))}
            <ErrorText
              hasError={hasError && !isLocationSelected}
              errorText="Please select and complete at least one location to continue."
            />
          </GridContainer>
        </DefaultPageLayout>
        <CoNavigationConfirm
          showDialog={isFormDirty && !(isSubmitSuccessful || isSubmitting)}
        />
      </FormProvider>
    </ValidationProvider>
  );
}
