import {
  Button, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Tooltip, Typography,
} from '@mui/material';
import { useState } from 'react';
import ErrorText from 'components/NePhoneInputContainer/IQErrorText/IQErrorText';

interface Props {
  label: string;
  name: string;
  value: number;
  data: any[];
  register: any;
  errors: any;
  offeringType: string;
  trigger: any;
}

export default function CampaignIdSelect({
  label, offeringType, name, data, register, errors, value, trigger,
}: Props) {
  const [campaignValue, setCampaignValue] = useState(value);
  const [showAll, setShowAll] = useState(false);

  const handleChange = (event) => {
    setCampaignValue(event.target.value);
    trigger();
  };

  const renderCampaignText = (obj) => {
    if (!obj) return 'Select CID';
    return `CID: ${obj.id}, Campaign Name: ${obj.name}, Payment Status: ${obj.status}`;
  };

  const renderCampaignValue = (id) => {
    if (typeof id === 'undefined') return 'Select CID';
    const option = data.find((el) => el.id === id);
    return renderCampaignText(option);
  };

  function normalizeWord(w: string) {
    return w.toLowerCase().replace(/s$/, '');
  }

  const renderDropdown = (options: any[]) => {
    const keyword = offeringType?.replace(/([A-Z])/g, ' $1').split(' ').filter(Boolean) || [];
    const normalizedKeywords = keyword.map((k) => normalizeWord(k));

    const sortedOptions = options
      .map((option) => {
        const normText = (`${option.productName} ${option.productFamilyName}`)
          .split(/\s+/)
          .map((str) => normalizeWord(str))
          .join(' ');

        let matches = 0;
        // eslint-disable-next-line no-restricted-syntax
        for (const k of normalizedKeywords) {
          if (normText.includes(k)) matches++;
        }

        const ratio = normalizedKeywords.length ? matches / normalizedKeywords.length : 0;
        return { ...option, points: ratio >= 0.8 ? ratio : 0 };
      })
      .sort((a, b) => b.points - a.points);

    const filteredOptions = showAll
      ? sortedOptions
      : sortedOptions.filter((x) => x.points > 0);

    const dropdownOptions = [
      <MenuItem key="header" disableGutters style={{ padding: '0 8px' }}>
        <Grid container>
          <Grid item xs={2}>
            <Typography fontWeight="bold" variant="subtitle1" color="inherit">
              CID
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography fontWeight="bold" variant="subtitle1">
              Campaign Name
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography fontWeight="bold" variant="subtitle1">
              Payment Status
            </Typography>
          </Grid>
        </Grid>
      </MenuItem>,
      <Divider key="divider" variant="fullWidth" sx={{ borderColor: 'text.disabled' }} />,
    ];

    if (!filteredOptions.length && !showAll) {
      dropdownOptions.push(
        <MenuItem key="no-match" disabled>
          <Typography>No approximate matches found. Please check below.</Typography>
        </MenuItem>,
      );
    } else {
      dropdownOptions.push(
        ...filteredOptions.map((option) => (
          <MenuItem key={option.id} disableGutters value={option.id}>
            <Tooltip title={renderCampaignText(option)} placement="bottom-end">
              <Grid container style={{ padding: '8px' }}>
                <Grid item xs={2}>
                  <Typography>{option.id}</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography noWrap>{option.name}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    color={option.status === 'Running' ? 'success.dark' : 'inherit'}
                  >
                    {option.status}
                  </Typography>
                </Grid>
              </Grid>
            </Tooltip>
          </MenuItem>
        )),
      );
    }

    if (!showAll && filteredOptions.length < sortedOptions.length) {
      dropdownOptions.push(
        <MenuItem
          key="show-all"
          sx={{
            justifyContent: 'center',
            color: 'primary.main',
            fontWeight: 500,
          }}
        >
          Can’t find your campaign?
          <Button
            onClick={() => setShowAll(true)}
            variant="text"
            sx={{
              marginLeft: '0.5rem',
              textDecoration: 'underline',
              color: 'inherit',
              padding: 0,
              minWidth: 'auto',
              textTransform: 'none',
            }}
          >
            Show complete list
          </Button>
        </MenuItem>,
      );
    }

    return dropdownOptions;
  };

  const hasError = !!errors[name];
  const errorMessage = hasError ? errors[name]?.message : '';

  return (
    <Grid container>
      <Grid item xs={12} sx={{ marginBottom: '8px' }}>
        <Typography color={hasError ? 'error.main' : 'inherit'} fontWeight={600}>
          {`${label} *`}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: '8px' }}>
        <FormControl fullWidth>
          <InputLabel id={name} shrink={false}>
            {campaignValue === null && 'Select CID'}
          </InputLabel>
          <Select
            {...register(name, { required: 'Please select a CID to continue.' })}
            labelId="campaignId-label"
            id={name}
            value={campaignValue}
            onChange={handleChange}
            renderValue={(selected) => (
              <Tooltip title={renderCampaignValue(selected)} placement="right-end">
                <Typography noWrap>{renderCampaignValue(selected)}</Typography>
              </Tooltip>
            )}
            sx={{
              backgroundColor: 'common.white',
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: hasError ? 'error.main' : 'inherit',
              },
            }}
          >
            {renderDropdown(data)}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: '16px' }}>
        {hasError && (
          <ErrorText
            hasError={hasError}
            errorMessage={errorMessage as string}
            alignItems="flex-start"
          />
        )}
      </Grid>
    </Grid>
  );
}
