/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { StatusType } from 'models/StatusType';
import { RootState } from './redux/store';
import { createAsyncThunkCatchErrors } from './errorSlice';
import AxiosAPI from './common/axios';
import { thunkBuilder } from './common/functions';

export interface OrderProduct {
  id: number
  name?: string
  imageUrl: string
  deliveryEstimate?: Date
  status?: string
  lastCompletedStep: string
  productName: string
  orderStatus?: any
  orderItem?: any
}

export interface Order {
  healthcare?: boolean
  neonStatusList?: any
  campaignIds?: string
  neonStatus?: any
  id: number
  createdAt: Date
  businessName: string
  gmaid: string
  status: string
  orderType: string
  lastCompletedState: string
  products: string[]
}

export interface OrderSort {
  cron: string;
  biz: string;
}

export interface OrdersPage {
  totalElements: number;
  content: Order[]
  pageable: {
    pageSize: number
    pageNumber: number
    totalElements: number
    last: boolean
  }
  totalPages: number
  sort: OrderSort
}

export interface MyOrdersState {
  orders: StatusType<OrdersPage>
  orderDetails: StatusType<OrderProduct[]>
  currentOrder: StatusType<Order>
}

const initialState: MyOrdersState = {
  orders: {
    status: 'idle',
    message: '',
    content: {
      content: [],
      pageable: {
        pageSize: 20,
        pageNumber: 1,
        totalElements: 50,
        last: true,
      },
      totalElements: 50,
      totalPages: 25,
      sort: {
        cron: '',
        biz: '',
      },
    },
  },
  currentOrder: {
    status: 'idle',
    message: '',
    content: {} as Order,
  },
  orderDetails: {
    status: 'idle',
    message: '',
    content: [],
  },
};

export const getOrdersList = createAsyncThunkCatchErrors(
  'orders/list/get',
  async ({ orderType, query = '' } :{ orderType: string, query?: string }) => {
    const response = await AxiosAPI.get(`/orders/neon?orderType=${orderType}${query}`);
    return response.data;
  },
);

export const getOrderDetails = createAsyncThunkCatchErrors(
  'orderDetails/get',
  async (id: string) => {
    const response = await AxiosAPI.get(`/orders/order-items-details/${id}`);
    return response.data;
  },
);

export const deleteOrder = createAsyncThunkCatchErrors(
  'order/delete',
  async (id: string) => {
    const response = await AxiosAPI.put(`/orders/${id}/archive`);
    return response.data;
  },
);

export const getOrder = createAsyncThunkCatchErrors(
  'myOrders/get',
  async (id: string) => {
    const response = await AxiosAPI.get(`/orders/${id}`);
    return response.data;
  },
);

export const myOrdersSlice = createReducer(
  initialState,
  (builder) => {
    thunkBuilder(builder)
      .addCase(getOrdersList, 'orders')
      .addCase(getOrderDetails, 'orderDetails');

    builder
      .addCase(deleteOrder.fulfilled, (state, { payload }) => {
        const orderIndex = state.orders.content.content.findIndex(order => order.id === payload.id);
        const ordersCopy = [...state.orders.content.content];
        ordersCopy.splice(orderIndex, 1);
        state.orders.content.content = ordersCopy;
      })
      .addCase(getOrder.fulfilled, (state, { payload }) => {
        state.currentOrder.content = payload.data;
      });
  },
);

export const selectOrders = (state: RootState) => state.myOrders.orders.content;
export const selectOrdersStatus = (state: RootState) => state.myOrders.orders.status;
export const selectOrderDetails = (state: RootState) => state.myOrders.orderDetails.content;
export const selectCurrentOrder = (state: RootState) => state.myOrders.currentOrder.content;

export default myOrdersSlice;
