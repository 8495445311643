import * as yup from 'yup';
import i18n from 'i18n/i18n';

const { t } = i18n;

const schema = yup.object().shape({
  willAdsScheduledToRunDuringCertainTimes: yup.string()
    .required(t('pages.youtube.advertising.willAdsScheduledToRunDuringCertainTimesErrorMsg')).nullable(),
  youtubeSelectTargeting: yup.string().required(t('pages.youtube.advertising.youtubeSelectTargetingErrorMsg'))
    .nullable(),
  weekdayStartAndEndTimes: yup.string().notRequired(),
  weekendStartAndEndTimes: yup.string().notRequired(),
  targetViewer: yup.string().required(t('pages.youtube.advertising.targetViewerErrorMsg')).nullable(),
  minimumAge: yup.number().required().nullable()
    .typeError(t('pages.generic-translation.minAgeNumberErrorMessage'))
    .test('is-less-than-or-equal-to-99', t('pages.generic-translation.minAgeLimitErrorMessage'), (value) => value <= 99)
    .test('is-not-positive', t('pages.generic-translation.minAgePositiveErrorMessage'), (value) => value > 0),
  maximumAge: yup.number().required().nullable()
    .typeError(t('pages.generic-translation.maxAgeNumberErrorMessage'))
    // eslint-disable-next-line func-names
    .test('is-greater-than-minimumAge', t('pages.generic-translation.maxAgeGreaterThanMinAgeErrorMessage'), function (value) {
      const { minimumAge } = this.parent;
      if (minimumAge === undefined || value === undefined) {
        return true;
      }
      return value >= minimumAge;
    })
    .test('is-not-greater-than-99', t('pages.generic-translation.maxAgeLimitErrorMessage'), (value) => value <= 99)
    .test('is-not-positive', t('pages.generic-translation.maxAgePositiveErrorMessage'), (value) => value > 0),
  numberOfAdGroups: yup.string().required(t('pages.youtube.advertising.numberOfAdGroupsErrorMsg')).nullable(),
  willClientProvidingClientList: yup.string().notRequired(),
  willClientProvidingCompanionBannerAd: yup.string().notRequired(),
  notesInstructions: yup.string().notRequired(),
});

export default {
  yupValidations: schema,
  customValidations: {},
};
