import { FormProvider, useForm } from 'react-hook-form';
import {
  IQDatePicker,
  IQFormInput,
  IQFormSelect,
  IQTheme, IQThemeV2,
  ValidationProvider,
} from '@gannettdigital/shared-react-components';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import Box from '@mui/material/Box';
import i18n from 'i18n/i18n';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import YouTubeType from 'models/YouTubeType';
import GridItem from 'components/grid/GridItem';
import {
  targetingTypeDropdownItems,
  targetingTypeRadiusDistanceDropdownItems,
} from 'pages/youtube/targeting/TargetingTypeService';
import React, {
  Suspense,
  useEffect, useMemo,
  useRef,
  useState,
} from 'react';
import GridContainer from 'components/grid/GridContainer';
import { yesNoDropdownItems } from 'pages/youtube/YouTubeService';
import {
  Autocomplete,
  TextField,
  Typography,
} from '@mui/material';
import useMapboxService from 'services/MapboxService';
import CityTargetingDetails from 'pages/generic-product/details/custom-fields/CityTargetingDetails';
import DmaTargetingDetails from 'pages/generic-product/details/custom-fields/DmaTargetingDetails';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getOrderBusinessByOrderId, selectOrderBusiness } from 'services/businessSlice';
import { getCountryCodeFromGmaid } from 'shared/common';
import { H2 } from 'components/labels/H2';
import ContentLoader from 'components/contentLoader/ContentLoader';
import ErrorText from 'components/errorText/ErrorText';
import schema from './Targeting.schema';

const { t } = i18n;

interface Props {
  product: YouTubeType;
  onSubmit: (data: TargetingPageFormProps) => void;
  onBack: () => void;
}

export type TargetingPageFormProps = {
  targetingType: string;
  youtubeTactics?: string;
  isCampaignFlighted?: string;
  startDate?: Date | string;
  endDate?: Date | string;
  cityTargetingDetails: any;
  dmaTargetingDetails: any;
  distance: string;
  additionalTargetingDetails: string;
  areasToInclude: string;
  areasToExclude: string;
  usingAdvertiserSalesforceBusinessAddress: string
  addressToUse: any
};

export const youtubeTacticsDropdownItems = [
  { value: 'YouTube', description: 'YouTube' },
  { value: 'YouTube Select ($20K minimum)', description: 'YouTube Select ($20K minimum)' },
  { value: 'YouTube TV ($85K minimum)', description: 'YouTube TV ($85K minimum)' },
];

export default function TargetingPageForm({ product, onSubmit, onBack }: Props) {
  const { getServiceAreas } = useMapboxService();
  const { orderId } = useParams();
  const dispatch = useDispatch();
  const { content } = useSelector(selectOrderBusiness);
  // eslint-disable-next-line max-len
  const [usingAdvertiserSalesforceBusinessAddressSelectedValue, setUsingAdvertiserSalesforceBusinessAddressSelectedValue] = useState(product?.usingAdvertiserSalesforceBusinessAddress || '');
  const [targetingTypeSelectedValue, setTargetingTypeSelectedValue] = useState<any>(product?.targetingType || '');
  const [isCampaignFlightedSelectedValue, setIsCampaignFlightedSelectedValue] = useState<any>(
    product?.isCampaignFlighted || '',
  );
  const [youtubeTacticSelectedValue, setYoutubeTacticSelectedValue] = useState<any>(product?.youtubeTactics || '');
  const [places, setPlaces] = useState<any>([]);
  const [selectedAddress, setSelectedAddress] = useState<any>(product?.addressToUse);
  const [isRadiusDataRequired, setIsRadiusDataRequired] = useState<boolean>(false);
  const [cityDetails, setCityDetails] = useState<any>([]);
  const [dmaDetails, setDmaDetails] = useState<any>([]);
  const deBouncerRef = useRef<any>(null);

  const parseDate = (date: Date | string | undefined) => {
    if (date === null || date === undefined) return null;
    return new Date(date);
  };

  const methods = useForm<TargetingPageFormProps>({
    mode: 'onChange',
    defaultValues: {
      targetingType: product?.targetingType || '',
      youtubeTactics: product?.youtubeTactics || '',
      isCampaignFlighted: product?.isCampaignFlighted || '',
      startDate: parseDate(product?.startDate),
      endDate: parseDate(product?.endDate),
      distance: product?.distance || '',
      addressToUse: product?.addressToUse || '',
      usingAdvertiserSalesforceBusinessAddress: product?.usingAdvertiserSalesforceBusinessAddress || '',
      additionalTargetingDetails: product?.additionalTargetingDetails || '',
      areasToInclude: product?.areasToInclude || '',
      areasToExclude: product?.areasToExclude || '',
    },
  });

  useEffect(() => {
    if (product?.cityTargetingDetails) setCityDetails(product?.cityTargetingDetails);
  }, [product]);

  const {
    handleSubmit,
    watch,
    setValue,
    getValues,
    trigger,
    setError,
    formState: {
      errors,
      isValid,
      isDirty,
      isSubmitSuccessful,
      isSubmitting,
    },
  } = methods;

  const addressToUseValue = watch('addressToUse');

  useEffect(() => {
    const start = getValues('startDate');
    const end = getValues('endDate');
    if (end < start) {
      setError('startDate', {
        message: 'Please select or enter a date before the End Date selection.',
      });
      return;
    }

    if (isDirty) trigger();
  }, [watch('endDate'), watch('startDate')]);

  useEffect(() => {
    if (watch('youtubeTactics') !== 'YouTube' && watch('targetingType') === 'Radius') {
      setValue('targetingType', '');
      setValue('distance', '');
      setTargetingTypeSelectedValue('');
    }
  }, [watch('youtubeTactics'), watch('targetingType')]);

  useEffect(() => {
    if (watch('isCampaignFlighted') === 'No') {
      setValue('startDate', null);
      setValue('endDate', null);
      setIsCampaignFlightedSelectedValue('');
    }
  }, [watch('isCampaignFlighted')]);

  useEffect(() => {
    if (targetingTypeSelectedValue.includes('Radius')) {
      setIsRadiusDataRequired(true);
      if (watch('distance') !== '') {
        if (usingAdvertiserSalesforceBusinessAddressSelectedValue === 'Yes') {
          setIsRadiusDataRequired(false);
        } else if (!(addressToUseValue === null || addressToUseValue === '')) {
          setIsRadiusDataRequired(false);
        }
      }
    } else {
      setIsRadiusDataRequired(false);
    }
  }, [targetingTypeSelectedValue, usingAdvertiserSalesforceBusinessAddressSelectedValue, addressToUseValue]);

  const handleFormSubmit = (data: TargetingPageFormProps) => {
    const updatedData = { ...data };

    updatedData.usingAdvertiserSalesforceBusinessAddress = usingAdvertiserSalesforceBusinessAddressSelectedValue;
    updatedData.addressToUse = selectedAddress?.label;

    if (updatedData.targetingType === 'Radius') {
      updatedData.additionalTargetingDetails = '';
    } else if (updatedData.targetingType === 'City') {
      updatedData.distance = '';
      updatedData.usingAdvertiserSalesforceBusinessAddress = '';
      updatedData.addressToUse = '';
    } else if (updatedData.targetingType === 'City' || data.targetingType === 'DMA') {
      updatedData.distance = '';
      updatedData.usingAdvertiserSalesforceBusinessAddress = '';
      updatedData.addressToUse = '';
    } else if (updatedData.targetingType === 'National') {
      updatedData.additionalTargetingDetails = '';
      updatedData.distance = '';
      updatedData.usingAdvertiserSalesforceBusinessAddress = '';
      updatedData.addressToUse = '';
    }

    if (updatedData.usingAdvertiserSalesforceBusinessAddress === 'Yes' || (!updatedData.addressToUse)) {
      updatedData.addressToUse = '';
    }
    updatedData.startDate = updatedData.startDate ? updatedData.startDate.toString() : null;
    updatedData.endDate = updatedData.endDate ? updatedData.endDate.toString() : null;

    onSubmit(
      {
        ...updatedData,
        cityTargetingDetails: data?.targetingType === 'City' ? cityDetails : [],
        dmaTargetingDetails: data?.targetingType === 'DMA' ? dmaDetails : [],
      },
    );
  };

  const handleTargetingTypeDropdownChange = (event) => {
    setTargetingTypeSelectedValue(event.target.value);
  };

  const handleUsingAdvertiserSalesforceBusinessAddressDropdownChange = (event) => {
    setUsingAdvertiserSalesforceBusinessAddressSelectedValue(event.target.value);
  };

  const handleYoutubeTacticsDropdownChange = (event) => {
    setYoutubeTacticSelectedValue(event.target.value);
  };

  const handleIsCampaignFlightedDropdownChange = (event) => {
    setIsCampaignFlightedSelectedValue(event.target.value);
  };

  const getSuggestions = async (value: any) => {
    const countryCode = getCountryCodeFromGmaid(content?.gmaid);
    try {
      const address = await getServiceAreas(value, countryCode);
      setPlaces(address);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleInputChange = (value: any) => {
    if (deBouncerRef.current) {
      clearTimeout(deBouncerRef.current);
    }
    deBouncerRef.current = setTimeout(() => {
      getSuggestions(value);
    }, 1500);
  };

  useEffect(() => {
    dispatch(getOrderBusinessByOrderId(orderId));
  }, [orderId]);

  const showYoutubeHelpText = youtubeTacticSelectedValue === 'YouTube Select ($20K minimum)'
      || youtubeTacticSelectedValue === 'YouTube TV ($85K minimum)';

  const showDateFields = isCampaignFlightedSelectedValue === 'Yes';

  const targetingTypeOptions = useMemo(() => {
    if (showYoutubeHelpText) {
      return targetingTypeDropdownItems.filter(item => item.value !== 'Radius');
    }
    return targetingTypeDropdownItems;
  }, [showYoutubeHelpText]);

  const renderYoutubeHelpText = () => {
    if (!showYoutubeHelpText) return null;

    return (
      <GridItem sizes={[12]}>
        <Typography>
          {t('pages.youtube.targeting.helpText_1')}
        </Typography>
      </GridItem>
    );
  };

  return (
    <DefaultPageLayout
      disableContinue={!isValid || isRadiusDataRequired}
      onBack={onBack}
      header="Tactics"
      onContinue={handleSubmit(handleFormSubmit)}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <Box
            id="mainForm"
            component="form"
            sx={{ display: 'flex' }}
            onSubmit={handleSubmit(handleFormSubmit)}
          >
            <GridContainer fullWidth>
              <GridItem sizes={[12]}>
                <IQFormSelect
                  id="youtubeTactics"
                  name="youtubeTactics"
                  labelText="Tactics *"
                  items={youtubeTacticsDropdownItems}
                  onChange={handleYoutubeTacticsDropdownChange}
                  theme={IQTheme}
                  required
                  fontLabelWeight="600"
                  fullWidth
                  schema={schema}
                />
              </GridItem>
              {renderYoutubeHelpText()}
              <GridItem sizes={[12]}>
                <H2>{t('pages.youtube.targeting.header')}</H2>
              </GridItem>
              <GridItem sizes={[12]}>
                <IQFormSelect
                  id="isCampaignFlighted"
                  name="isCampaignFlighted"
                  labelText={t('pages.youtube.targeting.isCampaignFlightedLabel')}
                  items={yesNoDropdownItems}
                  onChange={handleIsCampaignFlightedDropdownChange}
                  theme={IQTheme}
                  fontLabelWeight="600"
                  fullWidth
                  schema={schema}
                />
              </GridItem>

              {/* Dates */}
              {showDateFields && (
              <Suspense fallback={<ContentLoader status="loading" />}>
                <GridItem marginBottom={1}>
                  <Typography variant="subtitle2" component="h3" fontSize={16}>
                    Campaign Dates:
                  </Typography>
                </GridItem>

                <GridItem sizes={[5, 2, 5]}>
                  <GridItem marginBottom={0}>
                    <IQDatePicker
                      id="startDate"
                      name="startDate"
                      labelText="Start Date *"
                      fullWidth
                      fontLabelWeight="600"
                      theme={IQThemeV2}
                      disablePast={false}
                      minDate={new Date('01-01-1800')}
                      hasError={!!errors?.startDate}
                    />
                    <ErrorText
                      hasError={!!errors?.startDate}
                      errorText={errors?.startDate?.message}
                    />
                  </GridItem>
                  <Typography sx={{
                    margin: '0 16px', position: 'relative', top: '38px', textAlign: 'center',
                  }}
                  >
                    -
                  </Typography>
                  <GridItem marginBottom={0}>
                    <IQDatePicker
                      id="endDate"
                      name="endDate"
                      labelText="End Date *"
                      fontLabelWeight="600"
                      fullWidth
                      theme={IQThemeV2}
                      disablePast={false}
                      minDate={new Date('01-01-1800')}
                      hasError={!!errors?.endDate}
                    />
                    <ErrorText
                      hasError={!!errors?.endDate}
                      errorText={errors?.endDate?.message}
                    />
                  </GridItem>
                </GridItem>
              </Suspense>
              )}
              <GridItem sizes={[12]}>
                <IQFormSelect
                  id="targetingType"
                  name="targetingType"
                  labelText={t('pages.youtube.targeting.targetingType')}
                  items={targetingTypeOptions}
                  defaultValue=""
                  onChange={handleTargetingTypeDropdownChange}
                  theme={IQTheme}
                  fontLabelWeight="600"
                  fullWidth
                  schema={schema}
                />
              </GridItem>
              {targetingTypeSelectedValue === 'Radius' && (
                <>
                  <GridItem sizes={[12]}>
                    <IQFormSelect
                      id="distance"
                      name="distance"
                      labelText={t('pages.youtube.targeting.distance')}
                      items={targetingTypeRadiusDistanceDropdownItems()}
                      defaultValue=""
                      theme={IQTheme}
                      fontLabelWeight="600"
                      fullWidth
                      schema={schema}
                    />
                  </GridItem>
                  <GridItem sizes={[12]}>
                    <IQFormSelect
                      id="usingAdvertiserSalesforceBusinessAddress"
                      name="usingAdvertiserSalesforceBusinessAddress"
                      labelText={t('pages.youtube.targeting.usingAdvertiserSalesforceBusinessAddress')}
                      items={yesNoDropdownItems}
                      onChange={handleUsingAdvertiserSalesforceBusinessAddressDropdownChange}
                      theme={IQTheme}
                      fontLabelWeight="600"
                      fullWidth
                      schema={schema}
                    />
                  </GridItem>
                  {usingAdvertiserSalesforceBusinessAddressSelectedValue === 'No'
                          && (
                          <>
                            <GridItem sizes={[12]} marginBottom={2}>
                              <Typography
                                fontWeight={600}
                                sx={{
                                  color: IQThemeV2.palette.text.primary,
                                }}
                              >
                                {t('pages.youtube.targeting.addressToUse')}
                              </Typography>
                            </GridItem>
                            <GridItem sizes={[12]}>
                              <Autocomplete
                                value={selectedAddress}
                                disablePortal
                                defaultValue={selectedAddress}
                                id="addressToUse"
                                options={places}
                                sx={{
                                  width: '100%',
                                  border: '1px solid #292928',
                                  '& .MuiOutlinedInput-notchedOutline': { border: 0 },
                                }}
                                onInputChange={(event, value) => {
                                  handleInputChange(value);
                                }}
                                onChange={(event, value) => {
                                  setSelectedAddress(value);
                                  setValue('addressToUse', value);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                              />

                            </GridItem>
                          </>
                          )}
                </>
              )}
              {targetingTypeSelectedValue === 'City'
                    && (
                    <GridItem sizes={[12]} marginTop={-3}>
                      <CityTargetingDetails cityDetails={cityDetails}
                        setCityDetails={setCityDetails}
                        nonSchema
                      />
                    </GridItem>
                    )}
              {targetingTypeSelectedValue === 'DMA'
                    && (
                    <GridItem sizes={[12]} marginTop={-3}>
                      <DmaTargetingDetails dmaDetails={dmaDetails}
                        setDmaDetails={setDmaDetails}
                        nonSchema
                      />
                    </GridItem>
                    )}
              <GridItem sizes={[12]}>
                <IQFormInput
                  id="areasToInclude"
                  name="areasToInclude"
                  labelText={t('pages.youtube.targeting.areasToInclude')}
                  theme={IQTheme}
                  fontLabelWeight="600"
                  fullWidth
                  schema={schema}
                />
              </GridItem>
              <GridItem sizes={[12]}>
                <IQFormInput
                  id="areasToExclude"
                  name="areasToExclude"
                  labelText={t('pages.youtube.targeting.areasToExclude')}
                  theme={IQTheme}
                  fontLabelWeight="600"
                  fullWidth
                  schema={schema}
                />
              </GridItem>
            </GridContainer>
          </Box>
        </FormProvider>
      </ValidationProvider>
      <CoNavigationConfirm
        showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
      />
    </DefaultPageLayout>
  );
}
