/* eslint-disable max-len */
import {
  IQFormInput,
  IQFormSelect,
  IQFormTextArea,
  IQTheme, ValidationProvider,
} from '@gannettdigital/shared-react-components';
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  FormHelperText,
  Grid,
  Link,
  MenuItem,
  Typography,
} from '@mui/material';
import GridItem from 'components/grid/GridItem';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import { updateOrAddDataBasedOnSourceId, yesNoDropdownItems } from 'pages/youtube/YouTubeService';
import GridContainer from 'components/grid/GridContainer';
import { numberOfAdGroupsDropdownItems } from 'pages/youtube/advertising/AdvertisingService';
import AdGroupOptionsFormDialog
  from 'pages/youtube/advertising/ad-group-options/AdGroupOptionsFormDialog';
import GenericCard from 'components/generic-card/GenericCard';
import { useTranslation } from 'react-i18next';
import YouTubeType from 'models/YouTubeType';
import { FormProvider, useForm } from 'react-hook-form';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import { Box } from '@mui/system';
import { yupResolver } from '@hookform/resolvers/yup';
import ErrorText from 'components/errorText/ErrorText';
import NeDropdown from 'components/dropdown/NeDropdown';
import { normalizeNewLines } from 'shared/constants';
import { youtubeSelectTargetingOptions } from 'pages/youtube/advertising/ad-group-options/AdGroupOptionsService';
import schema from './Advertising.schema';

interface Props {
  product: YouTubeType;
  onSubmit: (data: AdvertisingPageFormProps) => void;
  onBack: () => void;
}

export type AdvertisingPageFormProps = {
  youtubeSelectTargeting: string;
  willAdsScheduledToRunDuringCertainTimes: string;
  weekdayStartAndEndTimes: string;
  weekendStartAndEndTimes: string;
  targetViewer: string;
  minimumAge: number;
  maximumAge: number;
  numberOfAdGroups: string;
  willClientProvidingClientList: string;
  willClientProvidingCompanionBannerAd: string;
  notesInstructions: string;
  adGroupOptions: {
    sourceId: string;
    adGroupName: string;
    youTubeVideoLink: string;
    additionalYouTubeVideoLink: string;
    advertiserWebsiteUrl: string;
    subPageUrl: string;
    howCampaignShouldBeTargeted: string;
    audiences: string;
    affinityAudiences: {
      sourceId: string;
      affinityAudience: string;
      category: string;
      subCategory: string;
      specifics: string;
      details: string;
    }[],
    inMarketAudiences: {
      sourceId: string;
      inMarketAudience: string;
      category: string;
      subCategory: string;
      specifics: string;
      details: string;
    }[],
    lifeEvents: {
      sourceId: string;
      lifeEventsAudience: string;
      category: string;
    }[],
  }[];
};

export default function AdvertisingPageForm({ product, onSubmit, onBack }: Props) {
  const { t } = useTranslation();

  const methods = useForm<AdvertisingPageFormProps>({
    resolver: yupResolver(schema.yupValidations),
    mode: 'onChange',
    defaultValues: {
      youtubeSelectTargeting: product?.youtubeSelectTargeting || '',
      willAdsScheduledToRunDuringCertainTimes: product?.willAdsScheduledToRunDuringCertainTimes || '',
      weekdayStartAndEndTimes: product?.weekdayStartAndEndTimes || '',
      weekendStartAndEndTimes: product?.weekendStartAndEndTimes || '',
      targetViewer: normalizeNewLines(product?.targetViewer) || '',
      minimumAge: product?.minimumAge || null,
      maximumAge: product?.maximumAge || null,
      numberOfAdGroups: product?.numberOfAdGroups || '',
      willClientProvidingClientList: product?.willClientProvidingClientList || '',
      willClientProvidingCompanionBannerAd: product?.willClientProvidingCompanionBannerAd || '',
      notesInstructions: normalizeNewLines(product?.notesInstructions) || '',
    },
  });

  const [adGroupOptionsFields, setAdGroupOptionsFields] = useState(product?.adGroupOptions || []);

  const handleFormSubmit = (data: AdvertisingPageFormProps) => {
    const formData = { ...data, adGroupOptions: adGroupOptionsFields };

    if (formData.willAdsScheduledToRunDuringCertainTimes === 'No') {
      formData.weekdayStartAndEndTimes = '';
      formData.weekendStartAndEndTimes = '';
    }
    onSubmit(formData);
  };

  const {
    handleSubmit,
    watch,
    getValues,
    setError,
    clearErrors,
    trigger,
    register,
    formState: {
      errors,
      isValid,
      isDirty,
      isSubmitSuccessful,
      isSubmitting,
    },
  } = methods;

  const [willClientProvidingCompanionBannerAdSelectedValue, setWillClientProvidingCompanionBannerAdSelectedValue] = useState('');

  const handleWillClientProvidingCompanionBannerAdDropdownChange = (event) => {
    setWillClientProvidingCompanionBannerAdSelectedValue(event.target.value);
  };

  /** Ad Group Options * */
  const [adGroupOptionsFormDialogOpen, setAdGroupOptionsFormDialogOpen] = useState(false);

  const [selectedAdGroupOptionsFields, setSelectedAdGroupOptionsFields] = useState([]);
  const [willAdsScheduledToRunDuringCertainTimesDropdownChangeSelectedValue, setWillAdsScheduledToRunDuringCertainTimesDropdownChangeSelectedValue] = useState(product?.willAdsScheduledToRunDuringCertainTimes || '');
  const [numberOfAdGroupsDropdownSelectedValue, setNumberOfAdGroupsDropdownChangeSelectedValue] = useState(product?.numberOfAdGroups || 0);

  const adGroupOptionsFieldsLabels = ['audiences', 'adGroupName'];
  const handleAdGroupOptionsFormDialogOpen = () => setAdGroupOptionsFormDialogOpen(true);
  const handleAdGroupOptionsFormDialogClose = () => {
    setAdGroupOptionsFormDialogOpen(false);
    setSelectedAdGroupOptionsFields([]);
  };

  const handleAdGroupOptionsSelection = (selectedAdGroupOptionsField) => {
    // Update selectedAdGroupOptions state
    setSelectedAdGroupOptionsFields(selectedAdGroupOptionsField);

    // Open the dialog
    handleAdGroupOptionsFormDialogOpen();
  };

  // Function to update AdGroupOptions
  const updateAdGroupOptions = (newData) => {
    setAdGroupOptionsFields(prevData =>
    // Update or add new data based on sourceId
      updateOrAddDataBasedOnSourceId(prevData, newData));
  };

  const onDeleteAdGroupOptionsFields = async (adGroupOptionField: any) => {
    // Filter out the item with the specified sourceId
    const updatedAdGroupOptions = adGroupOptionsFields.filter(
      (adGroupOption) => adGroupOption.sourceId !== adGroupOptionField.sourceId,
    );

    // Update the local state with the new lifeEvents array
    setAdGroupOptionsFields(updatedAdGroupOptions);
  };

  const handleWillAdsScheduledToRunDuringCertainTimesDropdownChange = (event) => {
    setWillAdsScheduledToRunDuringCertainTimesDropdownChangeSelectedValue(event.target.value);
  };

  useMemo(() => {
    const { minimumAge, maximumAge } = getValues();
    if (minimumAge && maximumAge) {
      trigger('maximumAge');
    }
  }, [watch('minimumAge')]);

  const handleNumberOfAdGroupsDropdownChange = () => {
    const value = Number(getValues('numberOfAdGroups'));
    setNumberOfAdGroupsDropdownChangeSelectedValue(value);
  };

  useEffect(() => {
    handleNumberOfAdGroupsDropdownChange();
  }, [watch('numberOfAdGroups')]);

  useEffect(() => {
    if (isDirty) {
      // Check if adGroupOptionsFields.length exceeds the NumberOfAdGroups selected value
      if (adGroupOptionsFields.length > numberOfAdGroupsDropdownSelectedValue) {
        setError('numberOfAdGroups', {
          type: 'manual',
          message: 'Ad Group Options should not exceed the Number of Ad Groups.',
        }, { shouldFocus: false });
      } else if (adGroupOptionsFields.length < numberOfAdGroupsDropdownSelectedValue) {
        setError('numberOfAdGroups', {
          type: 'manual',
          message: 'Ad Group Options should not be less than the Number of Ad Groups.',
        }, { shouldFocus: false });
      } else {
        clearErrors('numberOfAdGroups');
        trigger('numberOfAdGroups');
      }
    }
  }, [numberOfAdGroupsDropdownSelectedValue, 'numberOfAdGroups', adGroupOptionsFields.length]);

  return (
    <DefaultPageLayout
      disableContinue={!isValid || Object.keys(errors).length > 0}
      onBack={onBack}
      header={t('pages.youtube.advertising.header')}
      onContinue={handleSubmit(handleFormSubmit)}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <Box
            id="mainForm"
            component="form"
            sx={{ display: 'flex' }}
            onSubmit={handleSubmit(handleFormSubmit)}
          >
            <GridContainer fullWidth>
              <GridItem sizes={[12]}>
                <IQFormSelect
                  id="youtubeSelectTargeting"
                  name="youtubeSelectTargeting"
                  labelText={t('pages.youtube.advertising.youtubeSelectTargeting')}
                  items={youtubeSelectTargetingOptions}
                  defaultValue=""
                  theme={IQTheme}
                  required
                  fontLabelWeight="600"
                  fullWidth
                  schema={schema}
                />
              </GridItem>
              <GridItem sizes={[12]}>
                <IQFormSelect
                  id="willAdsScheduledToRunDuringCertainTimes"
                  name="willAdsScheduledToRunDuringCertainTimes"
                  labelText={t('pages.youtube.advertising.willAdsScheduledToRunDuringCertainTimes')}
                  items={yesNoDropdownItems}
                  defaultValue=""
                  theme={IQTheme}
                  required
                  onChange={handleWillAdsScheduledToRunDuringCertainTimesDropdownChange}
                  fontLabelWeight="600"
                  fullWidth
                  schema={schema}
                />
              </GridItem>
              {willAdsScheduledToRunDuringCertainTimesDropdownChangeSelectedValue === 'Yes' && (
                <GridItem inlineItems sizes={[6, 6]}>
                  <IQFormInput
                    id="weekdayStartAndEndTimes"
                    theme={IQTheme}
                    labelText={t('pages.youtube.advertising.weekdayStartAndEndTimes')}
                    fontLabelWeight="600"
                    name="weekdayStartAndEndTimes"
                    fullWidth
                  />
                  <IQFormInput
                    id="weekendStartAndEndTimes"
                    theme={IQTheme}
                    labelText={t('pages.youtube.advertising.weekendStartAndEndTimes')}
                    fontLabelWeight="600"
                    name="weekendStartAndEndTimes"
                    fullWidth
                  />
                </GridItem>
              )}
              <GridItem sizes={[12]}>
                <IQFormTextArea
                  id="targetViewer"
                  labelText={t('pages.youtube.advertising.targetViewer')}
                  name="targetViewer"
                  rowCount={2}
                  fullWidth
                  fontLabelWeight="600"
                  showError
                  required
                />
                <FormHelperText sx={{
                  mt: '-15px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}
                >
                  <Typography>{t('pages.youtube.advertising.targetViewerHelpText')}</Typography>
                </FormHelperText>
              </GridItem>
              <GridItem inlineItems sizes={[4, 4, 4]}>
                <IQFormInput
                  id="minimumAge"
                  name="minimumAge"
                  labelText={t('pages.youtube.advertising.minimumAge')}
                  theme={IQTheme}
                  fontLabelWeight="600"
                  fullWidth
                  schema={schema}
                />
                <IQFormInput
                  id="maximumAge"
                  name="maximumAge"
                  labelText={t('pages.youtube.advertising.maximumAge')}
                  theme={IQTheme}
                  fontLabelWeight="600"
                  fullWidth
                  schema={schema}
                />
                <NeDropdown
                  name="numberOfAdGroups"
                  label={t('pages.youtube.advertising.numberOfAdGroups')}
                  {...register('numberOfAdGroups', { required: 'This is required' })}
                  required
                  defaultValue={product?.numberOfAdGroups || ''}
                >
                  {numberOfAdGroupsDropdownItems().map(opt => (
                    <MenuItem value={opt.value} key={opt.value}>{opt.description}</MenuItem>
                  ))}
                </NeDropdown>
                <ErrorText
                  hasError={!!errors?.numberOfAdGroups}
                  errorText={errors?.numberOfAdGroups?.message}
                />
              </GridItem>
              <Grid item xs={12} sx={{ marginBottom: '8px' }}>
                {adGroupOptionsFields && (
                  <Grid item>
                    <Typography variant="h6">Ad Group Options</Typography>
                  </Grid>
                )}
                {Array.isArray(adGroupOptionsFields) && adGroupOptionsFields.map((adGroupOptionsField) => (
                  <GenericCard
                    key={adGroupOptionsField.sourceId}
                    labels={adGroupOptionsFieldsLabels}
                    data={adGroupOptionsField}
                    handleDelete={() => onDeleteAdGroupOptionsFields(adGroupOptionsField)}
                    onEdit={() => handleAdGroupOptionsSelection(adGroupOptionsField)}
                    onSelect={() => {
                    }}
                  />
                ))}
                <AdGroupOptionsFormDialog
                  open={adGroupOptionsFormDialogOpen}
                  onOpen={handleAdGroupOptionsFormDialogOpen}
                  onClose={handleAdGroupOptionsFormDialogClose}
                  updateAdGroupOptions={updateAdGroupOptions}
                  selectedAdGroupOptions={selectedAdGroupOptionsFields}
                  numberOfAdGroupsDropdownSelectedValue={numberOfAdGroupsDropdownSelectedValue}
                  adGroupOptionsFieldsSize={adGroupOptionsFields.length}
                />
              </Grid>
              <GridItem sizes={[12]}>
                <Typography>
                  {t('pages.youtube.advertising.helpText_1')}
                  <Link
                    href="https://docs.google.com/document/d/1ixZf0DveGDacRBnaRl3_dH5NJZs5Zy6TbvfeOKN7RwM/edit"
                    target="_blank" rel="noopener noreferrer" sx={{ marginLeft: '5px' }}
                  >
                    {t('pages.youtube.advertising.helpText_2')}
                  </Link>
                </Typography>
              </GridItem>
              <GridItem sizes={[12]}>
                <IQFormSelect
                  id="willClientProvidingClientList"
                  name="willClientProvidingClientList"
                  labelText={t('pages.youtube.advertising.willClientProvidingClientList')}
                  items={yesNoDropdownItems}
                  defaultValue=""
                  theme={IQTheme}
                  fontLabelWeight="600"
                  fullWidth
                  schema={schema}
                />
              </GridItem>
              <GridItem sizes={[12]}>
                <IQFormSelect
                  id="willClientProvidingCompanionBannerAd"
                  name="willClientProvidingCompanionBannerAd"
                  labelText={t('pages.youtube.advertising.willClientProvidingCompanionBannerAd')}
                  items={yesNoDropdownItems}
                  defaultValue=""
                  theme={IQTheme}
                  fontLabelWeight="600"
                  fullWidth
                  schema={schema}
                  onChange={handleWillClientProvidingCompanionBannerAdDropdownChange}
                />
              </GridItem>
              {willClientProvidingCompanionBannerAdSelectedValue === 'Yes' && (
                <GridItem sizes={[12]}>
                  <Typography variant="body1">
                    {t('pages.youtube.advertising.willClientProvidingCompanionBannerAdNote')}
                  </Typography>
                </GridItem>
              )}
              <GridItem sizes={[12]}>
                <IQFormTextArea
                  id="notesInstructions"
                  labelText={t('pages.youtube.advertising.notesInstructions')}
                  name="notesInstructions"
                  rowCount={2}
                  fullWidth
                  fontLabelWeight="600"
                  showError
                  required
                />
              </GridItem>
            </GridContainer>
          </Box>
        </FormProvider>
      </ValidationProvider>
      <CoNavigationConfirm
        showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
      />
    </DefaultPageLayout>
  );
}
