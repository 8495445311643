import React, { ReactNode, useEffect, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import {
  Checkbox, Chip, Stack, Card, CardContent, FormControlLabel,
  Typography, Divider, Collapse,
} from '@mui/material';
import { Theme } from '@emotion/react';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import CircleIcon from '@mui/icons-material/Circle';
import styled from '@emotion/styled';
import * as yup from 'yup';
import '../../styles/website_fonts.css';
import { useValidationContext } from '@gannettdigital/shared-react-components';
import schemaValidate from '../schemaValidate';

export interface ChatLocationCardProps {
  field: string;
  locationName: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
  zipcode: string;
  error?: boolean;
  locationStatus?: 'Complete' | 'Review' | string;
  required?: boolean;
  childern?: ReactNode;
  params?: any;
  [rest: string]: any;
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

type StyleProps = {
  theme?: Theme;
  error?: boolean;
  locationStatus?: string;
};

const LocationCard = styled(Card) <StyleProps>`
  padding: ${(props) => props.theme.spacing(1, 1, 0, 1)};
  border: 1px solid ${(props) =>
    (props.error ? props.theme.palette.error.main : props.theme.palette.action.disabledBackground)};
`;

const LocationCardContent = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
}));

const ChipStyled = styled(Chip)(() => ({
  height: '18px',
  fontSize: '12px',
  fontWeight: 600,
  padding: '1px',
}));

const ChipWrapper = styled(Stack)(({ theme, locationStatus }: StyleProps) => ({
  '.MuiChip-root > .MuiChip-icon': {
  // eslint-disable-next-line no-nested-ternary,max-len
    color: `${locationStatus === 'Complete' ? theme.palette.success.dark : (locationStatus === 'Review' ? theme.palette.error.main : '')}`,
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: theme.spacing(4),
  },
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const AddressWrapper = styled(Stack)(({ theme }) => ({
  flexBasis: '40%',
  [theme.breakpoints.down('sm')]: {
    marginLeft: theme.spacing(6),
    maxWidth: '300px',
    wordWrap: 'break-word',
  },
}));

const VerticalDivider = styled(Divider)(({ theme }) => ({
  height: '24px',
  width: '1px',
  color: `${theme.palette.action.disabledBackground}`,

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const HorizontalDivider = styled(Divider)(({ theme }) => ({
  color: `${theme.palette.action.disabledBackground}`,
  paddingTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

const SectionDivider = styled(Divider)(({ theme }) => ({
  color: `${theme.palette.action.disabledBackground}`,
}));

const StyledCircleIcon = styled(CircleIcon)(() => ({
  fontSize: '10px',
}));

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(-90deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),

  [theme.breakpoints.down('sm')]: {
    position: 'absolute',
    top: '30px',
    right: '30px',
  },
}));

const StatusIconMap = {
  Complete: {
    icon: <StyledCircleIcon />,
    name: 'COMPLETE',
  },
  Review: {
    icon: <StyledCircleIcon />,
    name: 'REVIEW',
  },
};

const ChatLocationCard = ({
  field,
  locationName,
  addressLine1,
  addressLine2,
  city,
  error,
  state,
  zipcode,
  locationStatus,
  required = false,
  childern,
  params,
  ...rest
}: ChatLocationCardProps) => {
  const { schema } = useValidationContext();
  const [expanded, setExpanded] = useState(false);
  const [customValidation, setCustomValidation] = useState(null);

  const {
    control,
    clearErrors,
  } = useFormContext();
  const {
    field: {
      onChange, name, value: fieldValue, ref,
    },
  } = useController({
    name: field,
    control,
    rules: {
      validate: (value) => schemaValidate(value, field, schema, params, customValidation),
    },
    defaultValue: rest?.defaultValue || false,
  });

  useEffect(() => {
    if (required) {
      setCustomValidation(yup.boolean().oneOf([true], `yup.${field}.required`));
    }

    clearErrors(field);
  }, [required]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleChange = (event) => {
    onChange(event);

    if (rest.onChange) {
      rest.onChange(event);
    }
  };

  return (
    <LocationCard error={error}>
      <LocationCardContent>
        <Stack direction="row" sx={{ flexBasis: '35%' }}>
          <FormControlLabel
            label=""
            control={(
              <Checkbox
                name={name}
                value={fieldValue}
                checked={fieldValue}
                inputRef={ref}
                onChange={handleChange}
              />
                        )}
          />
          <Stack>
            <Subtitle variant="body2">Location:</Subtitle>
            <Typography
              component="div"
              sx={{
                fontFamily: 'Unify Sans',
                fontSize: 16,
                lineHeight: '24px',
                fontWeight: 'bold',
              }}
            >
              {locationName}
            </Typography>
          </Stack>
        </Stack>
        <AddressWrapper>
          <HorizontalDivider orientation="horizontal" flexItem />
          <Subtitle variant="body2">Address:</Subtitle>
          <Typography variant="body1">{`${addressLine1}, ${addressLine2 || ''}`}</Typography>
          <Typography variant="body1">{`${city}, ${state} ${zipcode}`}</Typography>
          <HorizontalDivider orientation="horizontal" flexItem />
        </AddressWrapper>

        <ChipWrapper direction="row" spacing={2} locationStatus={locationStatus}
          sx={{ flexBasis: '20%', flexShrink: 0, flexGrow: 0 }}
        >
          {locationStatus
                        && (
                        <>
                          <VerticalDivider orientation="vertical" flexItem />
                          <ChipStyled label={StatusIconMap[locationStatus].name}
                            icon={StatusIconMap[locationStatus].icon} variant="outlined"
                          />
                        </>
                        )}
        </ChipWrapper>
        <Stack>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <KeyboardArrowRightIcon />
          </ExpandMore>
        </Stack>
      </LocationCardContent>
      {/* Negative margin mentioned to expand the divider to touch its edge of the container */}
      <SectionDivider sx={{ borderBottomWidth: '1px', marginLeft: '-5%', marginRight: '-5%' }} />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {childern}
      </Collapse>
    </LocationCard>
  );
};

export default ChatLocationCard;
