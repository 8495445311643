import i18n from 'i18n/i18n';

const { t } = i18n;

export function targetingTypeRadiusDistanceDropdownItems() {
  const options = [];
  for (let i = 10; i <= 100; i += 5) {
    options.push({ value: i, description: i });
  }
  return options;
}

export const targetingTypeDropdownItems = [
  { value: 'Radius', description: t('pages.xmo.solutionsStrategy.inputValues.radius') },
  { value: 'City', description: t('pages.xmo.solutionsStrategy.inputValues.city') },
  { value: 'DMA', description: t('pages.xmo.solutionsStrategy.inputValues.dma') },
  { value: 'National', description: t('pages.xmo.solutionsStrategy.inputValues.national') },
  { value: 'Zip/Postal Code', description: t('pages.xmo.solutionsStrategy.inputValues.zipPostalCode') },
];

export const minimumAgeDropdown = Array.from({ length: 47 }, (v, k) => k + 18)
.map(age => ({
      description: age.toString(),
      value: age.toString(),
    })
);

export const maximumAgeDropdown = Array.from({ length: 48 }, (v, k) => k + 18)
.map(age => ({
      description: age === 65 ? '65 plus' : age.toString(),
      value: age.toString(),
    })
);

export const genderDropdown = [
  { description: t('pages.xmo.dynamic.male'), value: "male"},
  { description: t('pages.xmo.dynamic.female'), value: "female"},
  { description: t('pages.xmo.dynamic.both'), value: "both"},
];