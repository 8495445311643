/* eslint-disable no-param-reassign */
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import { useTranslation } from 'react-i18next';
import {
  FormControlLabel, Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import React, {
  useEffect,
  useRef,
  useState
} from 'react';
import { Box } from '@mui/system';
import GridItem from 'components/grid/GridItem';
import GridContainer from 'components/grid/GridContainer';
import {
  IQFormInput, IQFormSelect, IQFormTextArea,
  IQTheme,
  ValidationProvider,
} from '@gannettdigital/shared-react-components';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import schemaValidate from 'components/schemaValidate';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import Autocomplete from '@mui/material/Autocomplete';
import useMapboxService from 'services/MapboxService';
import ErrorIcon from '@mui/icons-material/Error';
import schema from './XmoTargeting.schema';
import {
  genderDropdown,
  maximumAgeDropdown,
  minimumAgeDropdown,
  targetingTypeDropdownItems,
  targetingTypeRadiusDistanceDropdownItems,
} from './XmoTargetingService';
import { normalizeNewLines } from "shared/constants";
import CityTargetingDetails from "pages/generic-product/details/custom-fields/CityTargetingDetails";
import DmaTargetingDetails from "pages/generic-product/details/custom-fields/DmaTargetingDetails";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getOrderBusinessByOrderId, selectOrderBusiness } from "services/businessSlice";
import { getCountryCodeFromGmaid } from "shared/common";

interface Props {
  product: any;
  variant?: number;
  onSubmit: (data: any) => void;
  onBack: () => void;
}

export type DetailsFormProps = {
  zipCodeTargetingDetails: string;
  targetingType: string,
  targetingDetails: string,
  radiusOrKilometerDistance: string,
  salesforceBusinessAddress: string,
  areasToIncludeNotInTargetArea: string,
  areasToExcludeInTargetArea: string,
  addressLine1: string,
  addressLine2: string,
  city: string,
  stateOrProvinceOrRegion: string,
  zipOrPostalCode: string,
  country: string,
  targetAudienceDemographic?: string,
  minimumAge: string,
  maximumAge: string,
  gender: string,
  multimediaCreativeCid: string,
  notesAndInstructions: string,
};

export default function XmoTargetingPageForm({
  product, variant, onSubmit, onBack,
}: Props) {
  const { t } = useTranslation();
  const { orderId } = useParams();
  const dispatch = useDispatch();
  const { content } = useSelector(selectOrderBusiness);
  const [targetingType, setTargetingType] = useState<string>(product?.targetingType || '');
  const [salesforceBusinessAddressRadio, setSalesforceBusinessAddressRadio] = useState(
    product?.salesforceBusinessAddress || '',
  );
  const [cityDetails, setCityDetails] = useState<any>([]);
  const [dmaDetails, setDmaDetails] = useState<any>([]);
  const deBouncerRef = useRef<any>(null);

  const methods = useForm<DetailsFormProps>({
    mode: 'onChange',
    defaultValues: {
      targetingType: product?.targetingType || '',
      zipCodeTargetingDetails: product?.zipCodeTargetingDetails || '',
      targetingDetails: product?.targetingDetails || '',
      areasToIncludeNotInTargetArea: normalizeNewLines(product?.areasToIncludeNotInTargetArea) || '',
      areasToExcludeInTargetArea: normalizeNewLines(product?.areasToExcludeInTargetArea) || '',
      radiusOrKilometerDistance: product?.radiusOrKilometerDistance || '',
      salesforceBusinessAddress: product?.salesforceBusinessAddress || '',
      addressLine1: product?.addressLine1 || '',
      addressLine2: product?.addressLine2 || '',
      city: product?.city || '',
      stateOrProvinceOrRegion: product?.stateOrProvinceOrRegion || '',
      zipOrPostalCode: product?.zipOrPostalCode || '',
      country: product?.country || '',
      targetAudienceDemographic: normalizeNewLines(product?.targetAudienceDemographic) || '',
      minimumAge: product?.minimumAge || '',
      maximumAge: product?.maximumAge === '65 plus' ? '65' : product?.maximumAge || '',
      gender: product?.gender || '',
      multimediaCreativeCid: product?.multimediaCreativeCid || '',
      notesAndInstructions: normalizeNewLines(product?.notesAndInstructions) || ''
    },
  });

  const handleFormSubmit = (data: DetailsFormProps) => {
    if (!['Radius', 'City', 'DMA'].includes(targetingType)) {
      data.targetingDetails = '';
    }
    if (targetingType !== 'Radius') {
      data.salesforceBusinessAddress = '';
      data.radiusOrKilometerDistance = '';
      data.addressLine1 = '';
      data.addressLine2 = '';
      data.city = '';
      data.stateOrProvinceOrRegion = '';
      data.zipOrPostalCode = '';
      data.country = '';
    }
    if (targetingType === 'Radius' && salesforceBusinessAddressRadio === 'Yes') {
      data.addressLine1 = '';
      data.addressLine2 = '';
      data.city = '';
      data.stateOrProvinceOrRegion = '';
      data.zipOrPostalCode = '';
      data.country = '';
    }
    if (targetingType !== 'Zip/Postal Code'){
      data.zipCodeTargetingDetails = '';
    }

    data.maximumAge = data?.maximumAge === "65" ? '65 plus' : data?.maximumAge;

    const pageFormData = {
      solutionStrategy: {
        ...product, ...data,
        cityTargetingDetails:  targetingType === 'City'? cityDetails : [],
        dmaTargetingDetails: data?.targetingType === 'DMA'? dmaDetails : [],
      },
    };
    onSubmit(pageFormData);
  };

  const {
    setValue, watch, control, handleSubmit, register,
    formState: {
      isValid,
      isDirty,
      isSubmitSuccessful,
      isSubmitting,
    },
  } = methods;

  const watchedValues = watch(['addressLine1', 'city', 'stateOrProvinceOrRegion', 'zipOrPostalCode', 'country']);

  const [inputValue, setInputValue] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isTouched, setIsTouched] = useState(false);
  const [addressData, setAddressData] = useState([]);
  const [selectedValue, setSelectedValue] = useState<any>(product?.addressLine1 ? {
    label: product?.addressLine1,
    value: '',
  } : null);
  const [placeDetails, setPlaceDetails] = useState<any>(null);
  const [isAddressFieldsEmpty, setIsAddressFieldsEmpty] = useState<any>(false);
  const [ageError, setAgeError] = useState<boolean>(false);
  const { getServiceAreas, getPlaceDetails } = useMapboxService();

  const minimumAge = watch('minimumAge');
  const maximumAge = watch('maximumAge');

  useEffect(() => {
    const minAge = parseInt(minimumAge);
    const maxAge = parseInt(maximumAge);

    if (minAge <= 0 || maxAge <= 0 || minAge > maxAge) {
      setAgeError(true);
    } else {
      setAgeError(false);
    }
  }, [minimumAge, maximumAge]);

  useEffect(() => {
    const getPlaceDetailsApiCall = async (placeId: any) => {
      const data = await getPlaceDetails(placeId);
      setPlaceDetails(data.data);
    };
    if (selectedValue && selectedValue.value !== '') {
      getPlaceDetailsApiCall(selectedValue.value);
    }
  }, [selectedValue]);

  useEffect(() => {
    if (product?.cityTargetingDetails) setCityDetails(product?.cityTargetingDetails);
    if (product?.dmaTargetingDetails) setDmaDetails(product?.dmaTargetingDetails);
  }, [product]);

  useEffect(() => {
    dispatch(getOrderBusinessByOrderId(orderId));
  }, [orderId]);

  const getSuggestions = async (value: any) => {
    const countryCode = getCountryCodeFromGmaid(content?.gmaid);
    try {
      const data = await getServiceAreas(value, countryCode);
      setAddressData(data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleInputChange = (e: any) => {
    if (deBouncerRef.current) {
      clearTimeout(deBouncerRef.current);
    }
    deBouncerRef.current = setTimeout(() => {
      getSuggestions(e);
    }, 1500);
  };

  useEffect(() => {
    if (placeDetails) {
      setValue('addressLine2', placeDetails.addressLine2 || '');
      setValue('city', placeDetails.city || '');
      setValue('stateOrProvinceOrRegion', placeDetails.state || '');
      setValue('zipOrPostalCode', placeDetails.zipCode || '');
      setValue('country', placeDetails.country === 'US' ? 'United States' : placeDetails.country || '');
    }
  }, [placeDetails]);

  useEffect(() => {
    if (salesforceBusinessAddressRadio === 'No' && watchedValues.includes('')) {
      setIsAddressFieldsEmpty(true);
    } else {
      setIsAddressFieldsEmpty(false);
    }
  }, [watchedValues]);

  return (
    <DefaultPageLayout
      disableContinue={!isValid || isAddressFieldsEmpty || ageError}
      onBack={onBack}
      header={t('pages.xmo.solutionsStrategy.targeting.targetingTitle')}
      onContinue={handleSubmit(handleFormSubmit)}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <Box
            id="mainForm"
            component="form"
            sx={{ display: 'flex' }}
            onSubmit={() => {
              handleSubmit(handleFormSubmit);
            }}
          >
            <GridContainer fullWidth>
              <GridItem sizes={[12]}>
                <Typography
                  variant="h6"
                >
                  {t('pages.xmo.solutionsStrategy.targeting.typeTitle')}
                </Typography>
              </GridItem>
              <GridItem sizes={[12]}>
                <IQFormSelect
                  id="targetingType"
                  name="targetingType"
                  labelText={t('pages.xmo.solutionsStrategy.targeting.targetingType')}
                  items={targetingTypeDropdownItems}
                  defaultValue=""
                  theme={IQTheme}
                  fontLabelWeight="600"
                  required
                  fullWidth
                  showError
                  onChange={(e: any) => setTargetingType(e.target.value)}
                  schema={schema}
                />
              </GridItem>
              {
                  targetingType === "Radius" && (
                      <GridItem sizes={[12]}>
                        <IQFormInput
                            schema={schema}
                            theme={IQTheme}
                            fontLabelWeight="600"
                            id="targetingDetails"
                            name="targetingDetails"
                            fullWidth
                            showError
                            labelText={t('pages.xmo.solutionsStrategy.targeting.targetingDetails')}
                        />
                      </GridItem>
                  )
              }
              {
                  targetingType === "Zip/Postal Code" && (
                      <GridItem sizes={[12]}>
                        <IQFormInput
                            schema={schema}
                            theme={IQTheme}
                            fontLabelWeight="600"
                            id="zipCodeTargetingDetails"
                            name="zipCodeTargetingDetails"
                            fullWidth
                            showError
                            labelText={t("pages.socialAds.solutionStrategy.targeting.zipCodeTargetingDetails")}
                            subDescription={t("pages.socialAds.solutionStrategy.targeting.zipCodeTargetingDetailsHelperText")}
                        />
                      </GridItem>
                  )
              }
              {
                  targetingType === "City" && (
                      <GridItem sizes={[12]} marginTop={-3}>
                        <CityTargetingDetails cityDetails={cityDetails}
                                              setCityDetails={setCityDetails}
                                              nonSchema={true}
                        />
                      </GridItem>
                  )
              }
              {
                  targetingType === "DMA" && (
                      <GridItem sizes={[12]} marginTop={-3}>
                        <DmaTargetingDetails dmaDetails={dmaDetails}
                                             setDmaDetails={setDmaDetails}
                                             nonSchema={true}
                        />
                      </GridItem>
                  )
              }
              {
                    targetingType === 'Radius'
                    && (
                    <>
                      <GridItem sizes={[12]}>
                        <IQFormSelect
                          id="radiusOrKilometerDistance"
                          name="radiusOrKilometerDistance"
                          labelText={t('pages.xmo.solutionsStrategy.targeting.radiusOrKilometerDistance')}
                          items={targetingTypeRadiusDistanceDropdownItems()}
                          defaultValue=""
                          theme={IQTheme}
                          fontLabelWeight="600"
                          required
                          fullWidth
                          showError
                          schema={schema}
                        />
                      </GridItem>
                      <GridItem sizes={[12]}>
                        <Typography
                          fontWeight={600}
                          variant="subtitle1"
                        >
                          {t('pages.xmo.solutionsStrategy.targeting.salesforceBusinessAddress')}
                          <RadioGroup
                            id="salesforceBusinessAddress"
                            name="salesforceBusinessAddress"
                            defaultValue={watch('salesforceBusinessAddress') || ''}
                            onChange={(e: any) => setSalesforceBusinessAddressRadio(e.target.value)}
                            row
                          >
                            {[t('pages.xmo.solutionsStrategy.inputValues.yesInputValue'),
                              t('pages.xmo.solutionsStrategy.inputValues.noInputValue')].map(opt => (
                                <FormControlLabel
                                  key={opt}
                                  control={<Radio value={opt} />}
                                  label={opt}
                                  {...register('salesforceBusinessAddress', {
                                    validate: (value) => schemaValidate(value, 'salesforceBusinessAddress', schema),
                                  })}
                                />
                            ))}
                          </RadioGroup>
                        </Typography>
                      </GridItem>
                      {
                          salesforceBusinessAddressRadio === t('pages.xmo.solutionsStrategy.inputValues.noInputValue')
                          && (
                          <>
                            <GridItem sizes={[12]}>
                              <Typography
                                variant="h6"
                              >
                                {t('pages.xmo.solutionsStrategy.targeting.newBusinessAddressTitle')}
                              </Typography>
                            </GridItem>
                            <GridItem sizes={[12]}>
                              <Typography
                                fontWeight={600}
                                variant="subtitle1"
                                mb={-1}
                              >
                                {t('pages.xmo.solutionsStrategy.targeting.addressLine1')}
                              </Typography>
                            </GridItem>
                            <GridItem sizes={[12]}>
                              <Controller
                                name="addressLine1"
                                control={control}
                                render={({ field: { onChange } }) => (
                                  <Autocomplete
                                    value={selectedValue}
                                    disablePortal
                                    id="addressLine1"
                                    options={addressData}
                                    getOptionLabel={(option) => option.label || ''}
                                    sx={{ width: '100%' }}
                                    inputValue={inputValue}
                                    onInputChange={(event, newInputValue) => {
                                      setInputValue(newInputValue);
                                      setIsTouched(true);
                                      handleInputChange(newInputValue);
                                    }}
                                    onChange={(event, newValue) => {
                                      setInputValue(newValue ? newValue.label : '');
                                      onChange(newValue ? newValue.label : '');
                                      setSelectedValue(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                  />
                                )}
                              />
                            </GridItem>
                            <GridItem sizes={[12]}>
                              <IQFormInput
                                schema={schema}
                                theme={IQTheme}
                                fontLabelWeight="600"
                                id="addressLine2"
                                name="addressLine2"
                                fullWidth
                                showError
                                labelText={t('pages.xmo.solutionsStrategy.targeting.addressLine2')}
                              />
                            </GridItem>
                            <GridItem sizes={[6, 6]} columnSpacing={2}>
                              <IQFormInput
                                schema={schema}
                                theme={IQTheme}
                                fontLabelWeight="600"
                                id="city"
                                name="city"
                                fullWidth
                                showError
                                labelText={t('pages.xmo.solutionsStrategy.targeting.city')}
                              />
                              <IQFormInput
                                schema={schema}
                                theme={IQTheme}
                                fontLabelWeight="600"
                                id="stateOrProvinceOrRegion"
                                name="stateOrProvinceOrRegion"
                                fullWidth
                                showError
                                labelText={t('pages.xmo.solutionsStrategy.targeting.stateOrProvinceOrRegion')}
                              />
                            </GridItem>
                            <GridItem sizes={[6, 6]} columnSpacing={2}>
                              <IQFormInput
                                schema={schema}
                                theme={IQTheme}
                                fontLabelWeight="600"
                                id="zipOrPostalCode"
                                name="zipOrPostalCode"
                                fullWidth
                                showError
                                labelText={t('pages.xmo.solutionsStrategy.targeting.zipOrPostalCode')}
                              />
                              <IQFormInput
                                schema={schema}
                                theme={IQTheme}
                                fontLabelWeight="600"
                                id="country"
                                name="country"
                                fullWidth
                                showError
                                labelText={t('pages.xmo.solutionsStrategy.targeting.country')}
                              />
                            </GridItem>
                            {isAddressFieldsEmpty
                                && (
                                <Grid container>
                                  <Grid item xs={12}>
                                    <Typography
                                      sx={{
                                        color: '#C20F1E',
                                        fontSize: '14px',
                                        marginTop: '-10px',
                                        marginBottom: '15px',
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                      variant="subtitle1"
                                    >
                                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <ErrorIcon
                                          sx={{
                                            color: '#C20F1E',
                                            fontSize: '16px',
                                            marginRight: '5px',
                                          }}
                                        />
                                        {t('pages.xmo.solutionsStrategy.targeting.genericAddressErrorMessage')}
                                      </Box>
                                    </Typography>
                                  </Grid>
                                </Grid>
                                )}
                          </>
                          )
                      }
                    </>
                    )
                }
              <GridItem sizes={[12]}>
                <Typography
                  variant="h6"
                >
                  {t('pages.xmo.solutionsStrategy.targeting.areasTitle')}
                </Typography>
              </GridItem>
              <GridItem sizes={[12]}>
                <IQFormTextArea
                  fontLabelWeight="600"
                  id="areasToIncludeNotInTargetArea"
                  name="areasToIncludeNotInTargetArea"
                  fullWidth
                  showError
                  labelText={t('pages.xmo.solutionsStrategy.targeting.areasToIncludeNotInTargetArea')}
                />
              </GridItem>
              <GridItem sizes={[12]}>
                <IQFormTextArea
                  fontLabelWeight="600"
                  id="areasToExcludeInTargetArea"
                  name="areasToExcludeInTargetArea"
                  fullWidth
                  showError
                  labelText={t('pages.xmo.solutionsStrategy.targeting.areasToExcludeInTargetArea')}
                  subDescription={variant === 3 ? t('pages.xmo.solutionsStrategy.targeting.exclusionsAreNotAvailable'): ""}
                />
              </GridItem>
              {variant === 3 && (
              <>
                <GridItem sizes={[12]}>
                  <Typography
                    variant="h6"
                  >
                    {t('pages.xmo.solutionsStrategy.targeting.demographicTitle')}
                  </Typography>
                </GridItem>
                <GridItem sizes={[12]}>
                  <IQFormTextArea
                    fontLabelWeight="600"
                    id="targetAudienceDemographic"
                    name="targetAudienceDemographic"
                    fullWidth
                    tooltipText={t('pages.xmo.solutionsStrategy.targeting.demographicTooltip')}
                    tooltipPlacement="top"
                    tooltipPaddingBottom={16}
                    labelText={t('pages.xmo.solutionsStrategy.targeting.demographicLabel')}
                  />
                </GridItem>
              <GridItem sizes={[12]}>
                <IQFormSelect
                    id="minimumAge"
                    name="minimumAge"
                    labelText={t('pages.xmo.solutionsStrategy.targeting.minimumAge')}
                    items={minimumAgeDropdown}
                    defaultValue=""
                    theme={IQTheme}
                    fontLabelWeight="600"
                    fullWidth
                    required
                    schema={schema}
                />
              </GridItem>
              <GridItem sizes={[12]}>
              <IQFormSelect
                  id="maximumAge"
                  name="maximumAge"
                  labelText={t('pages.xmo.solutionsStrategy.targeting.maximumAge')}
                  items={maximumAgeDropdown}
                  defaultValue=""
                  theme={IQTheme}
                  fontLabelWeight="600"
                  fullWidth
                  required
                  schema={schema}
              />
                <Typography sx={{color: '#C20F1E', fontSize: '14px'}} variant="subtitle1">
                  {ageError && <ErrorIcon sx={{
                    fontSize: '16px',
                  }}/>} {ageError ? t('pages.xmo.dynamic.maximumAgeError') : ''}
                </Typography>
              </GridItem>
              <GridItem sizes={[12]}>
                <IQFormSelect
                    id="gender"
                    name="gender"
                    labelText={t('pages.xmo.solutionsStrategy.targeting.gender')}
                    items={genderDropdown}
                    defaultValue=""
                    theme={IQTheme}
                    fontLabelWeight="600"
                    fullWidth
                    required
                    schema={schema}
                />
              </GridItem>
              <GridItem sizes={[12]}>
                <IQFormInput
                    schema={schema}
                    theme={IQTheme}
                    fontLabelWeight="600"
                    id="multimediaCreativeCid"
                    name="multimediaCreativeCid"
                    fullWidth
                    showError
                    labelText={t('pages.xmo.solutionsStrategy.targeting.multimediaCreativeCid')}
                />
              </GridItem>
              <GridItem sizes={[12]}>
                <IQFormTextArea
                    fontLabelWeight="600"
                    id="notesAndInstructions"
                    name="notesAndInstructions"
                    fullWidth
                    showError
                    labelText={t('pages.xmo.solutionsStrategy.targeting.notesAndInstructions')}
                />
              </GridItem>
              </>
            )}
            </GridContainer>
          </Box>
          <CoNavigationConfirm
            showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
          />
        </FormProvider>
      </ValidationProvider>
    </DefaultPageLayout>
  );
}
