import * as yup from 'yup';
import i18n from 'i18n/i18n';

const { t } = i18n;

const today = new Date();
today.setDate(today.getDate() - 1);

const schema = {
  targetingType: yup.string().required(t('pages.youtube.targeting.targetingTypeErrorMsg')).nullable(),
  youtubeTactics: yup.string().required(t('pages.youtube.targeting.youtubeTacticsErrorMsg')).nullable(),
  isCampaignFlighted: yup.string().required(t('pages.youtube.targeting.isCampaignFlightedErrorMsg')).nullable(),
  startDate: yup.date()
    .typeError('Please select or enter a valid date format: MM/DD/YYYY.')
    .min(today, 'Please select or enter a valid date as of today or a future date. ')
    .required(t('pages.youtube.targeting.startDateErrorMessage'))
    .nullable(),
  endDate: yup.date()
    .typeError('Please select or enter a valid date format: MM/DD/YYYY.')
    .min(today, 'Please select or enter a valid date as of today or a future date. ')
    .required(t('pages.youtube.targeting.endDateErrorMessage'))
    .nullable(),
  distance: yup.string().notRequired(),
  addressToUse: yup.string().notRequired(),
  additionalTargetingDetails: yup.string().notRequired(),
  areasToInclude: yup.string().notRequired(),
  areasToExclude: yup.string().notRequired(),
};

export default {
  yupValidations: schema,
  customValidations: {},
};
