/* eslint-disable react/no-array-index-key */
import { Typography, Box, Grid } from '@mui/material';
import GridItem from 'components/grid/GridItem';
import React, { useEffect, useState } from 'react';
import { useAppDispatch } from 'services/redux/store';
import { useParams } from 'react-router';
import { getProduct } from 'services/productsSlice';
import { displayNotRequiredField, editButton } from 'pages/overview/Overview';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import OrderTracker from 'pages/orders/OrderTracker';
import { xmoVariantsConstants } from 'pages/xmo/xmoUtils';
import {
  MenuName,
  urlNavigationFromOverViewToProductPage,
  urlNavigationFromOverViewToProductPagePmm,
  Urls,
} from 'navigation/Urls';
import { headingStyle } from 'pages/overview/sections/Business/BusinessSection';
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { camelCaseToFlat } from 'pages/shopping-cart/ShoppingCart';
import { displayAssets } from 'pages/overview/sections/sites/SitesSection';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectOrderItems } from 'services/ordersSlice';
import { xmoUpgradeVariantsConstants } from 'pages/xmo/xmoUpgradeUtils';
import { pmmVariantsConstants } from 'pages/xmo/pmmUtils';
import {
  GeneralQuestionsSection,
} from 'pages/overview/sections/generalQuestions/GeneralQuestionsSection';
import { fieldsOrder } from 'pages/xmo/solutionStrategy/brandAmplifier/XmoBrandAmplifierConstants';
import XmoBrandAmplifierSection from 'pages/overview/sections/xmo/XmoBrandAmplifierSection';
import { normalizeNewLines } from 'shared/constants';
import { TypeOfSaleConstants } from 'pages/shopping-cart/Constants';
import { ActionItems } from '../common/ActionItems';

export default function XmoSection({
  product, isEditable, showTracker, setShowCIDModal,
}: any) {
  const { t } = useTranslation();
  const appDispatch = useAppDispatch();
  const orderItems = useSelector(selectOrderItems);
  const navigate = useNavigationHandler();
  const { orderId } = useParams();
  const [xmoData, setXmoData] = useState({} as any);
  const [sections, setSections] = useState([]);
  const offeringType = product.offeringType === 'ProductsData' ? product.pdOfferingType : product.offeringType;
  const isRebuildItem = product.saleType === TypeOfSaleConstants.REBUILD;
  const status = product?.trackerStatus;

  const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
  }));

  const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square
      {...props}
    />
  ))(() => ({
    backgroundColor: 'transparent',
  }));

  function convertStringToJsonKeyFormat(str: string): string {
    return str
      .toLowerCase()
      .replace(/[^a-z0-9\s]/g, '')
      .split(' ')
      .map((word, index) =>
        (index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)))
      .join('');
  }

  const getProductData = (orderItemId: any) => {
    appDispatch(getProduct({
      orderItemId,
    })).then((response) => setXmoData(response.payload));
  };

  useEffect(() => {
    if (product) {
      getProductData(product?.id);
    }
  }, [product, orderId]);

  const renderObjectDetailFields = (data: any, isFirstLevel = true) => {
    let filteredXmoData: any;
    if (isFirstLevel && Array.isArray(data)) {
      filteredXmoData = data && data.length > 0 && data[0];
    } else {
      filteredXmoData = data;
    }

    const objectDetails: any[] = [];
    const arrayOfObjectDetails: any[] = [];
    const arrayOfArraysDetails: any[] = [];
    const plainArrayDetails: any[] = [];
    const stringFieldDetails: any[] = [];

    if (filteredXmoData) {
      Object.entries(filteredXmoData).forEach(([key, value]) => {
        if (key === 'adGroupFields') {
          arrayOfObjectDetails.push({ key, value });
        } else if (typeof value === 'string' || typeof value === 'number') {
          stringFieldDetails.push({ key, value });
        } else if (Array.isArray(value)) {
          if (value.length > 0 && typeof value[0] === 'object' && !Array.isArray(value[0])) {
            arrayOfObjectDetails.push({ key, value });
          } else if (value.every(item => Array.isArray(item)
                && item.every(innerItem => typeof innerItem !== 'object'))) {
            arrayOfArraysDetails.push({ key, value });
          } else {
            plainArrayDetails.push({ key, value });
          }
        } else if (typeof value === 'object') {
          objectDetails.push({ key, value });
        }
      });
    }

    return (
      filteredXmoData
        ? (
          <>
            <Grid container>
              {/* Render String Field Details */}
              {stringFieldDetails.map(({ key, value }, index, productList) => {
                if (key !== 'sourceId' && !key.includes('uploader_')
                          && !(key === 'primaryAdGroupFocus' || key === 'primaryAdGroupLandingPage')) {
                  const formattedKey = camelCaseToFlat(key);
                  return (
                    <Grid item xs={productList.length === 1 ? 12 : 6} key={key}>
                      <Box key={key} mb={2} mr={2}>
                        <Typography sx={headingStyle}
                          color="primary"
                        >
                          {formattedKey}
                        </Typography>
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {(typeof value === 'boolean')
                          ? (value ? 'Yes' : 'No')
                          : displayNotRequiredField(
                            formattedKey,
                            typeof value === 'string' ? normalizeNewLines(value) : value,
                          )}
                      </Box>
                    </Grid>
                  );
                }
                return null;
              })}
            </Grid>
            {/* Render plain arrays details */}
            <Grid container>
              {plainArrayDetails.map(({ key, value }, index) => (
                <Grid item xs={6} key={key}>
                  <Box key={index} mb={2}>
                    <Typography sx={headingStyle} color="primary">
                      {camelCaseToFlat(key).toUpperCase()}
                    </Typography>
                    <Typography>
                      {value.join(', ')}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
            {/* Render an array of arrays details */}
            {arrayOfArraysDetails.map(({ key, value }, index) => (
              <Box key={index} mb={2}>
                <Typography sx={headingStyle}
                  color="primary"
                >
                  {camelCaseToFlat(key).toUpperCase()}
                </Typography>
                <Typography>
                  {value.length > 0
                    ? value.map((subArray: any) => subArray.join(', '))
                    : displayNotRequiredField(camelCaseToFlat(key), '')}
                </Typography>
              </Box>
            ))}
            {/* Render object details */}
            {objectDetails.map(({ key, value }) => (
              <Box mb={2} key={key}>
                <GridItem sizes={[3, 9]} marginBottom={0} sx={{
                  padding: isFirstLevel ? '20px 0 0' : '0',
                  borderTop: isFirstLevel ? '0px solid black' : '0',
                }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {renderObjectDetailFields(value, false)}
                  </Box>
                </GridItem>
              </Box>
            ))}
            {/* Render array of objects details */}
            {arrayOfObjectDetails.map(({ key, value }, index) => {
              if (key === 'cityTargetingDetails') {
                return (
                  <Grid item xs={6} key={key}>
                    <Box key={key} mb={2} mr={2}>
                      <Typography sx={headingStyle}
                        color="primary"
                      >
                        {camelCaseToFlat(key)}
                      </Typography>
                      {/* eslint-disable-next-line no-nested-ternary */}
                      {value === '' || value?.length === 0
                        ? displayNotRequiredField(camelCaseToFlat(key), '')
                        : value?.length > 0
                            && value?.map((item: any) =>
                              `${item?.city}, ${item?.state}`).join('; ')}
                    </Box>
                  </Grid>
                );
              }
              if (key === 'dmaTargetingDetails') {
                return (
                  <Grid item xs={6} key={key}>
                    <Box key={key} mb={2} mr={2}>
                      <Typography sx={headingStyle}
                        color="primary"
                      >
                        {camelCaseToFlat(key)}
                      </Typography>
                      {/* eslint-disable-next-line no-nested-ternary */}
                      {value === '' || value?.length === 0
                        ? displayNotRequiredField(camelCaseToFlat(key), '')
                        : value?.length > 0
                            && value?.map((item: any) =>
                              `${item?.name}, ${item?.provinceAbbreviation}`).join('; ')}
                    </Box>
                  </Grid>
                );
              }
              if (key.includes('uploader_')) {
                const formattedKey = camelCaseToFlat(key.replace('uploader_', ''));
                return (
                  <Grid item xs={6} key={key}>
                    <Box key={key} mb={2}>
                      <Typography sx={headingStyle} color="primary">{formattedKey}</Typography>
                      {displayAssets(filteredXmoData[key], 'Uploaded File')}
                    </Box>
                  </Grid>
                );
              }
              return (
                <Box key={index} mb={2}>
                  <Typography sx={headingStyle}
                    color="primary"
                  >
                    {camelCaseToFlat(key).toUpperCase()}
                  </Typography>
                  {key === 'adGroupFields' && (
                  <Grid container mt={2} mx={3}>
                    {stringFieldDetails.map((item) => {
                      if (item.key === 'primaryAdGroupFocus' || item.key === 'primaryAdGroupLandingPage') {
                        return (
                          <Grid item xs={6} key={item.key}>
                            <Box key={item.key} mb={2} mr={2}>
                              <Typography sx={headingStyle}
                                color="primary"
                              >
                                {camelCaseToFlat(item.key)}
                              </Typography>
                              {displayNotRequiredField(camelCaseToFlat(item.key), item.value)}
                            </Box>
                          </Grid>
                        );
                      }
                    })}
                  </Grid>
                  )}
                  <Box sx={{ paddingLeft: '15px' }}>
                    {value && value.map((item, idx, valueArr) => (
                      <Accordion key={idx} defaultExpanded>
                        <AccordionSummary aria-controls={`panel${index}-${idx}-content`}
                          id={`panel${index}-${idx}-header`}
                        >
                          <Typography>
                            {`${camelCaseToFlat(key)} `}
                            {valueArr.length !== 1 && idx + 1}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box sx={{ paddingLeft: '15px' }}>
                            {renderObjectDetailFields(item, false)}
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </Box>
                </Box>
              );
            })}
          </>
        )
        : ( // If Data is not available
          <Box mb={2}>
            <Typography sx={headingStyle}
              color="primary"
            >
              {camelCaseToFlat(t('pages.xmo.overview.noDataAvailable')).toUpperCase()}
            </Typography>
          </Box>
        )
    );
  };

  const orderObjectByArray = (obj, orderArray) => {
    const orderedObj = {};

    orderArray.forEach((key) => {
      if (obj && Object.prototype.hasOwnProperty.call(obj, key)) {
        orderedObj[key] = obj[key];
      }
    });

    return orderedObj;
  };

  const renderXmoOverPageDetails = (sectionName: any, index: number, variant: number) => {
    const sectionNameKey = convertStringToJsonKeyFormat(sectionName);
    let filteredXmoData = {};
    let navigateUrlKey = {};
    if (xmoData?.data) {
      filteredXmoData = (sectionNameKey.toLowerCase().includes('solutionstrategy'))
        ? xmoData?.data.solutionStrategy
        : xmoData?.data[sectionNameKey];
      if (orderItems?.content[0]?.pdOfferingType === 'PromotionsMediaMix') {
        navigateUrlKey = sectionNameKey.toLowerCase().includes('solutionstrategy')
          ? urlNavigationFromOverViewToProductPagePmm.solutionStrategy
          : urlNavigationFromOverViewToProductPagePmm[sectionNameKey];
      } else if (variant === 3) {
        navigateUrlKey = sectionNameKey.toLowerCase().includes('solutionstrategy')
          ? urlNavigationFromOverViewToProductPage.solutionStrategyBa
          : urlNavigationFromOverViewToProductPage[sectionNameKey];
      } else {
        navigateUrlKey = sectionNameKey.toLowerCase().includes('solutionstrategy')
          ? urlNavigationFromOverViewToProductPage.solutionStrategy
          : urlNavigationFromOverViewToProductPage[sectionNameKey];
      }
    }

    if (sectionName === MenuName[Urls.XmoBaTactics] && xmoData?.data) {
      navigateUrlKey = Urls.XmoBaTactics;
      filteredXmoData = xmoData?.data.solutionStrategy;

      const orderedFields = orderObjectByArray(filteredXmoData, fieldsOrder);
      filteredXmoData = { ...orderedFields };
    }

    return (
      <GridItem sizes={[3, 9]} marginBottom={0}
        sx={{ padding: '20px 0 0', borderTop: index > 0 && '1px solid black' }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {editButton(isEditable, () => navigate.to(
            navigateUrlKey,
            product.id,
          ))}
          <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>{sectionName}</Typography>
        </Box>
        <Box sx={{ marginTop: '15px' }} mb={5}>
          {/* Render Details */}
          {renderObjectDetailFields(filteredXmoData, true)}
        </Box>
      </GridItem>
    );
  };

  useEffect(() => {
    const variant = xmoData?.data?.variant;
    if (variant) {
      if (orderItems?.content[0]?.pdOfferingType === 'XMOUpgrade') {
        const sectionNames = xmoUpgradeVariantsConstants[variant]?.items.map((url: any) =>
          MenuName[url] || xmoUpgradeVariantsConstants[variant]?.label);
        setSections(sectionNames.map((name: any, index: number) => renderXmoOverPageDetails(name, index, variant)));
      } else {
        const sectionNames = xmoVariantsConstants[variant]?.items
          .map((url: any) => MenuName[url] || xmoVariantsConstants[variant]?.label)
          .filter((name: string) => (name !== 'General Questions'));
        setSections(sectionNames.map((name: any, index: number) => renderXmoOverPageDetails(name, index, variant)));
      }
    } else if (orderItems?.content[0]?.pdOfferingType === 'PromotionsMediaMix') {
      const sectionNames = pmmVariantsConstants?.items.map((url: any) =>
        MenuName[url] || pmmVariantsConstants?.label).filter((name: string) => name !== 'General Questions');
      setSections(sectionNames.map((name: any, index: number) => renderXmoOverPageDetails(name, index, variant)));
    }
  }, [xmoData]);

  const renderFormFieldsData = () => {
    if (xmoData?.data?.variant === 3) {
      return (
        <XmoBrandAmplifierSection productData={xmoData?.data}
          isEditable={isEditable} productId={product.id}
          offerId={product.offeringId}
        />
      );
    }
    return sections.length > 0 && sections;
  };

  return (
    <Grid container sx={{ borderTop: '1px solid #716F6F' }}>
      {/* Status Tracker */}
      {showTracker && (
        <GridItem sizes={[3, 9]} marginBottom={4} sx={{
          padding: '40px 0 0', borderBottom: '1px solid #000000',
        }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {editButton(isEditable, () => {
            })}
            <Typography sx={{
              fontSize: '16px',
              fontWeight: '600',
            }}
            >
              {t('pages.xmo.overview.orderStatus')}
            </Typography>
          </Box>
          <Box>
            <OrderTracker
              key={product?.id}
              status={status}
              productName={product?.pdOfferingType}
              product={product}
            />
          </Box>
        </GridItem>
      )}
      {/* General Questions */}
      {GeneralQuestionsSection(offeringType, product.offeringId, product.id, navigate, isEditable)}

      {/* Dynamic Sections */}
      {!isRebuildItem && renderFormFieldsData()}

      {/* Actions items */}
      {showTracker && ActionItems(false, product?.id, null, null, false, () => {
      }, setShowCIDModal)}
    </Grid>
  );
}
