export const howCampaignShouldBeTargetedDropdownItems = [
  { value: 'Use my selections', description: 'Use my selections' },
  { value: 'Use my selections & optimize it', description: 'Use my selections & optimize it' },
  { value: 'Optimize it', description: 'Optimize it' },
];

export const youtubeSelectTargetingOptions = [
  { value: 'Beauty, Fashion & Lifestyle', description: 'Beauty, Fashion & Lifestyle' },
  { value: 'Cars, Trucks & Racing', description: 'Cars, Trucks & Racing' },
  { value: 'Comedy', description: 'Comedy' },
  { value: 'Entertainment & Pop Culture', description: 'Entertainment & Pop Culture' },
  { value: 'Families with Kids: Top 100', description: 'Families with Kids: Top 100' },
  { value: 'Food & Recipes (Romania)', description: 'Food & Recipes (Romania)' },
  { value: 'Food & Recipes', description: 'Food & Recipes' },
  { value: 'Gen Z Content', description: 'Gen Z Content' },
  { value: 'Gen Z Music', description: 'Gen Z Music' },
  { value: 'Kids & Family CTV', description: 'Kids & Family CTV' },
  { value: 'MLB Lineup - Sports Lineup', description: 'MLB Lineup - Sports Lineup' },
  { value: 'Music (Romania)', description: 'Music (Romania)' },
  { value: 'Music', description: 'Music' },
  { value: 'Music: Released', description: 'Music: Released' },
  { value: 'Music: Top 100 Charting Artists', description: 'Music: Top 100 Charting Artists' },
  { value: 'Music: Top 100 Charting Latin Artists', description: 'Music: Top 100 Charting Latin Artists' },
  { value: 'Music: Trending Music', description: 'Music: Trending Music' },
  { value: 'NBA Lineup - Sports Lineup', description: 'NBA Lineup - Sports Lineup' },
  { value: 'News', description: 'News' },
  { value: 'NFL Football', description: 'NFL Football' },
  { value: 'Parenting & Family', description: 'Parenting & Family' },
  { value: 'Run of Lineups', description: 'Run of Lineups' },
  { value: 'Science & Education', description: 'Science & Education' },
  { value: 'Spanish Language Content', description: 'Spanish Language Content' },
  { value: 'Spanish Language Entertainment', description: 'Spanish Language Entertainment' },
  { value: 'Spanish Language Music', description: 'Spanish Language Music' },
  { value: 'Sports', description: 'Sports' },
  { value: 'Technology', description: 'Technology' },
  { value: 'Top Podcasts', description: 'Top Podcasts' },
  { value: 'Trending Gaming', description: 'Trending Gaming' },
  { value: 'Trending Now', description: 'Trending Now' },
  { value: 'Trending Sports Lineup', description: 'Trending Sports Lineup' },
  { value: 'Video Gaming', description: 'Video Gaming' },
  { value: 'YouTube TV', description: 'YouTube TV' },
  { value: 'YouTube TV Sports', description: 'YouTube TV Sports' },
];
