import {
  Grid,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import GenericCard from 'components/generic-card/GenericCard';
import { useMemo, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { IQButtonLink } from '@gannettdigital/shared-react-components';
import { useParams } from 'react-router';
import { Urls } from 'navigation/Urls';
import { TargetedPageSelected } from 'pages/targeted-display/TargetedDisplay.interface';
import TacticsFormDialog from './TacticsFormDialog';

export function Tactics({ tactics, onChange }) {
  const { page } = useParams();
  const tacticsPageForProductSelected = useMemo<TargetedPageSelected>(() => {
    const selected: TargetedPageSelected = {
      xmoFreeParty: page === Urls.XmoTargetedDisplay,
      xmoBrandAmplifer: page === Urls.XmoTargetedDisplay,
      targetedDisplay: page === Urls.TargetedDisplay,
      pmm: page === Urls.PmmTargetedDisplay,
    };
    return selected;
  }, [page]);

  const getTacticLabels = (tactic) => {
    if (tacticsPageForProductSelected.xmoFreeParty && tactic.tacticType !== 'googleDisplayNetwork') {
      return ['tacticType', 'specificProductOrService', 'tacticNameInClientReporting'];
    } if (tacticsPageForProductSelected.xmoBrandAmplifer) {
      return ['tacticType', 'targetingType'];
    } if (tacticsPageForProductSelected.targetedDisplay || tacticsPageForProductSelected.pmm) {
      return ['tacticType', 'specificProductOrService', 'adRunningTypes'];
    }
    return ['tacticType', 'specificProductOrService'];
  };

  const { t } = useTranslation();
  const [selectedTacticIdx, setSelectedTacticIdx] = useState(-1);
  const [tacticsFieldsFormDialogOpen, setTacticsFormDialogOpen] = useState(false);
  const selectedTactic = useMemo(() => {
    if (selectedTacticIdx < 0) return {};
    return { ...tactics[selectedTacticIdx] }; // avoid mutation
  }, [selectedTacticIdx]);
  const tacticsCount = useMemo(() => tactics?.length || 0, [tactics]);
  const handleTacticsFormDialogOpen = () => setTacticsFormDialogOpen(true);

  const onDeleteTacticsFieldsLabels = async (idx: number) => {
    const tacticsX = [...tactics];
    tacticsX.splice(idx, 1);
    onChange(tacticsX);
  };

  const handleTacticsFormDialogClose = () => {
    setTacticsFormDialogOpen(false);
    setSelectedTacticIdx(-1);
  };

  const handleTacticsFieldsLabels = (idx: number) => {
    setSelectedTacticIdx(idx);
    handleTacticsFormDialogOpen();
  };

  const updateTactics = (newData) => {
    const tacticsX = [...tactics];
    if (selectedTacticIdx < 0) tacticsX.push(newData);
    else tacticsX[selectedTacticIdx] = newData;

    onChange(tacticsX);
  };

  return (
    <Grid item xs={12} sx={{ marginBottom: '8px' }}>
      <Typography variant="h6">
        {t('pages.xmo.targetedDisplay.tactics.title')}
      </Typography>

      {Array.isArray(tactics)
              && tactics.map((tactic, idx) => (
                <GenericCard
                  key={idx}
                  labels={getTacticLabels(tactic)}
                  data={tactic}
                  handleDelete={() => onDeleteTacticsFieldsLabels(idx)}
                  onEdit={() => handleTacticsFieldsLabels(idx)}
                  onSelect={() => {
                  }}
                />
              ))}

      {tacticsCount < 10 && (
      <IQButtonLink
        startIcon={<AddIcon />}
        onClick={() => handleTacticsFormDialogOpen()}
      >
        {t('pages.xmo.targetedDisplay.tactics.addTacticsLabel')}
      </IQButtonLink>
      )}

      {tacticsFieldsFormDialogOpen && (
      <TacticsFormDialog
        onClose={handleTacticsFormDialogClose}
        updateTactics={updateTactics}
        selectedTactics={selectedTactic}
      />
      )}
    </Grid>
  );
}
