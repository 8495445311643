import { Typography, Box, Grid } from '@mui/material';
import GridItem from 'components/grid/GridItem';
import React, { useEffect, useState } from 'react';
import { useAppDispatch } from 'services/redux/store';
import { useParams } from 'react-router';
import { getProduct } from 'services/productsSlice';
import { displayNotRequiredField, editButton } from 'pages/overview/Overview';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { Urls } from 'navigation/Urls';
import OrderTracker from 'pages/orders/OrderTracker';
import { ProductTypes, getFiles } from 'services/fileUploaderSlice';
import { renderSocialUrls } from 'pages/overview/sections/Business/BusinessLocation';
import { headingStyle } from '../Business/BusinessSection';
import { ActionItems } from '../common/ActionItems';
import { GeneralQuestionsSection } from '../generalQuestions/GeneralQuestionsSection';
import { displayAssets } from '../sites/SitesSection';

export default function CustomerCenterByDashSection({ product, isEditable, showTracker }: any) {
  const appDispatch = useAppDispatch();
  const navigate = useNavigationHandler();
  const { orderId } = useParams();
  const [ccData, setCcData] = useState({} as any);
  const [existingAssets, setExistingAssets] = useState([]);

  const getAssets = (offeringId, offeringType) => {
    appDispatch(getFiles({
      websiteId: offeringId,
      uploaderType: ProductTypes.EXISTING_CONTACT_LIST,
      offeringType,
    })).then((response) => setExistingAssets(response.payload));
  };

  const getProductData = (orderItemId) => {
    appDispatch(getProduct({
      orderItemId,
    })).then((response) => setCcData(response.payload));
  };

  useEffect(() => {
    if (product) {
      const offeringType = product.pdOfferingType;
      getProductData(product?.id);
      getAssets(product?.offeringId, offeringType);
    }
  }, [product, orderId]);

  const offeringType = product.offeringType === 'ProductsData' ? product.pdOfferingType : product.offeringType;

  const filterSocial = (name) => {
    const filtered = ccData?.data?.accounts?.filter(acc => acc.name === name)[0];
    if (filtered?.enabled) return filtered.url;
    return '';
  };

  const socialAccounts = {
    facebookUrl: filterSocial('Facebook'),
    twitterUrl: filterSocial('Twitter'),
    instagramUrl: filterSocial('Instagram'),
    youtubeUrl: filterSocial('YouTube'),
  };

  const renderProductData = () => (
    <>
      {/* Details */}
      <GridItem sizes={[12, 12]} marginBottom={3} sx={{ padding: '20px 0 0', borderTop: '1px solid black' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {editButton(isEditable, () => navigate.to(
            Urls.CCByDashDetails,
            product.id,
          ))}
          <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Details</Typography>
        </Box>
        <Box sx={{ marginTop: '15px' }}>
          {/* General Business */}
          <Typography sx={{ fontSize: '18px', margin: '10px 0 20px' }}>General Business</Typography>
          <GridItem sizes={[6, 6]} sx={{ borderBottom: '1px solid #000000' }}>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Business Legal Name</Typography>
                <Typography textTransform="capitalize">{ccData?.data?.businessFriendlyName}</Typography>
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Website URL</Typography>
                <Typography>{ccData?.data?.websiteUrl}</Typography>
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Business Employer Identification Number (EIN)</Typography>
                <Typography textTransform="capitalize">
                  {displayNotRequiredField('EIN', ccData?.data?.ein)}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Business Industry</Typography>
                <Typography textTransform="capitalize">
                  {ccData?.data?.businessIndustry?.toLowerCase()?.replaceAll('_', ' ')}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Business Operation Type</Typography>
                <Typography>
                  <Typography textTransform="capitalize">{ccData?.data?.operationType}</Typography>
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Business Type</Typography>
                <Typography textTransform="capitalize">{ccData?.data?.businessType}</Typography>
              </Box>
            </Box>
          </GridItem>

          {/* Address & Contact */}
          <Typography sx={{ fontSize: '18px', margin: '20px 0 20px' }}>Address & Contact</Typography>
          <GridItem sizes={[6, 6]}>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Business Address</Typography>
                <Typography>{ccData?.data?.addressLine1}</Typography>
                <Typography>{ccData?.data?.addressLine2}</Typography>
                <Typography>
                  {`${ccData?.data?.city}, ${ccData?.data?.stateProvince}, ${ccData?.data?.postalCode}`}
                </Typography>
                <Typography>{ccData?.data?.country}</Typography>
              </Box>
            </Box>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Primary Contact</Typography>
                <Typography>{ccData?.data?.primaryContactName}</Typography>
                <Typography>{ccData?.data?.jobTitle}</Typography>
                <Typography>{ccData?.data?.primaryContactEmail}</Typography>
                <Typography>
                  {`${ccData?.data?.primaryContactPhone}${ccData?.data?.primaryContactPhoneExt
                      && ` ext.${ccData.data.primaryContactPhoneExt}`}`}
                </Typography>
              </Box>
            </Box>
          </GridItem>
        </Box>
      </GridItem>

      {/* Solution Strategy */}
      <GridItem sizes={[12, 12]} marginBottom={3} sx={{ padding: '20px 0 0', borderTop: '1px solid black' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {editButton(isEditable, () => navigate.to(
            Urls.CCByDashStrategy,
            product.id,
          ))}
          <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Solution Strategy</Typography>
        </Box>
        <Box sx={{ marginTop: '15px' }}>
          <GridItem sizes={[6, 6]}>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Phone Tracking</Typography>
                <Typography>
                  <Typography textTransform="capitalize">{ccData?.data?.phoneTracking}</Typography>
                </Typography>
              </Box>
              {ccData?.data?.phoneTracking === 'yes'
                && React.Children.toArray(ccData?.data?.trackingLines.map((line, i) => (
                  <>
                    <Typography sx={headingStyle} color="primary">{`Tracking Line ${i + 1}`}</Typography>
                    <Typography>{`Original Number: ${line.originalNumber}`}</Typography>
                    <Typography>{`Forward Number: ${line.forwardToNumber}`}</Typography>
                    <Typography mb={2}>{`Area Code Preference Number: ${line.forwardToNumber}`}</Typography>
                  </>
                )))}
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Edge Lead Notification Email</Typography>
                <Typography>
                  {displayNotRequiredField('edge lead notification email', ccData?.data?.edgeLeadEmail)}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Existing Contact List</Typography>
                {displayAssets(existingAssets, 'Uploaded File')}
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Link Social Media</Typography>
                <Typography textTransform="capitalize">
                  {displayNotRequiredField('social media', ccData?.data?.linkSocialMedia)}
                </Typography>
              </Box>
              {ccData?.data?.linkSocialMedia === 'yes'
                && (
                <Box mb={2}>
                  <Typography sx={headingStyle} color="primary">Social Media Accounts</Typography>
                  {renderSocialUrls(socialAccounts)}
                </Box>
                )}
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Client has active Chat Widget</Typography>
                <Typography textTransform="capitalize">{ccData?.data?.activeChatWidget}</Typography>
              </Box>
              {ccData?.data?.activeChatWidget === 'no' && (
                <Box mb={2}>
                  <Typography sx={headingStyle} color="primary">SMS Widget Color</Typography>
                  <Typography>{ccData?.data?.smsWidgetColor}</Typography>
                </Box>
              )}
              {ccData?.data?.smsAiAgentOptions === 'yes'
                && (
                  <Box mb={2}>
                    <Typography sx={headingStyle} color="primary">Utilize same primary URL</Typography>
                    <Typography textTransform="capitalize">{ccData?.data?.utilizeSamePrimaryUrl}</Typography>
                  </Box>
                )}
              {(ccData?.data?.smsAiAgentOptions === 'yes') && (ccData?.data?.utilizeSamePrimaryUrl === 'no')
                  && (
                  <Box mb={2}>
                    <Typography sx={headingStyle} color="primary">Site URL to scrape</Typography>
                    <Typography>{ccData?.data?.siteUrl}</Typography>
                  </Box>
                  )}
            </Box>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Local Area Code 1</Typography>
                <Typography>{ccData?.data?.localAreaCode1}</Typography>
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Local Area Code 2</Typography>
                <Typography>{displayNotRequiredField('Local Area Code 2', ccData?.data?.localAreaCode2)}</Typography>
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Enable SMS AI Agent feature</Typography>
                <Typography textTransform="capitalize">{ccData?.data?.smsAiAgentOptions}</Typography>
              </Box>
              {ccData?.data?.smsLeadEmailList?.map((email, index) => (
                <Box mb={2} key={email}>
                  <Typography sx={headingStyle} color="primary">
                    {`SMS Lead Notification Email ${index + 1}`}
                  </Typography>
                  {email
                    ? (<Typography>{email}</Typography>)
                    : (<Typography fontWeight="bold">No SMS Lead Notification Email</Typography>)}
                </Box>
              ))}
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">SMS Lead Notification Phone Number</Typography>
                <Typography>
                  {displayNotRequiredField('SMS Lead Notification Phone Number', ccData?.data?.smsLeadPhone)}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Additional Notes/Instructions</Typography>
                <Typography>
                  {displayNotRequiredField('additional notes/instructions', ccData?.data?.additionalNotes)}
                </Typography>
              </Box>
            </Box>
          </GridItem>
        </Box>
      </GridItem>
    </>
  );

  return (
    <Grid container sx={{ borderTop: '1px solid #716F6F' }}>
      {/* Status Tracker */}
      {showTracker && (
      <GridItem sizes={[12, 12]} marginBottom={8} sx={{
        padding: '40px 0 0', borderBottom: '1px solid #000000',
      }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {editButton(isEditable, () => {})}
          <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Order Status</Typography>
        </Box>
        <Box>
          <OrderTracker
            key={product?.id}
            status={product?.trackerStatus}
            productName={product?.pdOfferingType}
            product={product}
          />
        </Box>
      </GridItem>
      )}

      {/* General Questions */}
      { GeneralQuestionsSection(offeringType, product.offeringId, product.id, navigate, isEditable) }

      {!product.isRebuild && renderProductData()}

      {/* Actions items */}
      {showTracker && ActionItems(false, product?.id)}
    </Grid>
  );
}
