import ContentLoader from 'components/contentLoader/ContentLoader';
import useProduct from 'hooks/useProduct';
import { Urls } from 'navigation/Urls';
import { useParams } from 'react-router';
import LeadAdsForm from './LeadAdsForm';
import LeadAdsFormDynamic from '../dynamic/XmoDynamicForm';

export default function LeadAds() {
  const { page } = useParams();
  const { product, onSubmit, onBack } = useProduct(page as Urls);

  return (
    <ContentLoader status={product.status} message={product.message}>
      {
        // eslint-disable-next-line max-len
          (product?.content?.data?.socialSmartOptimization?.selectCampaignGoalFocus === 'Contacts Ads - Dynamics Ads (Auto Only)') ? (
            <LeadAdsFormDynamic
              product={product?.content?.data?.leadAds}
              onSubmit={onSubmit}
              onBack={onBack}
            />
          ) : (
            <LeadAdsForm
              product={product?.content?.data?.leadAds}
              onSubmit={onSubmit}
              onBack={onBack}
            />
          )
      }
    </ContentLoader>
  );
}
